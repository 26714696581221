import React, { useEffect } from 'react';
import { Handler } from '../handler/HandlerImpl';
import { Modal, Button, Row, Col, Spin } from 'antd';
import XIconModal from '../../../../../assets/icons/x-icon-modal.svg';
import TableOverviewComponent from './TableOverview';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import '../../../../../assets/styles/DB/DB002.css';
import TableRevenueExpenditure from './TableRevenueExpenditure';
import helpers from '../../../../../common/helpers/common';
import COMMON from '../../../../../common/constants/COMMON';
import LoadingComponent from '../../../../../common/components/icons/LoadingComponent';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';

interface props {
    handler: Handler;
}

function MainUI({ handler }: props) {
    const visibleDB002 = useSelector((state: RootState) => state.db002.isVisibleDB002);
    const dataProjectDB001 = useSelector((state: RootState) => state.db002.dataProjectDB002);
    const [searchParams] = useSearchParams();

    const fromDate =
    searchParams.get("startDate") || moment().format(COMMON.FORMAT_DATE4);
    const toDate =
        searchParams.get("endDate") || moment().format(COMMON.FORMAT_DATE4);

    useEffect(() => {
        if (dataProjectDB001.projectId)
            handler.getReMoneyMonthly({
                projectId: dataProjectDB001.projectId,
                from: helpers.getFilterTime(fromDate),
                to: helpers.getFilterTimeTo(toDate),
            });
    }, [JSON.stringify(dataProjectDB001)]);
    return (
        <Spin indicator={LoadingComponent} spinning={handler.loading}>
            <Modal
                className="group-detail-modal"
                style={{ marginLeft: 300, marginTop: 40, marginBottom: 40 }}
                visible={visibleDB002}
                centered
                maskClosable={false}
                width={'1519px'}
                closable={false}
                onCancel={handler.cancelModalDB002}
                footer={
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            size="large"
                            style={{ marginLeft: 16, width: 118, color: '#666' }}
                            onClick={handler.cancelModalDB002}
                        >
                            キャンセル
                        </Button>
                    </div>
                }
            >
                <div className="w-100-percent header-modal-containter" style={{ height: 50 }}>
                    <div className="header-modal-title-om004 ">
                        {handler.inforProject.projectName}　詳細情報
                    </div>
                    <div style={{ cursor: 'pointer' }} onClick={handler.cancelModalDB002}>
                        <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
                    </div>
                </div>
                <div style={{ margin: '24px 24px 24px 24px', backgroundColor: '#ffffff' }}>
                    <Row style={{ marginBottom: 24 }}>
                        <Col span={8} style={{ display: 'flex' }}>
                            <div style={{ marginRight: 24 }} className="CM0017_3-info-title">
                                期間
                            </div>
                            <div className="CM0017_3-info-content">
                                {' '}
                                {helpers.formatDatePresenter(
                                    fromDate,
                                    COMMON.FORMAT_DATE_DB,
                                )}
                                ～
                                {helpers.formatDatePresenter(
                                    toDate,
                                    COMMON.FORMAT_DATE_DB,
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: 24 }}>
                        <TableOverviewComponent handler={handler} />
                    </Row>
                    <Row>
                        <TableRevenueExpenditure handler={handler} />
                    </Row>
                </div>
            </Modal>
        </Spin>
    );
}

export default MainUI;
