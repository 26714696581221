import { message } from "antd";
import { useSearchParams } from "react-router-dom";
// Redux
import { useDispatch, useSelector } from "react-redux";

// Services
import { CM0033UseCase } from "./../../usecase/ServiceImpl";
import { useState } from "react";
import { RootState } from "../../../../../store";

import { refreshCM0033, resetCM0033, setVisibleCM0033 } from "../slice/Slice";
import {
  setIsVisibleCM0063,
  setParentCodeCM0063,
  setTaxCodeCM0063,
  setTypeModalCM0063,
} from "../../../CM0063/presenter/slice/Slice";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import {
  convertDataView,
  convertDataViewCompany,
  summaryFooter,
} from "../../helper";
import COMMON from "../../../../../common/constants/COMMON";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import {
  setInput,
  setIsVisibleCM0062,
} from "../../../CM0062/presenter/slice/Slice";
import { ItemApprove } from "../../../../../common/components/approval-flow/ApprovalFlow";
import { setLoadingWorkflow } from "../../../../../common/components/workflow-notification/presenter/slice/Slice";
import { showModalApproveConfirm } from "../../../../../common/components/modal-approve-confirm/presenter/slice/Slice";

export const TYPE_FILE = {
  COMPANY: "re_summary_document_company",
  PROJECT: "re_summary_document_project",
};

const CM0033Handler = (service: CM0033UseCase) => {
  const dispatch = useDispatch();

  const [dataView, setDataView] = useState<any>([]);
  const [expandKey, setExpandKey] = useState<any>([]);
  const [page, setPage] = useState<number>(COMMON.DEFAULT_PAGE);
  const [footer, setFooter] = useState<any>({});
  const [workflow, setWorkflow] = useState<ItemApprove[]>([]);
  const [currentUserApproval, setCurrentUserApproval] =
    useState<ItemApprove | null>(null);
  const visibleCM0033 = useSelector(
    (state: RootState) => state.cm0033.isVisibleCM0033
  );

  const getWorkflow = async (params: any) => {
    try {
      dispatch(setLoading(true));
      let data: any = null;
      if (params.documentType === TYPE_FILE.COMPANY) {
      } else {
      }
      data =
        params.documentType === TYPE_FILE.COMPANY
          ? await service.getWorkflowSummaryReceiveMoneyCompanyApprovalProcess({
              documentId: params.documentId,
            })
          : await service.getWorkflowSummaryReceiveMoneyProjectApprovalProcess({
              documentId: params.documentId,
            });
      if (data === null) return;

      if (data?.results?.currentUserApproval)
        setCurrentUserApproval({
          approveDate: data.results.currentUserApproval.approveDate,
          contact_address: "",
          email: data.results.currentUserApproval.email,
          id: data.results.currentUserApproval.id,
          isApprove: data.results.currentUserApproval.approve,
          reject: data.results.currentUserApproval.reject,
          position: "",
          priority: data.results.currentUserApproval.priority,
          username: data.results.currentUserApproval.username,
          note: data.results.currentUserApproval.note,
          fullName: data.results.currentUserApproval.fullName,
        });
      const workflowData: ItemApprove[] = data.results.listApprover.map(
        (element: any) => {
          let note = element.note;
          if (element.id === data.results?.currentUserApproval?.id)
            note = data.results.currentUserApproval.note;
          const result: ItemApprove = {
            approveDate: element.approveDate,
            contact_address: "",
            email: element.email,
            id: element.id,
            isApprove: element.approve,
            reject: element.reject,
            position: "",
            priority: element.priority,
            username: element.username,
            note: note,
            fullName: element.fullName,
          };
          return result;
        }
      );
      setWorkflow(workflowData);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const funcSetExpendKey = (key: any) => {
    const checkKey = funcCheckKeyExpand(key);
    if (checkKey) {
      const newExpandKey = expandKey.filter(
        (keyExpand: any) => keyExpand !== key
      );
      setExpandKey(newExpandKey);
    } else {
      setExpandKey([...expandKey, key]);
    }
  };

  const funcCheckKeyExpand = (key: any) => {
    if (!key || !expandKey) return false;
    return expandKey.some((element: any) => element === key);
  };

  const handleCancelModal = () => {
    dispatch(resetCM0033());
  };

  const handleViewDetail = (
    documentId: number,
    categoryId: number,
    projectName: string,
    companyName: string,
    time: string,
    option: boolean
  ) => {
    dispatch(setIsVisibleCM0062(true));
    dispatch(
      setInput({
        documentId: documentId,
        categoryId: categoryId,
        projectName: projectName,
        companyName: companyName,
        time: time,
        option: option,
      })
    );
  };

  const getDataView = async (params: any) => {
    // Set loading
    try {
      dispatch(setLoadingWorkflow(true));
      // PROJECTS
      let responseResults = [];

      if (params.documentType === TYPE_FILE.COMPANY) {
        const responseData =
          await service.getReSummarySubmittedReceiveMoneyCompanyList({
            documentId: params.documentId,
            page: params.page,
            size: COMMON.DEFAULT_SIZE,
          });
        responseResults = responseData.results ?? [];
      } else {
        const responseData =
          await service.getReSummarySubmittedReceiveMoneyProjectList({
            documentId: params.documentId,
            page: params.page,
            size: COMMON.DEFAULT_SIZE,
          });
        responseResults = responseData.results ?? [];
      }

      const output: any[] = [];

      if (responseResults.length > 0) {
        const promises = responseResults.map(async (item: any) => {
          if (params.documentType === TYPE_FILE.COMPANY) {
            const nested = await service.getListMoneyReceiveCompany({
              documentId: params.documentId,
              projectId: item.id,
            });

            const obj = {
              ...item,
              children: nested.results,
            };

            output.push(obj);
          } else {
            const nested = await service.getListMoneyReceive({
              documentId: params.documentId,
              projectId: item.id,
            });

            const obj = {
              ...item,
              children: nested.results,
            };

            output.push(obj);
          }
        });

        await Promise.all(promises);
      }

      let data: any[] = [];

      if (params.documentType === TYPE_FILE.COMPANY) {
        const response = convertDataViewCompany(output);
        data = [...dataView, ...response];
        setDataView(data);
        if (data.length > 0) {
          setExpandKey([data[0].key, ...expandKey]);
          if (data[0].children.length > 0) {
            setExpandKey([...expandKey, data[0].key, data[0].children[0].key]);
          }
        }
      } else {
        const response = convertDataView(output);
        data = [...dataView, ...response];
        setDataView(data);
        if (data.length > 0) {
          setExpandKey([data[0].key, ...expandKey]);
          if (data[0].children.length > 0) {
            setExpandKey([data[0].key, `table${data[0].children[0].key}`]);
          }
        }
      }
    } catch (error: any) {
      ErrorNotification(error?.message, NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoadingWorkflow(false));
    }
  };

  const handlerScrollInfinity = () => {
    setPage((prev) => prev + 1);
  };

  const retrieveDocumentProject = async (documentId: number) => {
    ConfirmModal({
      onOk: async () => {
        try {
          const resp = await service.retrieveDocumentProject({
            documentId: documentId,
          });
          dispatch(resetCM0033());
          dispatch(refreshCM0033());
          SuccessNotification(resp?.message);
        } catch (error) {
          throw error;
        }
      },
      className: "confirm__modal",
      title: MESSAGE.MESSAGE_TITLE_CM016,
      description: MESSAGE.MESSAGE_DESCRIPTION_CR_5,
      extraDescription: MESSAGE.MESSAGE_DESCRIPTION_COMMON_QUESTION_1,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL,
      isCenterWithoutMenu: true,
    });
  };

  const retrieveDocumentCompany = async (documentId: number) => {
    ConfirmModal({
      onOk: async () => {
        try {
          const resp = await service.retrieveDocumentCompany({
            documentId: documentId,
          });
          dispatch(resetCM0033());
          dispatch(refreshCM0033());
          SuccessNotification(resp?.message);
        } catch (error) {
          throw error;
        }
      },
      className: "confirm__modal",
      title: MESSAGE.MESSAGE_TITLE_CM016,
      description: MESSAGE.MESSAGE_DESCRIPTION_CR_5,
      extraDescription: MESSAGE.MESSAGE_DESCRIPTION_COMMON_QUESTION_1,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL,
      isCenterWithoutMenu: true,
    });
  };

  const getLineSummary = async (params: any) => {
    try {
      let output: any = {};

      if (params.documentType === TYPE_FILE.COMPANY) {
        const resp = await service.getLineSummaryCompany({
          documentId: params.documentId,
        });
        output = resp.results;
      } else {
        const resp = await service.getLineSummaryProject({
          documentId: params.documentId,
        });
        output = resp.results;
      }

      setFooter(summaryFooter(output));
    } catch (error: any) {
      ErrorNotification(error?.message, NOTIFICATION_TITLE.ERROR);
    }
  };
  const showConfirmApproveModal = () => {
    dispatch(showModalApproveConfirm());
  };
  return {
    dataView,
    expandKey,
    visibleCM0033,
    page,
    footer,
    currentUserApproval,
    workflow,

    funcSetExpendKey,
    funcCheckKeyExpand,
    handleCancelModal,
    handleViewDetail,
    getDataView,
    handlerScrollInfinity,
    retrieveDocumentProject,
    retrieveDocumentCompany,
    getLineSummary,
    getWorkflow,
    showConfirmApproveModal,
  };
};

export default CM0033Handler;
