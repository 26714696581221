import { useDispatch, useSelector } from "react-redux";
import { useMemo, useRef, useState } from "react";
import { PM005UseCase } from "../../usecase/ServiceImpl";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import { useNavigate } from "react-router-dom";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import { Form } from "antd";
import {
  setIsModalOpen,
  setProjectId,
} from "../../../PM007/presenter/slice/Slice";
import helpers from "../../../../../common/helpers/common";
import { refreshPM005, setProjectDetail } from "../slice/Slice";
import { showImageViewerModal } from "../../../../../common/components/image-viewer-modal/ImageViewSlice";
import { showPdfViewerModal } from "../../../../../common/components/pdf-viewer-modal/pdfViewerModalSlice";
import COMMON from "../../../../../common/constants/COMMON";
import ConfirmModalAsync from "../../../../../common/components/modal/ConfirmModalAsync";
import {
  hidePM011Modal,
  showPM011Modal,
} from "../../../PM011/presenter/slice/Slice";
import {
  pm012ChangeOpen,
  setProject,
} from "../../../PM012/presenter/slice/Slice";
import moment from "moment";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import { isHasPrivilege } from "../../../../../common/helpers/privilege";
import {
  PRIVILEGE_CREATOR,
  PRIVILEGE_PM,
} from "../../../../../common/constants/PRIVILEGE";
import { RootState } from "../../../../../store";
import { SYSTEM_ROLES } from "../../../../../common/constants/AUTHORIZATION";
const CODE_MAIN_CONSTRUCTION = "main";
const COLOR = [
  {
    name: "見積中",
    color: "#FF9054",
  },
  {
    name: "施工中",
    color: "#0050AE",
  },
  {
    name: "工事完了",
    color: "#0FA44A",
  },
  {
    name: "不調",
    color: "#FF0909",
  },
];

export const STATUS_APPROVE: any = {
  approved: {
    name: "承認済",
    code: "approved",
    color: "#0FA44A",
    background_color: "rgba(209, 242, 222, 1)",
  },
  waiting_for_approval: {
    name: "承認待ち",
    code: "waiting_for_approval",
    color: "#FF9054",
    background_color: "rgba(255, 144, 84, 0.15)",
  },
  reject: {
    name: "否認",
    code: "reject",
    color: "#FF0909",
    background_color: "rgba(255, 9, 9, 0.15)",
  },
  not_submit: {
    name: "未提出",
    code: "not_submit",
    color: "#605BFF",
    background_color: "rgba(151, 71, 255, 0.15)",
  },
};

export const TYPE_ASC = "ASC";
export const TYPE_DESC = "DESC";

const PM005Handler = (pM002Service: PM005UseCase) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const refNote = useRef();
  const [projectInfo, setProjectInfo] = useState<any>({});
  const [pdfUrl, setPdfUrl] = useState("");
  const [showPdf, setShowPdf] = useState(false);
  const [chooseConstruction, setChooseConstruction] = useState<any>({});
  const [noteEdit, setNoteEdit] = useState<number>(0);
  const [noteConstruction, setNoteConstruction] = useState<string>("");
  const [noteView, setNoteView] = useState<number>(0);
  const [keyCollapseMain, setKeyCollapseMain] = useState<string | string[]>([]);
  const [isChangeConstructions, setIsChangeConstructions] = useState(false);
  const [isScroll, setIsScroll] = useState(false);
  const [approverCIMapper, setApproverCIMapper] = useState<any>({});
  const [approverCRMapper, setApproverCRMapper] = useState<any>({});

  const userId = useSelector((state: RootState) => state.auth.authData?.id);

  const isRoleAdmin = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdmin
  );

  const isRoleAdminSystem = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdminSystem
  );

  const getPromiseAllCICR = async (constructionId: number, userId: number) => {
    let isCI: boolean = false;
    let isCR: boolean = false;
    try {
      const [responseCI, responseCR] = await Promise.all([
        pM002Service.getApprovalProcessCI({
          constructionId: constructionId,
        }),
        pM002Service.getApprovalProcessCR({
          constructionId: constructionId,
        }),
      ]);

      const listApproverCI = responseCI.data?.results?.listApprover ?? [];
      const listApproverCR = responseCR.data?.results?.listApprover ?? [];

      let isCI: boolean = false;
      let isCR: boolean = false;

      if (listApproverCI.length > 0)
        isCI = listApproverCI.some((element: any) => element.id === userId);
      if (listApproverCR.length > 0)
        isCR = listApproverCR.some((element: any) => element.id === userId);

      return {
        id: constructionId,
        isCI: isCI,
        isCR: isCR,
      };
    } catch (error) {
      return {
        id: constructionId,
        isCI: isCI,
        isCR: isCR,
      };
    }
  };

  const getDetailProject = async (params: any) => {
    localStorage.removeItem("parentId");
    try {
      dispatch(setLoading(true));
      const response = await pM002Service.getDetailProject(params);
      if (response?.results?.userId) {
        localStorage.setItem("projectUserId", response?.results?.userId);
      }
      if (response?.results?.parentId) {
        localStorage.setItem("parentId", response?.results?.parentId);
      }
      if (response) {
        setProjectInfo(response.results);
        dispatch(setProjectDetail(response.results));
        const rootId = response.results.rootId
          ? response.results.rootId
          : response.results.id;
        if (response?.results?.document && response?.results?.document !== "") {
          await getPdfMap({
            keyName: "project/" + rootId + "/" + response.results.document,
          });
        } else {
          setPdfUrl("");
        }

        // Call arpover

        const approverCI: any = {};
        const approverCR: any = {};

        const resultsConstruction = response?.results?.constructions ?? [];
        if (resultsConstruction.length > 0) {
          const promisesCalls = resultsConstruction.map((construction: any) => {
            return getPromiseAllCICR(
              construction.id,
              userId ? Number(userId) : 0
            );
          });
          const approverCICR = await Promise.all(promisesCalls);
          approverCICR.forEach((approver) => {
            approverCI[approver.id] = approver.isCI;
            approverCR[approver.id] = approver.isCR;
          });
        }
        setApproverCIMapper(approverCI);
        setApproverCRMapper(approverCR);
      }
      handleCloseNoteConstruction();
      // setTimeOptions(response);
    } catch (error) {
      setProjectInfo({});
      handleCloseNoteConstruction();
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getNoteConstruction = async (params: any) => {
    try {
      dispatch(setLoading(true));
      const response = await pM002Service.getNoteConstruction(params);
      setNoteConstruction(response.data.results);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      setNoteConstruction("");
    } finally {
      dispatch(setLoading(false));
    }
  };
  const updateNoteConstruction = async (params: any) => {
    try {
      const response = await pM002Service.updateNoteConstruction(params);
      SuccessNotification(
        response?.data?.message ??
        NOTIFICATION_TITLE.MESSAGE_DELETE_NOTIFICATION_SUCCESS
      );
      handleCloseNoteConstruction();
      dispatch(refreshPM005());
      setIsChangeConstructions(false);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const onViewConstructionInquiry = (id: number, projectId: string) => {
    navigate(
      `/app-ci001/${projectId}/${id}/${projectInfo.userId || 0}/${projectInfo?.projectManager?.id || 0
      }?projectName=${projectInfo.projectName}`
    );
  };

  const onViewRunningBudget = (id: number, projectId: string) => {
    navigate(
      `/app-cr003/${projectId}/${id}/${projectInfo.userId || 0}/${projectInfo?.projectManager?.id || 0
      }?projectName=${projectInfo.projectName}`
    );
  };

  const constructionUI = useMemo(() => {
    if (isChangeConstructions) setIsScroll(!isScroll);
    setIsChangeConstructions(false);

    if (!projectInfo?.constructions)
      return {
        main: [],
        other: [],
      };

    if (projectInfo.constructions?.length === 0) {
      return {
        main: [
          {
            id: 0,
            code: "main",
            name: "本工事登録",
            note: "",
            userId: 0,
          },
        ],
        other: [],
      };
    }
    const resultMain = projectInfo.constructions.filter(
      (construction: any) => construction.code === CODE_MAIN_CONSTRUCTION
    );

    const resultOther = projectInfo.constructions.filter(
      (construction: any) => !(construction.code === CODE_MAIN_CONSTRUCTION)
    );

    const resultsForm: { [key: string]: any } = {};

    resultMain?.forEach(
      (element: { id: number; name: string; note: string }) => {
        resultsForm[element?.id] = element?.name;
        resultsForm[`main${element?.id}`] = element?.note;
      }
    );

    resultOther.forEach(
      (element: { id: string | number; name: string; note: string }) => {
        resultsForm[element?.id] = element?.name;
        resultsForm[`addition${element?.id}`] = element?.note;
      }
    );

    const resultsKeyCollapseMain = resultMain?.map(
      (main: { id: number }) => `main${main?.id}`
    );
    setKeyCollapseMain(resultsKeyCollapseMain);

    form.setFieldsValue(resultsForm);
    return {
      main: resultMain,
      other: resultOther,
    };
  }, [projectInfo, form]);

  const postAddConstruction = async (params: { projectId: number }) => {
    dispatch(setLoading(true));
    try {
      const response = await pM002Service.postAddConstruction(params);
      SuccessNotification(
        response?.data?.message ??
        NOTIFICATION_TITLE.MESSAGE_DELETE_NOTIFICATION_SUCCESS
      );
      dispatch(refreshPM005());
      setIsChangeConstructions(true);
      return response;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const deleteConstruction = async (data: any, constructionName: string) => {
    ConfirmModalAsync({
      onOk: async () => {
        dispatch(setLoading(true));
        try {
          const response = await pM002Service.deleteConstruction(data);
          SuccessNotification(
            response?.data?.message ??
            NOTIFICATION_TITLE.MESSAGE_DELETE_NOTIFICATION_SUCCESS
          );
          dispatch(refreshPM005());
          setIsChangeConstructions(true);
          return response;
        } catch (error: any) {
          ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
          dispatch(setLoading(false));
        }
      },
      onCancel: () => { },
      title: "削除確認",
      className: "confirm__modal confirm__modal-confirm",
      description: `${constructionName}を削除してもよろしいでしょうか？`,
      canceText: "キャンセル",
      okText: "削除",
    });
  };
  const showModal = () => {
    dispatch(setProjectId(projectInfo.id));
    dispatch(setIsModalOpen(true));
  };
  const handleCancel = () => {
    dispatch(setIsModalOpen(false));
  };

  const getPdfMap = async (params: any) => {
    try {
      const response = await pM002Service.getPdfMap(params);
      setPdfUrl(response.results);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const cancelPdf = () => setShowPdf(false);

  const checkColor = (statusName: string) => {
    if (!statusName) return "";
    const found = COLOR.find(
      (color: { name: string; color: string }) => color.name === statusName
    );
    return found?.color;
  };

  const handleConstructionName = (construction: any) => {
    setChooseConstruction(construction);
    form.setFieldValue(construction.id, construction.name);
  };

  const updateNameConstruction = async (data: any) => {
    try {
      const response = await pM002Service.updateNameConstruction(data);
      SuccessNotification(
        response?.data?.message ??
        NOTIFICATION_TITLE.MESSAGE_DELETE_NOTIFICATION_SUCCESS
      );
      dispatch(refreshPM005());
      setChooseConstruction({});
      return response;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const onSaveConstruction = (value: any) => {
    const results = {
      constructionName: value[Object.keys(value)[0]],
      constructionId: Number([Object.keys(value)[0]]),
    };
    updateNameConstruction(results);
  };

  const handleCancelUpdateConstruction = () => {
    setChooseConstruction({});
    const resultMain = projectInfo.constructions.filter(
      (construction: any) => construction.code === CODE_MAIN_CONSTRUCTION
    );
    const resultOther = projectInfo.constructions.filter(
      (construction: any) => !(construction.code === CODE_MAIN_CONSTRUCTION)
    );
    const resultsForm: { [key: string]: any } = {};

    resultMain.forEach(
      (main: { id: string | number; name: string; note: string }) => {
        resultsForm[main?.id] = main?.name;
        resultsForm[`main${main?.id}`] = main?.note;
      }
    );

    resultOther.forEach(
      (element: { id: string | number; name: string; note: string }) => {
        resultsForm[element?.id] = element?.name;
        resultsForm[`addition${element?.id}`] = element?.note;
      }
    );

    form.setFieldsValue(resultsForm);
  };

  const handleCancelUpdateNote = () => {
    setNoteEdit(0);
    const resultsForm: { [key: string]: any } = {};

    constructionUI?.other.forEach(
      (element: { id: string | number; name: string; note: string }) => {
        resultsForm[`addition${element?.id}`] = element?.note;
      }
    );

    constructionUI?.main.forEach(
      (element: { id: string | number; name: string; note: string }) => {
        resultsForm[`main${element?.id}`] = element?.note;
      }
    );

    form.setFieldsValue(resultsForm);
  };
  const handleSaveUpdateNote = (data: any, nameInput: string) => {
    const results = form.getFieldValue(nameInput);
    updateNoteConstruction({
      constructionId: data,
      note: results,
    });
  };

  const handleDeleteNoteConstruction = (id: any) => {
    ConfirmModal({
      onOk: async () => {
        await updateNoteConstruction({
          constructionId: id,
          note: "",
        });
      },
      className: "confirm__modal",
      title: MESSAGE.MESSAGE_MODAL_DELETE_TITLE,
      description: MESSAGE.MESSAGE_MODAL_DELETE_DESCRIPTION_PM025,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL,
      isCenterWithoutMenu: true,
    });
  };

  const handleMouseLeaveNote = (e: any) => {
    setNoteEdit(0);
  };

  const handleChangeNoteView = (id: number) => {
    setNoteView(id);
  };

  const handleChangeInputArea = (e: any, keyName: string) => {
    const results = helpers.toLowerCaseNonAccentVietnamese(e.target.value);
    form.setFieldValue(keyName, results);
  };

  const handleShowImageViewerModal = () => dispatch(showImageViewerModal());
  const handleShowPdfViwerModal = () => dispatch(showPdfViewerModal());
  const handleOpenChooseMember = () => {
    dispatch(
      showPM011Modal({
        projectDetail: projectInfo ?? null,
      })
    );
  };
  const handleCloseChooseMember = () => { };

  const handleViewAddMember = (member: any) => {
    dispatch(pm012ChangeOpen(true));
    dispatch(setProject(member));
  };

  const hideModalPM011 = () => {
    dispatch(hidePM011Modal());
  };

  const handleChangeCollapseMain = (value: string | string[]) => {
    setKeyCollapseMain(value);
  };
  const handleCloseNoteConstruction = () => {
    setNoteEdit(0);
    setNoteView(0);
  };

  const redirectToCM001 = () => {
    const rangeDate =
      projectInfo?.constructionFromDate && projectInfo?.constructionToDate
        ? `${moment(projectInfo?.constructionFromDate).format(
          "YYYY 年 MM 月 DD 日"
        )} ～ ${moment(projectInfo?.constructionToDate).format(
          "YYYY 年 MM 月 DD 日"
        )}`
        : "～";

    navigate(
      `/app-cm001/pm005?projectName=${projectInfo?.projectName}&projectId=${projectInfo?.id}&rangeDate=${rangeDate}&projectManagerId=${projectInfo?.projectManager?.id}`
    );
  };

  const checkAccessTC = () => {
    const user = helpers.getObjectFromLocalStorage("user");
    let isAccess = false;
    if (user.order.code === "plan_basic") isAccess = true;

    return isHasPrivilege(projectInfo, [PRIVILEGE_PM]) && isAccess;
  };

  const isDisabledMinusButton = (projectInfo: any, record: any) => {
    if (
      (isHasPrivilege(projectInfo, [PRIVILEGE_PM, PRIVILEGE_CREATOR]) ||
        isRoleAdmin ||
        isRoleAdminSystem) &&
      record.canDelete &&
      projectInfo.parentId === 0
    ) {
      return true;
    }
    return false;
  };

  return {
    getDetailProject,
    onViewConstructionInquiry,
    onViewRunningBudget,
    postAddConstruction,
    showModal,
    handleCancel,
    getPdfMap,
    cancelPdf,
    handleShowPdfViwerModal,
    handleShowImageViewerModal,
    handleConstructionName,
    checkColor,
    deleteConstruction,
    onSaveConstruction,
    handleCancelUpdateConstruction,
    setNoteEdit,
    getNoteConstruction,
    updateNoteConstruction,
    handleCancelUpdateNote,
    handleSaveUpdateNote,
    handleDeleteNoteConstruction,
    handleMouseLeaveNote,
    handleChangeNoteView,
    handleChangeInputArea,
    handleOpenChooseMember,
    handleCloseChooseMember,
    handleViewAddMember,
    hideModalPM011,
    handleChangeCollapseMain,
    handleCloseNoteConstruction,
    redirectToCM001,
    checkAccessTC,
    isDisabledMinusButton,
    projectInfo,
    constructionUI,
    pdfUrl,
    showPdf,
    chooseConstruction,
    form,
    noteEdit,
    noteConstruction,
    noteView,
    keyCollapseMain,
    refNote,
    isChangeConstructions,
    isScroll,
    approverCIMapper,
    approverCRMapper,
  };
};

export default PM005Handler;
