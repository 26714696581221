import { format } from "path";
// Built-in
import React, { useState } from "react";

// 3rd party lib
import moment from "moment";
import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";

// Source files
import { setLoading } from "../../../../../common/slice/CommonSlice";
import { CollapsedTableServiceImpl } from "./../../usecase/ServiceImpl";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import ConfirmModalAsync from "../../../../../common/components/modal/ConfirmModalAsync";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import COMMON from "../../../../../common/constants/COMMON";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import { RootState } from "../../../../../store";
import { setCheckedCollapse, setMaterialCostData } from "../slice/Slice";
import { STATUS_CM_CHILD_TABLE } from "../../../../../common/constants/COMMON";
import { showImageViewerModal } from "../../../../../common/components/image-viewer-modal/ImageViewSlice";
import { uuidv4 } from "@firebase/util";
import { STATUS_PARENT } from "../../../CM0013/presenter/handler/HandlerMaterialCost";
import { showPdfViewerModal } from "../../../../../common/components/pdf-viewer-modal/pdfViewerModalSlice";
import helpers from "../../../../../common/helpers/common";
import { setDocumentView } from "../../../../../common/components/modal-approve-view/presenter/slice/Slice";
import { MoneyIndirectCostInformationEditPayload } from "../../entity/Entity";
import { setDocumentEdit } from "../../../../../common/components/modal-approve-edit/presenter/slice/Slice";
import { setEvidenceName } from "../../../CM005/presenter/slice/Slice";

export const INPUT_CATEGORY_CODE = {
  manualInput: "manual_input",
  percentage: "percentage",
};

const SCREEN_NAME = "CM0015";

const CollapsedTableHandler = (service: CollapsedTableServiceImpl) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const [parentData, setParentData] = useState<any>();
  const [data, setData] = useState<any[]>([]);
  const [originalData, setOriginalData] = useState<any[]>([]);
  const [editingKeys, setEditingKeys] = useState<any[]>([]);
  const [newLines, setNewLines] = useState<any[]>([]);
  const [newCount, setNewCount] = useState<number>(0);
  const [refresh, setRefresh] = useState(false);
  const [inputCategories, setInputCategories] = useState<any>([]);
  const [linkEvidence, setLinkEvidence] = useState("");
  const [saveKeys, setSaveKeys] = useState<any[]>([]);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedList, setCheckedList] = useState<any[]>([]);

  const dataMaterialCost = useSelector(
    (state: RootState) => state.cm0015.dataMaterialCost
  );

  const constructionDirectCost = useSelector(
    (state: RootState) => state.cm0015.constructionDirectCost
  );

  const isEnableSaveButton = (record: any): boolean =>
    saveKeys.some((element) => element === record.key + "");

  const checkEnableButtons = (changedValues: any, values: any) => {
    const [key] = Object.keys(changedValues);
    if (changedValues[key]?.inputMethod) {
      if (!String(key)?.includes("new")) {
        const newData = data.map((element) => {
          const obj = { ...element };
          if (`record${element.id}` === key) {
            obj.inputMethod = changedValues[key].inputMethod;
          }
          return obj;
        });
        setData(newData);
      } else {
        const dataNewLine = newLines.map((line) => {
          const obj = { ...line, isEnable: true };
          if (`record${line.id}` === key) {
            obj.inputMethod = changedValues[key].inputMethod;
          }
          return obj;
        });
        setNewLines(dataNewLine);
      }
    }

    const recordChangedValue = changedValues[Object.keys(changedValues)[0]];
    const changedValue = recordChangedValue[Object.keys(recordChangedValue)[0]];
    const recordKeyName = Object.keys(changedValues)[0]?.replaceAll(
      "record",
      ""
    );
    if (recordChangedValue.inputMethod) return;
    if (changedValue !== undefined && changedValue !== null) {
      // find in saveKeys
      const foundKey = saveKeys.find((key: any) => {
        return recordKeyName === key;
      });
      if (foundKey) return;
      setSaveKeys([...saveKeys, recordKeyName]);
    } else {
      const changedValueObj = { ...values[Object.keys(changedValues)[0]] };
      delete changedValueObj.inputMethod;
      const isAllUndefined = Object.values(changedValueObj).every(
        (x) => x === undefined || x === null
      );
      if (isAllUndefined) {
        const newSaveKeys = saveKeys.filter(
          (key: any) => key !== recordKeyName
        );
        setSaveKeys(newSaveKeys);
      } else {
        const foundKey = saveKeys.find((key: any) => {
          return recordKeyName === key;
        });
        if (foundKey) return;
        setSaveKeys([...saveKeys, recordKeyName]);
      }
    }
  };

  const afterClearEvidence = (formName: any) => {
    const evidenceChangeValue = form.getFieldValue(formName[0]);
    const recordKeyName = formName[0]?.replaceAll("record", "");
    const evidenceChangeValueCheck = { ...evidenceChangeValue };
    delete evidenceChangeValueCheck.inputMethod;
    const isAllUndefined = Object.values(evidenceChangeValueCheck).every(
      (x) => {
        return x === undefined || x === null;
      }
    );
    if (isAllUndefined) {
      const newSaveKeys = saveKeys.filter((key: any) => key !== recordKeyName);
      setSaveKeys(newSaveKeys);
    } else {
      const foundKey = saveKeys.find((key: any) => {
        return recordKeyName === key;
      });
      if (foundKey) return;
      setSaveKeys([...saveKeys, recordKeyName]);
    }
  };
  const handleFirstRender = (data: any) => {
    setData(data ?? []);
    setOriginalData(data ?? []);
  };

  const getMoneyIndirectCostInformationList = async (params: {
    indirectCostId: number;
    constructionId: number;
    sortBy?: string;
    sortType?: string;
    from?: string;
    to?: string;
    page: number;
    size: number;
  }): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const responseData = await service.getMoneyIndirectCostInformationList(
        params
      );
      let finalResult = responseData?.results ?? [];

      let checkApproved = true;
      let checkWaitingApproved = false;
      if (finalResult?.length === 0) checkApproved = false;
      const dataView = finalResult.map((data, i: number) => {
        if (data.status?.code !== STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE)
          checkApproved = false;
        if (
          data.status?.code ===
          STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE
        )
          checkWaitingApproved = true;
        return {
          key: data.id,
          no: i + 1,
          id: data.id,
          isEnable: true,
          inputMethod: data.inputMethodCategory.id,
          inputMethodName: data.inputMethodCategory.description,
          inputMethodCode: data.inputMethodCategory.code,
          targetPeriodFrom: data.startDate
            ? moment(data.startDate).format(COMMON.FORMAT_DATE2)
            : "",
          targetPeriodTo: data.endDate
            ? moment(data.endDate).format(COMMON.FORMAT_DATE2)
            : "",
          datePayment: data.paymentDate
            ? moment(data.paymentDate).format(COMMON.FORMAT_DATE2)
            : "",
          manualInput: data.value,
          multiplicationFactor: data.multiplicationFactor,
          amountTotal: 1000,
          actualCost: data.actualCost,
          evidence: data.evidence,
          status: data.status,
          note: data.note,
          value: data.value,
          directCost: data.directCost,
        };
      });

      const newDataMaterial = dataMaterialCost.map((element) => {
        const obj = { ...element };
        if (element.id === params.indirectCostId) {
          if (checkApproved) obj.status = STATUS_PARENT.approved;
          else if (checkWaitingApproved)
            obj.status = STATUS_PARENT.waiting_for_approve;
          else obj.status = STATUS_PARENT.rejected;
          obj.child = dataView;
        }
        return obj;
      });
      dispatch(setMaterialCostData(newDataMaterial));

      // using deep copy to avoid 2 state aim the same memory address
      setData(JSON.parse(JSON.stringify(dataView)));
      setOriginalData(JSON.parse(JSON.stringify(dataView)));
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getMoneyIndirectCostInformationInputCategory = async () => {
    try {
      const response =
        await service.getMoneyIndirectCostInformationInputCategory();
      setInputCategories(response?.results ?? []);
    } catch (error) {
      setInputCategories([]);
    }
  };

  const onValueNumberChange = (record: any, property: string, e: any) => {
    const value = e;
    const toNumber = Number(value);

    if (String(record.id).includes("new")) {
      // new data
      const newNewLines = newLines.map((element) => {
        const obj = { ...element };
        if (element.id === record.id) {
          obj.isEnable = true;
          obj[`${property}`] = isNaN(toNumber) ? 0 : toNumber;
        }
        return obj;
      });
      setNewLines(newNewLines);
    } else {
      // old data
      const newData = data.map((element) => {
        const obj = { ...element };
        if (element.id === record.id)
          obj[`${property}`] = isNaN(toNumber) ? 0 : toNumber;
        return obj;
      });
      setData(newData);
    }
  };

  const addNewLine = () => {
    const newLineKey = `new${newCount}`;
    const inputPercentage = inputCategories.find(
      (ele: { code: string }) => ele.code === INPUT_CATEGORY_CODE.percentage
    );

    const newLinesData: any = {
      key: newLineKey,
      id: newLineKey,
      no: newLineKey,
      isEnable: false,
      targetPeriodFrom: null,
      targetPeriodTo: null,
      datePayment: null,
      actualCost: null,
      evidence: "",
      inputMethod: inputPercentage?.id,
      manualInput: null,
      multiplicationFactor: null,
      directCost: 0,
    };
    form.setFieldValue(
      [`record${newLineKey}`, "inputMethod"],
      inputPercentage?.id
    );
    setNewLines([...newLines, newLinesData]);
    setEditingKeys([...editingKeys, newLineKey]);
    setNewCount(newCount + 1);
  };

  const isEditing = (record: any): boolean =>
    editingKeys.some((element) => element === record.key);

  const edit = (record: any) => {
    setEditingKeys([...editingKeys, record.key]);
    form.setFields([
      {
        name: [`record${record.id}`, `actualCost`],
        value: record.actualCost,
      },
      {
        name: [`record${record.id}`, `datePayment`],
        value: record.datePayment ? moment(record.datePayment) : undefined,
      },
      {
        name: [`record${record.id}`, `targetPeriod`],
        value: [
          record.targetPeriodFrom ? moment(record.targetPeriodFrom) : undefined,
          record.targetPeriodTo ? moment(record.targetPeriodTo) : undefined,
        ],
      },
      {
        name: [`record${record.id}`, `inputMethod`],
        value: record.inputMethod,
      },
      {
        name: [`record${record.id}`, `manualInput`],
        value: record.manualInput,
      },
      {
        name: [`record${record.id}`, `multiplicationFactor`],
        value: record.multiplicationFactor,
      },
      {
        name: [`record${record.id}`, `evidence`],
        value: {
          file: {
            uid: uuidv4(),
            name: record.evidence,
          },
        },
      },
    ]);
  };

  const getLinkPresignUpload = async (params: any) => {
    try {
      const response = await service.getLinkPresignUpload(params);

      if (response) {
        return response.results;
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const uploadTos3 = async (url: any, data: any) => {
    try {
      const response = await service.uploadToS3(url, data);
      return response.results;
    } catch (error) {
      throw error;
    }
  };

  const save = async (record: any, documentIndirectCostId: number) => {
    try {
      dispatch(setLoading(true));
      const { key } = record;
      const dataUpdate = form.getFieldValue(`record${key}`);
      let manualInput = 0;
      let multiplicationFactor = 0;
      const codeCategoryInput = inputCategories.find(
        (input: any) => input.id === dataUpdate.inputMethod
      )?.code;
      if (codeCategoryInput === INPUT_CATEGORY_CODE.manualInput) {
        manualInput = dataUpdate.manualInput ?? 0;
      }
      if (codeCategoryInput === INPUT_CATEGORY_CODE.percentage) {
        multiplicationFactor = dataUpdate.multiplicationFactor ?? 0;
      }

      if (String(key).includes("new")) {
        const response = await service.postMoneyIndirectCostInformationCreate({
          data: [
            {
              id: 0,
              documentIndirectCostId: Number(documentIndirectCostId),
              endDate: dataUpdate.targetPeriod
                ? helpers.getFilterTimeTo(dataUpdate.targetPeriod[1])
                : null,
              paymentDate:
                helpers.getFilterTime(dataUpdate.datePayment) ?? null,
              startDate: dataUpdate.targetPeriod
                ? helpers.getFilterTime(dataUpdate.targetPeriod[0])
                : null,
              actualCost: dataUpdate.actualCost ?? 0,
              inputMethodCategoryId: dataUpdate.inputMethod ?? null,
              manualInput: manualInput,
              multiplicationFactor: multiplicationFactor,
              evidence: dataUpdate.evidence?.file
                ? dataUpdate.evidence?.file.name
                : "",
            },
          ],
        });
        if (response && response.status === `${COMMON.HTTP_STATUS_OK}`) {
          if (dataUpdate.evidence?.file?.originFileObj) {
            const companyId = JSON.parse(localStorage.getItem("user") ?? `{}`)
              ?.company?.id;
            const linkPreSignUpload = await getLinkPresignUpload({
              keyName: `re/${companyId}/${SCREEN_NAME}/${response.results}/${dataUpdate.evidence?.file?.name}`,
            });
            await uploadTos3(
              linkPreSignUpload,
              dataUpdate.evidence.file?.originFileObj
            );
          }
          SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);

          setRefresh(!refresh);
          const resultLine = newLines.filter((line) => line.key !== key);
          setNewLines(resultLine);
        }
      } else {
        const payload: MoneyIndirectCostInformationEditPayload = {
          actualCost: dataUpdate.actualCost ?? 0,
          documentIndirectCostId: +documentIndirectCostId,
          endDate: dataUpdate.targetPeriod
            ? helpers.getFilterTimeTo(dataUpdate.targetPeriod[1])
            : null,
          id: Number(key),
          inputMethodCategoryId: dataUpdate.inputMethod ?? null,
          manualInput: manualInput,
          multiplicationFactor: multiplicationFactor,
          paymentDate: helpers.getFilterTime(dataUpdate.datePayment) ?? null,
          startDate: dataUpdate.targetPeriod
            ? helpers.getFilterTime(dataUpdate.targetPeriod[0])
            : null,
          evidence: dataUpdate.evidence?.file
            ? dataUpdate.evidence?.file.name
            : record.evidence,
          note: record?.note ?? "",
        };
        if (
          record?.status?.code === STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE
        ) {
          dispatch(setDocumentEdit(payload));
          return;
        }
        const response = await service.putMoneyIndirectCostInformationEdit(
          payload
        );
        if (response && response.status === `${COMMON.HTTP_STATUS_OK}`) {
          if (dataUpdate.evidence?.file?.originFileObj) {
            const companyId = JSON.parse(localStorage.getItem("user") ?? `{}`)
              ?.company?.id;
            const linkPreSignUpload = await getLinkPresignUpload({
              keyName: `re/${companyId}/${SCREEN_NAME}/${Number(key)}/${
                dataUpdate.evidence?.file?.name
              }`,
            });
            await uploadTos3(
              linkPreSignUpload,
              dataUpdate.evidence.file?.originFileObj
            );
          }
          setRefresh(!refresh);

          const newEditKey = editingKeys.filter((keyEdit) => keyEdit !== key);
          setEditingKeys(newEditKey);

          SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
        }
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };
  const updateOnNoteModal = async (payload: any) => {
    const response = await service.putMoneyIndirectCostInformationEdit(payload);
    if (response && response.status === `${COMMON.HTTP_STATUS_OK}`) {
      if (payload.evidence?.file?.originFileObj) {
        const companyId = JSON.parse(localStorage.getItem("user") ?? `{}`)
          ?.company?.id;
        const linkPreSignUpload = await getLinkPresignUpload({
          keyName: `re/${companyId}/${SCREEN_NAME}/${Number(payload.id)}/${
            payload.evidence?.file?.name
          }`,
        });
        await uploadTos3(
          linkPreSignUpload,
          payload.evidence.file?.originFileObj
        );
      }
      setRefresh(!refresh);
      const newEditKey = editingKeys.filter(
        (keyEdit) => keyEdit + "" !== payload.id + ""
      );
      setEditingKeys(newEditKey);
      SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
    } else throw new Error(NOTIFICATION_TITLE.ERROR);
  };

  const viewApproveModal = (record: any) => {
    const payload: MoneyIndirectCostInformationEditPayload = {
      actualCost: record.actualCost,
      documentIndirectCostId: record.documentIndirectCostId,
      endDate: record.endDate,
      id: Number(record.key),
      inputMethodCategoryId: record.inputMethodCategoryId,
      manualInput: record.manualInput,
      multiplicationFactor: record.multiplicationFactor,
      paymentDate: record.paymentDate,
      startDate: record.startDate,
      evidence: record.evidence,
      note: record.note,
    };
    dispatch(
      setDocumentView({
        ...payload,
        status: record.status,
      })
    );
  };

  const cancelEditing = (record: any) => {
    const valueEdit = form.isFieldTouched(`record${record.key}`);
    if (!String(record.id).includes("new")) {
      const evidence = form.getFieldValue([`record${record.key}`, "evidence"]);
      if (valueEdit || (!evidence && record.evidence)) {
        ConfirmModal({
          onOk: () => {
            const filteredKeys = editingKeys.filter(
              (element: any) => element !== record.key
            );
            setEditingKeys(filteredKeys);
            const dataCurrent = data.map((element) => {
              if (element.key === record.key)
                return {
                  ...originalData?.find((item) => item.key === record.key),
                };

              return element;
            });
            setData(dataCurrent);
          },
          onCancel: () => {},
          title: MESSAGE.MESSAGE_020,
          description: MESSAGE.MESSAGE_022_1,
          extraDescription: MESSAGE.MESSAGE_022_2,
          canceText: LABEL_MESSAGE.CANCEL_MODAL,
          okText: LABEL_MESSAGE.OK_MODAL,
        });
      } else {
        const filteredKeys = editingKeys.filter(
          (element: any) => element !== record.key
        );
        setEditingKeys(filteredKeys);
      }
    } else {
      if (valueEdit) {
        ConfirmModal({
          onOk: () => {
            const resultsLine = newLines.filter(
              (element: any) => element.key !== record.key
            );
            setNewLines(resultsLine);
            const filteredKeys = editingKeys.filter(
              (element: any) => element !== record.key
            );
            setEditingKeys(filteredKeys);
          },
          onCancel: () => {},
          title: MESSAGE.MESSAGE_020,
          description: MESSAGE.MESSAGE_022_1,
          extraDescription: MESSAGE.MESSAGE_022_2,
          canceText: LABEL_MESSAGE.CANCEL_MODAL,
          okText: LABEL_MESSAGE.OK_MODAL,
        });
      } else {
        const resultsLine = newLines.filter(
          (element: any) => element.key !== record.key
        );
        setNewLines(resultsLine);
        const filteredKeys = editingKeys.filter(
          (element: any) => element !== record.key
        );
        setEditingKeys(filteredKeys);
      }
    }
  };

  const deleteRecord = async (value: any) => {
    ConfirmModalAsync({
      onOk: async () => {
        try {
          dispatch(setLoading(true));

          const response =
            await service.deleteMoneyIndirectCostInformationDelete({
              id: [value.id],
            });
          if (response?.status === `${COMMON.HTTP_STATUS_OK}`) {
            const newData = data.filter((element) => element.id !== value.id);
            setData(newData);
            SuccessNotification(
              response?.message ?? NOTIFICATION_TITLE.SUCCESS
            );
          }
        } catch (error: any) {
          ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
          dispatch(setLoading(false));
        }
      },
      onCancel: () => {},
      title: MESSAGE.MESSAGE_MODAL_DELETE_TITLE,
      description: MESSAGE.MESSAGE_DESCRIPTION_CR_7,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_DELETE,
      className: "confirm__modal",
    });
  };

  const handleViewEvidence = async (record: any) => {
    try {
      dispatch(setLoading(true));
      const companyId = JSON.parse(localStorage.getItem("user") ?? `{}`)
        ?.company?.id;
      const response = await service.getPresignLinkS3({
        keyName: `re/${companyId}/${SCREEN_NAME}/${Number(record.id)}/${
          record.evidence
        }`,
      });
      setLinkEvidence(response.results);
      const checkFile = record.evidence?.split(".").pop();
      const evidenceName = record.evidence?.split(".").slice(0, -1).join(".");
      dispatch(setEvidenceName(evidenceName));
      if (checkFile === "pdf") {
        dispatch(showPdfViewerModal());
      } else dispatch(showImageViewerModal());
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };
  const resetEditingLines = () => {
    setNewLines([]);
    setEditingKeys([]);
  };

  const onCheckAllChange = (checked: boolean) => {
    setCheckAll(checked);
    if (checked) {
      const checkedItems: any[] = [];
      for (let element of data) {
        if (
          element?.status?.code ===
          STATUS_CM_CHILD_TABLE.MONEY_NOT_APPROVED.CODE
        )
          checkedItems.push(element.id);
      }
      setCheckedList(checkedItems);
      dispatch(
        setCheckedCollapse({
          id: parentData.id,
          checked: checkedItems,
        })
      );
    } else {
      setCheckedList([]);
      dispatch(
        setCheckedCollapse({
          id: parentData.id,
          checked: [],
        })
      );
    }
  };
  const isCheckbox = (record: any): boolean =>
    checkedList.some((element) => element === record.key);

  const onCheckboxChange = (record: any, checked: boolean) => {
    // setCheckedList(list);
    let newCheckList: any[] = [];

    if (checked) {
      newCheckList = [...checkedList, record.key];
    } else {
      const checkedData = checkedList.filter(
        (element: any) => element !== record.key
      );
      newCheckList = checkedData;
    }
    const possibleItems: any[] = [];

    for (let element of data) {
      if (
        element?.status?.code === STATUS_CM_CHILD_TABLE.MONEY_NOT_APPROVED.CODE
      )
        possibleItems.push(element.id);
    }
    setCheckedList(newCheckList);
    setCheckAll(newCheckList.length === possibleItems.length);
    dispatch(
      setCheckedCollapse({
        id: parentData.id,
        checked: newCheckList,
      })
    );
  };

  const checkParentCBChange = (
    parentData: any,
    checkedCollapse: { id: number; checked: number[] }[]
  ) => {
    const isChecked = checkedCollapse.some(
      (element) => element.id === parentData.id
    );
    if (!isChecked) {
      setCheckAll(false);
      setCheckedList([]);
    }
  };
  const onAttendanceLaborCostChange = (
    record: any,
    property: string,
    value: any,
    projectId: number,
    constructionId: number
  ) => {
    try {
      if (value?.length !== 2) return;

      const foundConstruction = constructionDirectCost.find(
        (element) => element.monthYear === value[1].format("MM-YYYY")
      );
      if (String(record.id).includes("new")) {
        // new data
        const newNewLines = newLines.map((element) => {
          if (element.id === record.id)
            element[`directCost`] = foundConstruction?.directCost ?? 0;
          return element;
        });
        // calculate total redux
        setNewLines(newNewLines);
      } else {
        // old data
        const newData = data.map((element: any) => {
          if (element.id === record.id)
            element[`directCost`] = foundConstruction?.directCost ?? 0;
          return element;
        });
        setData(newData);
      }
    } catch (error: any) {
      return;
    }
  };

  return {
    form,
    editingKeys,
    data,
    newLines,
    refresh,
    inputCategories,
    linkEvidence,
    saveKeys,
    checkAll,
    checkedList,
    isEditing,
    edit,
    save,
    cancelEditing,
    addNewLine,
    onValueNumberChange,
    deleteRecord,
    getMoneyIndirectCostInformationList,
    getMoneyIndirectCostInformationInputCategory,
    handleFirstRender,
    handleViewEvidence,
    checkEnableButtons,
    isEnableSaveButton,
    afterClearEvidence,
    resetEditingLines,
    onCheckAllChange,
    isCheckbox,
    onCheckboxChange,
    setParentData,
    checkParentCBChange,
    viewApproveModal,
    updateOnNoteModal,
    onAttendanceLaborCostChange,
  };
};

export default CollapsedTableHandler;
