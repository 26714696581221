import { CM0032Handler } from "../handler/HandlerImpl";
import Table from "./Table";
import Header from "./Header";

import { MENU_ITEM } from "../../../CM0026/presenter/handler/Handler";
import TableByProject from "./TableByProject";
import CM0033Component from "../../../CM0033";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import CM0063Component from "../../../CM0063";
import { useEffect } from "react";
import helpers from "../../../../../common/helpers/common";
import ModalApprove from "../../../../../common/components/approval-flow/ModalApprove";
import MESSAGE from "../../../../../common/constants/MESSAGE";
import { useSearchParams } from "react-router-dom";
import COMMON from "../../../../../common/constants/COMMON";

interface props {
  handler: CM0032Handler;
}

const MainUI = ({ handler }: props) => {
  const visibleCM0033 = useSelector(
    (state: RootState) => state.cm0033.isVisibleCM0033
  );
  const visibleCM0063 = useSelector(
    (state: RootState) => state.cm0063.isVisible
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab") || "";

  let isRender = true;
  useEffect(() => {
    if (isRender) {
      if (
        handler.menu === MENU_ITEM[0].key &&
        !handler.openFilter &&
        (helpers.getFilterTimeMonth(handler.from) !==
          handler.filterCurrent.from ||
          helpers.getFilterTimeMonthTo(handler.to) !== handler.filterCurrent.to)
      ) {
        Promise.all([
          handler.getSummaryProjectReceiveMoneyListByTrader({
            from: handler.clear ? "" : helpers.getFilterTimeMonth(handler.from),
            to: handler.clear ? "" : helpers.getFilterTimeMonthTo(handler.to),
            page: COMMON.DEFAULT_PAGE,
            size: COMMON.DEFAULT_SIZE,
          }),
          handler.getSummaryProjectReceiveMoneyTotalByTrader({
            from: handler.clear ? "" : helpers.getFilterTimeMonth(handler.from),
            to: handler.clear ? "" : helpers.getFilterTimeMonthTo(handler.to),
          }),
        ]);
      }
    }
    return () => {
      isRender = false;
    };
  }, [handler.menu, handler.from, handler.to, handler.openFilter]);

  useEffect(() => {
    (async () => {
      if (!handler.openFilter) {
        handler.handleGetStatus(
          {
            from: helpers.getFilterTimeMonth(
              handler.menu === MENU_ITEM[0].key ? handler.from : handler.from2
            ),
            to: helpers.getFilterTimeMonthTo(
              handler.menu === MENU_ITEM[0].key ? handler.to : handler.to2
            ),
          },
          handler.menu
        );
      }
    })();
  }, [
    handler.clear,
    handler.menu,
    handler.from,
    handler.to,
    handler.refreshStatus,
    handler.openFilter,
  ]);

  useEffect(() => {
    if (isRender) {
      if (
        handler.menu === MENU_ITEM[1].key &&
        !handler.openFilter &&
        (helpers.getFilterTimeMonth(handler.from2) !==
          handler.filterCurrent.from ||
          helpers.getFilterTimeMonthTo(handler.to2) !==
          handler.filterCurrent.to)
      ) {
        handler.getSummaryProjectReceiveMoneyList({
          from: handler.clear ? "" : helpers.getFilterTimeMonth(handler.from2),
          to: handler.clear ? "" : helpers.getFilterTimeMonthTo(handler.to2),
        });
        handler.getSummaryProjectReceiveMoneyTotal({
          from: handler.clear ? "" : helpers.getFilterTimeMonth(handler.from2),
          to: handler.clear ? "" : helpers.getFilterTimeMonthTo(handler.to2),
        });
      }
    }
    return () => {
      isRender = false;
    };
  }, [handler.menu, handler.from2, handler.to2, handler.openFilter]);
  useEffect(() => {
    handler.initialFilter();
  }, [handler.menu]);

  useEffect(() => {
    if (tab === "6") {
      searchParams.set("menu", MENU_ITEM[0].key);
      setSearchParams(searchParams);
    }
  }, [tab]);

  return (
    <div className="cm024">
      <Header handler={handler} />
      {handler.menu === MENU_ITEM[0].key ? (
        <Table handler={handler} />
      ) : (
        <TableByProject handler={handler} />
      )}
      {visibleCM0033 ? <CM0033Component /> : null}
      {visibleCM0063 ? <CM0063Component /> : null}
    </div>
  );
};

export default MainUI;
