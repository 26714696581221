import React from 'react';

// Media
import '../../../../../assets/styles/ORD/ORD001/content.css';
import { Select, Table } from 'antd';
import ColumnTable from './ColumnTable';
import PaginationComponentList from '../../../../../common/components/pagination/PaginationComponentList';
import { SELECT_SUBSCRIPTION } from '../../contants';
import { ORD001Handler } from '../handler/HandlerImpl';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';

type props = {
    handler: ORD001Handler;
};

const { Option } = Select;

const FILTER_ALL = {
    name: 'すべて',
    value: 'すべて',
};

const ContentComponent = ({ handler }: props) => {
    const { columns, rowSelection } = ColumnTable({ handler });
    const listDataStatus = useSelector((state: RootState) => state.ord001.listStatus);
    const listDataPlan = useSelector((state: RootState) => state.ord001.listPlan);

    return (
        <div className="ord--001__content">
            <div className="ord--001__component-header">
                <div className="ord--001__component-header--unit">
                    <div className="ord--001__component-header--unit__text">プラン区分</div>
                    <div className="ord--001__component-header--unit__option">
                        <Select
                            bordered={false}
                            onChange={handler.onChangePlan}
                            value={handler.choosePlan}
                            defaultValue={handler.choosePlan}
                            getPopupContainer={(trigger: any) => trigger.parentNode}
                        >
                            <Option value={FILTER_ALL.value}>{FILTER_ALL.name}</Option>
                            {listDataPlan.map((state: any) => {
                                return (
                                    <Option key={state?.subCategory?.code} value={`${state.id}`}>
                                        {state?.subCategory?.description}
                                    </Option>
                                );
                            })}
                        </Select>
                    </div>
                </div>

                <div className="ord--001__component-header--unit">
                    <div className="ord--001__component-header--unit__text">ステータス</div>
                    <div className="ord--001__component-header--unit__option">
                        <Select
                            bordered={false}
                            onChange={handler.onChangeStatus}
                            value={handler.chooseStatus}
                            defaultValue={handler.chooseStatus}
                            getPopupContainer={(trigger: any) => trigger.parentNode}
                        >
                            <Option value={FILTER_ALL.value}>{FILTER_ALL.name}</Option>
                            {listDataStatus.map((state: any) => {
                                return (
                                    <Option key={state?.code} value={`${state.id}`}>
                                        {state?.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </div>
                </div>

                <div className="ord--001__component-header--unit">
                    <div className="ord--001__component-header--unit__text">オプション機能</div>
                    <div className="ord--001__component-header--unit__option">
                        <Select
                            bordered={false}
                            onChange={handler.onChangeSubscription}
                            value={handler.chooseSubscription}
                            defaultValue={handler.chooseSubscription}
                            getPopupContainer={(trigger: any) => trigger.parentNode}
                        >
                            <Option value={FILTER_ALL.value}>{FILTER_ALL.name}</Option>
                            {SELECT_SUBSCRIPTION.map((state: any) => {
                                return (
                                    <Option key={state?.code} value={`${state.value}`}>
                                        {state?.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </div>
                </div>

                <div className="ord--001__component-header--unit">
                    <div className="ord--001__component-header--unit__text">有効期限</div>
                    <div className="ord--001__component-header--unit__option">
                        <Select
                            bordered={false}
                            onChange={handler.onChangeRangeExpiredDate}
                            value={handler.chooseRangeExpiredDate}
                            defaultValue={handler.chooseRangeExpiredDate}
                            getPopupContainer={(trigger: any) => trigger.parentNode}
                        >
                            <Option value={FILTER_ALL.value}>{FILTER_ALL.name}</Option>
                            {handler.dataSelectExpiredDate.map((state: any) => {
                                return (
                                    <Option key={state?.code} value={`${state.value}`}>
                                        {state?.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </div>
                </div>

                <div className="ord--001__component-header--button">
                    <div
                        className="ord--001__component-button__one"
                        onClick={handler.fitlerWithOption}
                    >
                        絞込み
                    </div>
                    <div className="ord--001__component-button__two" onClick={handler.resetOption}>
                        クリア
                    </div>
                </div>
            </div>
            <div className="ord--001__component-table">
                <Table
                    bordered
                    rowSelection={{
                        ...rowSelection,
                    }}
                    columns={columns}
                    dataSource={handler.dataTable}
                    scroll={{ x: 3000 }}
                    pagination={false}
                />
                <PaginationComponentList
                    total={handler.total}
                    page={handler.page}
                    size={handler.size}
                    setPage={handler.onPageChange}
                    setSize={handler.onSizeChange}
                />
            </div>
        </div>
    );
};

export default ContentComponent;
