import { DatePicker, Form, FormInstance, Input } from "antd";
import helpers from "../../../../../common/helpers/common";
import COMMON, {
  MAX_LENGTH,
  PLAN_TYPE,
} from "../../../../../common/constants/COMMON";
import REGEX from "../../../../../common/constants/REGEX";
import {
  inputPhoneNumberFormat,
  typeInteger,
} from "../../../../../common/helpers/typeInput";
import CalendarSvg from "../../../../../assets/icons/calendar.svg";
import locale from "antd/es/date-picker/locale/ja_JP";
import "moment/locale/ja";
import "../../../../../assets/styles/OM/OM002.css";
import "../../../../../assets/styles/OM/OM004.css";
import "../../../../../assets/styles/OM/OM007.css";

import MESSAGE from "../../../../../common/constants/MESSAGE";
import { DetailMemberEntity } from "../../../../OM/OM006/entity/Entity";
import UploadAvatarComponent from "../../../../../common/components/upload-image";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { CCUS_VERRIFICATION_STATE } from "../../../../../common/constants/STATUS";

type props = {
  form: FormInstance<any>;
  detailMember: DetailMemberEntity | null;
  memberName: string;
  onExpirationDateChange: (
    value: moment.Moment | null,
    dateString: string
  ) => void;
  getAvatarBinary: (value: any) => void;
};

const UserFormComponent = ({
  memberName,
  form,
  detailMember,
  getAvatarBinary,
  onExpirationDateChange,
}: props) => {
  const isOrderBasic =
    useSelector((state: RootState) => state.auth.authData?.order?.code) ===
    PLAN_TYPE.PLAN_BASIC;

  return (
    <>
      <UploadAvatarComponent
        form={form}
        getBinary={getAvatarBinary}
        initialImageUrl={
          detailMember?.s3ImageUrl !== "" ? detailMember?.s3ImageUrl : undefined
        }
        style={{ marginLeft: 242 + 14 }}
      />
      <main className="w-100-percent" style={{ display: "flex" }}>
        <section style={{ width: "100%", display: "flex" }}>
          <div style={{ width: "50%" }}>
            <Form.Item
              name="name"
              labelAlign="right"
              colon={false}
              className="form-item-label "
              label={
                <div
                  className="w-100-percent pr-20 form-label-member"
                  style={{ marginLeft: 208 }}
                >
                  名前<span style={{ color: "#FE0909" }}>*</span>
                </div>
              }
              rules={[
                {
                  whitespace: true,
                  message: MESSAGE.MESSAGE_REQUIRED_FIELD,
                },
                {
                  required: true,
                  message: MESSAGE.MESSAGE_REQUIRED_FIELD,
                },
                {
                  pattern: new RegExp(REGEX.MEMBER_NAME_REGEX),
                  message: MESSAGE.MESSAGE_60,
                },
                () => ({
                  validator(_, value) {
                    if (!value || !value.trim()) return Promise.resolve();
                    const valueArr = value.split("");
                    const isOnlyBracket = valueArr.every(
                      (element: any) => element === "(" || element === ")"
                    );
                    return isOnlyBracket
                      ? Promise.reject(new Error(MESSAGE.MESSAGE_60))
                      : Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                style={{ height: 40, width: 375 }}
                maxLength={20}
                onChange={(e) => {
                  helpers.preventInputJapanese(e);
                }}
                placeholder="メンバーの名前を入力してください"
                onBlur={() => helpers.onBlurTrimFormItem(form, "name")}
              />
            </Form.Item>
            <div
              style={{
                display: "flex",
                height: 40,
                alignItems: "center",
                marginBottom: 16,
              }}
            >
              <div
                style={{ width: 242, textAlign: "right", marginRight: 24 }}
                className="detail-label "
              >
                部門
              </div>
              <div
                style={{ textAlign: "left", marginRight: 0 }}
                className="detail-info "
              >
                {detailMember?.group?.name || "-"}
              </div>
            </div>

            {isOrderBasic ? (
              <div
                style={{
                  display: "flex",
                  height: 40,
                  alignItems: "center",
                  marginBottom: 16,
                }}
              >
                <div
                  style={{ width: 242, textAlign: "right", marginRight: 24 }}
                  className="detail-label "
                >
                  役職
                </div>
                <div
                  style={{ textAlign: "left", marginRight: 24 }}
                  className="detail-info "
                >
                  {detailMember?.role?.name || "-"}
                </div>
              </div>
            ) : (
              <></>
            )}
            <div style={{ display: "flex", marginLeft: 149 }}>
              <Form.Item
                name="CCUSID"
                labelAlign="right"
                colon={false}
                className="form-item-label error-message-300"
                label={
                  <div className="w-100-percent pr-20 form-label-member">
                    CCUS技能者ID
                  </div>
                }
                style={{ width: 280 }}
                rules={[
                  () => ({
                    validator(_, value) {
                      const expirationDate =
                        form.getFieldValue("expirationDate");
                        value = value.replaceAll(" ", "");
                      if (
                        (!value && expirationDate) ||
                        (value && value.length < MAX_LENGTH.CCUSID) ||
                        (value &&
                          !REGEX.PHONENUMBER.test(value.replaceAll(" ", "")))
                      )
                        return Promise.reject(MESSAGE.MESSAGE_LESS_THAN_14);
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input
                  onKeyPress={typeInteger}
                  onInput={(e: React.FormEvent<HTMLInputElement>) => {
                    const inputElement = e.currentTarget;
                    const inputValue = inputElement.value;
                    const filteredValue = inputValue.replace(/[^0-9]/g, "");
                    inputElement.value = filteredValue.slice(0, 14);
                  }}
                  onChange={(e: any) => {
                    helpers.preventInputJapanese(e);
                    helpers.inputCCUSFormat(e, "CCUSID", form);
                  }}
                  style={{ height: 40, width: 159 }}
                  maxLength={17}
                  placeholder="xxxx xxxx xxxx xx"
                  disabled={detailMember?.status == CCUS_VERRIFICATION_STATE.DONE}
                />
              </Form.Item>
              <Form.Item
                name="expirationDate"
                labelAlign="right"
                colon={false}
                className="form-item-label "
                label={
                  <div className="w-100-percent  form-label-member">
                    有効期限
                  </div>
                }
                style={{ marginLeft: 14, width: 200 }}
              >
                <DatePicker
                  onChange={onExpirationDateChange}
                  format={COMMON.FORMAT_DATE}
                  className="ci001-date-picker"
                  inputReadOnly
                  locale={locale}
                  getPopupContainer={(trigger: any) => trigger.parentNode}
                  placeholder="[ ---- / -- / -- ]"
                  suffixIcon={
                    <img alt="" src={CalendarSvg} className="icon-calender" />
                  }
                  style={{ width: 136 }}
                />
              </Form.Item>
            </div>
          </div>

          <div style={{ width: "50%" }}>
            <Form.Item
              name="address"
              labelAlign="right"
              colon={false}
              className="form-item-label "
              label={
                <div
                  className="w-100-percent pr-20 form-label-member"
                  style={{ marginLeft: 209 }}
                >
                  住所
                </div>
              }
              rules={[
                {
                  whitespace: true,
                  message: MESSAGE.MESSAGE_REQUIRED_FIELD,
                },

                {
                  pattern: new RegExp(REGEX.EXCEPT_VIETNAMESE),
                  message: MESSAGE.MESSAGE_INPUT_INVALID,
                },
              ]}
            >
              <Input
                style={{ height: 40, width: 375, marginRight: 141 }}
                maxLength={MAX_LENGTH.ADDRESS}
                onChange={(e) => {
                  helpers.preventInputJapanese(e);
                }}
                placeholder="記入例：〇〇市〇〇区１−２−１"
                onBlur={() => helpers.onBlurTrimFormItem(form, "address")}
              />
            </Form.Item>

            <Form.Item
              name="phoneNumber"
              labelAlign="right"
              colon={false}
              style={{}}
              className="form-item-label "
              label={
                <div
                  className="w-100-percent pr-20 form-label-member"
                  style={{ marginLeft: 176 }}
                >
                  電話番号<span style={{ color: "#FE0909" }}>*</span>
                </div>
              }
              rules={[
                () => ({
                  validator(_, value) {
                    if (value && value !== "") {
                      value = value.trim().replaceAll(" ", "");
                      value = value.replaceAll("-", "");
                      if (!REGEX.PHONENUMBER_2.test(value))
                        return Promise.reject(
                          MESSAGE.MESSAGE_WRONG_PHONE_FORMAT
                        );
                      if (value?.length < 10) {
                        return Promise.reject(MESSAGE.MESSAGE_INVALID_INPUT);
                      }

                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(MESSAGE.MESSAGE_REQUIRED_FIELD)
                    );
                  },
                }),
              ]}
            >
              <Input
                style={{ height: 40, width: 375, marginRight: 141 }}
                placeholder="メンバーの電話番号を入力してください"
                maxLength={COMMON.MAX_LENGTH_PHONE_NUMBER}
                onKeyPress={typeInteger}
                onPaste={typeInteger}
                onChange={(e) => {
                  helpers.preventInputJapanese(e);
                  inputPhoneNumberFormat(e, "phoneNumber", form);
                }}
              />
            </Form.Item>
            <div
              style={{
                display: "flex",

                height: 40,
                alignItems: "center",
                marginBottom: 16,
              }}
            >
              <div
                style={{ width: 235, textAlign: "right", marginRight: 34 }}
                className="detail-info "
              >
                ID
              </div>
              <div
                style={{ textAlign: "left", marginRight: 24, width: 460 }}
                className="detail-info "
              >
                {detailMember?.username || "-"}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                height: 40,
                alignItems: "center",
              }}
            >
              <div
                style={{ width: 235, textAlign: "right", marginRight: 34 }}
                className="detail-info "
              >
                メールアドレス
              </div>
              <div
                style={{ textAlign: "left", marginRight: 24, width: 460 }}
                className="detail-info "
              >
                {detailMember?.emailAddress || "-"}
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default UserFormComponent;
