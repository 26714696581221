import { Form } from "antd";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { CCUS02ServiceImpl } from "../../usecase/ServiceImpl";
import { useEffect, useState } from "react";
import MESSAGE, { LABEL_MESSAGE, NOTIFICATION_TITLE } from "../../../../../common/constants/MESSAGE";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { DataTableType, FilterCCUSType, ResultDataType } from "../../entity/Entity";
import moment from "moment";
import { DEFAULT_PARAM, MENU_ITEM, PARAMS, REGISTER_TYPE } from "../../constants";
import COMMON from "../../../../../common/constants/COMMON";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import { RangeDateType } from "../../../../AM/AM002/entity/Entity";
import ErrorModalCCUS from "../../../../../common/components/modal/ErrorModalCCUS";
import IconWarning from "../../../../../assets/icons/icon-warning-circle-red.svg";

const CCUS002Handler = (service: CCUS02ServiceImpl) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const state = searchParams.get(PARAMS.state) || MENU_ITEM[0].code;

  const [dataTable, setDataTable] = useState<DataTableType[]>([]);
  const [totalRecord, setTotalRecord] = useState<number>(0);

  const sortType = searchParams.get(PARAMS.sortType);
  const sortBy = searchParams.get(PARAMS.sortBy);
  const page = searchParams.get(PARAMS.page) || COMMON.DEFAULT_PAGE + "";
  const size = searchParams.get(PARAMS.size) || COMMON.DEFAULT_SIZE + "";

  const [rangeDate, setRangeDate] = useState<RangeDateType>({
    from: moment().startOf("month"),
    to: moment(),
  });

  const [listProject, setListProject] = useState<FilterCCUSType[]>([]);
  const [listCompany, setListCompany] = useState<FilterCCUSType[]>([]);
  const [listApprover, setListApprover] = useState<FilterCCUSType[]>([]);

  const [pageProject, setPageProject] = useState<number>(DEFAULT_PARAM.page);
  const [pageCompany, setPageCompany] = useState<number>(DEFAULT_PARAM.page);
  const [pageApprover, setPageApprover] = useState<number>(DEFAULT_PARAM.page);

  const [sizeProject, setSizeProject] = useState<number>(DEFAULT_PARAM.size);
  const [sizeCompany, setSizeCompany] = useState<number>(DEFAULT_PARAM.size);
  const [sizeApprover, setSizeApprover] = useState<number>(DEFAULT_PARAM.size);

  const [loadingProject, setLoadingProject] = useState<boolean>(false);
  const [loadingCompany, setLoadingCompany] = useState<boolean>(false);
  const [loadingApprover, setLoadingApprover] = useState<boolean>(false);

  const [selectedProject, setSelectedProject] = useState<string>("")
  const [selectedCompany, setSelectedCompany] = useState<string>("")
  const [selectedApprover, setSelectedApprover] = useState<string>("")

  const [timeSheet, setTimeSheet] = useState<any>({
    attendanceDateGreatest: "",
    attendanceDateLeast: ""
  });

  const currentScroll: any = document.querySelector(".ant-table-body");

  const fromDate =
    searchParams.get("startDate")
  const toDate =
    searchParams.get("endDate")

  const handleChangeMenuChosen = (menu: string) => {
    if (menu != state) {
      setDataTable([]);
      setSelectedProject("")
      setSelectedCompany("")
      setSelectedApprover("")
      searchParams.set(PARAMS.state, menu);
      searchParams.set(PARAMS.page, DEFAULT_PARAM.page + "");
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    onResetFilter()
  }, [state]);

  const onPageChange = (value: number) => {
    if (currentScroll) {
      currentScroll.scrollTo(0, 0)
    }
    searchParams.set(PARAMS.page, value + "");
    setSearchParams(searchParams);
  };
  const onSizeChange = (value: number) => {
    searchParams.set(PARAMS.page, "1");
    searchParams.set(PARAMS.size, value + "");
    setSearchParams(searchParams);
  };

  const getTimesheetSyncData = async (params: {
    projectId?: number;
    companyId?: number;
    approverId?: number;
    state?: string;
    from?: string;
    to?: string;
    syncTime?: string;
    registerType?: string;
    sortBy?: string;
    sortType?: string;
    page?: number;
    size?: number;
  }) => {
    dispatch(setLoading(true))
    try {
      const response = await service.getTimesheetSyncData(params);

      setDataTable(convertDataTable(response.results));

      setTotalRecord(response.pagination.count || 0)
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false))

    }
  };

  const convertDataTable = (data: ResultDataType[]) => {
    const result: DataTableType[] = data.map((item: ResultDataType, index: number) => {
      return {
        ...item,
        key: item.id,
        No: index + 1 + (Number(page) - 1) * Number(size),
        project: item?.project.name,
        company: item?.company.name,
        user: item?.user.fullName,
        attendanceDate: moment(item?.attendanceDate).format(COMMON.FORMAT_DATE),
        checkin: moment(item.checkin, true).isValid()
          ? moment(item.checkin).format(COMMON.FORMAT_TIME1)
          : "-",
        checkout: moment(item.checkout, true).isValid()
          ? moment(item.checkout).format(COMMON.FORMAT_TIME1)
          : "-",
        registerType: item?.registerType === REGISTER_TYPE[0].value ? REGISTER_TYPE[0].name : REGISTER_TYPE[1].name,
        syncTime: moment(item.syncTime, true).isValid()
          ? moment(item.syncTime).format(COMMON.FORMAT_DATE)
          : "-",
        approver: item?.approver?.fullName,
        approvedAt: moment(item.approvedAt, true).isValid()
          ? moment(item.approvedAt).format(COMMON.FORMAT_DATE_TIME)
          : "-",
      }
    })
    return result
  }

  const funcCheckSortOrder = (
    columnName: string,
    sortType: string | null,
    sortBy: string | null,
  ): 'ASC' | 'DESC' | undefined => {
    if (columnName === sortBy) {
      return !sortType ? undefined : sortType === 'ASC' ? 'ASC' : 'DESC';
    }
  };
  const funcSortTable = (column: string, sortOrder: string) => {
    searchParams.set(PARAMS.sortBy, column);
    searchParams.set(PARAMS.sortType, sortOrder);
    setSearchParams(searchParams);
  };

  const onFromDateChange = (value: any) => {
    setRangeDate({ ...rangeDate, from: value });
  };
  const onToDateChange = (value: any) => {
    setRangeDate({ ...rangeDate, to: value });
  };

  const initiateFilter = () => {
    const currentTime = moment().subtract(1, 'days');
    form.setFieldsValue({
      startDate: currentTime,
      endDate: currentTime
    });
  };

  const onFilter = (filter: any) => {
    let from = "";
    let to = "";

    const startDate = filter.startDate;
    if (startDate) from = moment(startDate).format(COMMON.FORMAT_DATE2);
    const endDate = filter.endDate
    if (endDate) to = moment(endDate).format(COMMON.FORMAT_DATE2);

    searchParams.set(PARAMS.projectId, filter.project || "");
    searchParams.set(PARAMS.companyId, filter.company || "");
    searchParams.set(PARAMS.approverId, filter.approver || "");
    searchParams.set(PARAMS.syncTime, filter.registrationDate || "");
    searchParams.set(PARAMS.startDate, from);
    searchParams.set(PARAMS.endDate, to);
    searchParams.set(PARAMS.registerType, filter.registerType || "");
    searchParams.set(PARAMS.page, DEFAULT_PARAM.page + "");

    setSearchParams(searchParams);
  };

  const onResetFilter = () => {
    searchParams.delete(PARAMS.projectId);
    searchParams.delete(PARAMS.companyId);
    searchParams.delete(PARAMS.approverId);
    searchParams.delete(PARAMS.syncTime);
    searchParams.delete(PARAMS.registerType);
    searchParams.delete(PARAMS.sortBy);
    searchParams.delete(PARAMS.sortType);
    searchParams.set(PARAMS.page, COMMON.DEFAULT_PAGE + "");

    form.resetFields()

    searchParams.delete(PARAMS.startDate);
    searchParams.delete(PARAMS.endDate);
    setSelectedProject("")
    setSelectedCompany("")
    setSelectedApprover("")
    setSearchParams(searchParams);
  };

  const onCollapse = (value: boolean) => {
    searchParams.set("isCollapsed", value ? "isCollapsed" : "");
    setSearchParams(searchParams);
  };

  const handleGetProject = () => {
    const param: any = {
      page: DEFAULT_PARAM.page,
      size: DEFAULT_PARAM.size,
      state,
    }
    if (selectedCompany) param.companyId = selectedCompany;
    if (selectedApprover) param.approverId = selectedApprover;
    setListProject([]);
    setPageProject(1);
    setSizeProject(10);
    getProjectListCCUS(param);
  };

  const handleScrollProject = (e: any) => {
    const target = e.target;

    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      setPageProject(pageProject + 1);
      const param: any = {
        state,
        page: pageProject + 1,
        size: sizeProject,
      }
      if (selectedCompany) param.companyId = selectedCompany;
      if (selectedApprover) param.approverId = selectedApprover;
      getProjectListCCUS(param);
    }
  };

  const handleGetCompany = () => {
    const param: any = {
      page: DEFAULT_PARAM.page,
      size: DEFAULT_PARAM.size,
      state,
    }
    if (selectedProject) param.projectId = selectedProject;
    if (selectedApprover) param.approverId = selectedApprover;
    setListCompany([]);
    setPageCompany(1);
    setSizeCompany(10);
    getDependedCompanyListCCUS(param);
  };

  const handleScrollCompany = (e: any) => {
    const target = e.target;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      setPageCompany(pageProject + 1);
      const param: any = {
        state,
        page: pageCompany + 1,
        size: sizeCompany,
      }
      if (selectedProject) param.projectId = selectedProject;
      if (selectedApprover) param.approverId = selectedApprover;
      getDependedCompanyListCCUS(param);
    }
  };

  const handleGetApprover = () => {
    const param: any = {
      page: DEFAULT_PARAM.page,
      size: DEFAULT_PARAM.size,
      state,
    }
    if (selectedProject) param.projectId = selectedProject;
    if (selectedCompany) param.companyId = selectedCompany;
    setListApprover([]);
    setPageApprover(1);
    setSizeApprover(10);
    getApproverListCCUS(param);
  };

  const handleScrollApprover = (e: any) => {
    const target = e.target;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      setPageApprover(pageProject + 1);
      const param: any = {
        state,
        page: pageApprover + 1,
        size: sizeApprover,
      }
      if (selectedProject) param.projectId = selectedProject;
      if (selectedCompany) param.companyId = selectedCompany;
      getApproverListCCUS(param);
    }
  };

  const getProjectListCCUS = async (params: {
    companyId?: number;
    approverId?: number;
    state?: string;
    page: number;
    size: number;
  }) => {
    setLoadingProject(true)
    try {
      const response = await service.getProjectListCCUS(params);
      if (params.page === 1) {
        setListProject(response);
      } else {
        setListProject([...listProject, ...response]);
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      setLoadingProject(false)
    }
  };

  const getDependedCompanyListCCUS = async (params: {
    projectId?: number;
    approverId?: number;
    state?: string;
    page: number;
    size: number;
  }) => {
    setLoadingCompany(true)
    try {
      const response = await service.getDependedCompanyListCCUS(params);
      if (params.page === 1) {
        setListCompany(response);
      } else {
        setListCompany([...listCompany, ...response]);
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      setLoadingCompany(false)
    }
  };

  const getApproverListCCUS = async (params: {
    companyId?: number;
    projectId?: number;
    state?: string;
    page: number;
    size: number;
  }) => {
    setLoadingApprover(true)
    try {
      const response = await service.getApproverListCCUS(params);
      if (params.page === 1) {
        setListApprover(response);
      } else {
        setListApprover([...listApprover, ...response]);
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      setLoadingApprover(false)
    }
  };

  const getCCUSTimesheet = async (params: {
    projectId?: number;
    companyId?: number;
    approverId?: number;
    state?: string;
    syncTime?: string;
    registerType?: string;
    from?: string;
    to?: string;
  }) => {
    setLoadingProject(true);
    try {
      const response = await service.getCCUSTimesheet(params);
      if (response.results) {
        // const { attendanceDateGreatest, attendanceDateLeast } = response.results;

        setTimeSheet(response.results);
        // setRangeDate({
        //   from: attendanceDateLeast,
        //   to: attendanceDateGreatest
        // });

        // const startDate = attendanceDateLeast ? moment(attendanceDateLeast) : null;
        // const endDate = attendanceDateGreatest ? moment(attendanceDateGreatest) : null;

        // if (form && form.setFieldValue) {
        //   if (startDate) form.setFieldValue("startDate", startDate);
        //   else form.resetFields(["startDate"]);

        //   if (endDate) form.setFieldValue("endDate", endDate);
        //   else form.resetFields(["endDate"]);
        // }

      }

    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      setLoadingProject(false);
    }
  };


  const openModalError = (error: any) => {
    ErrorModalCCUS({
      description: [error.content, error.action],
      okText: LABEL_MESSAGE.CANCEL_MODAL,
      title: MESSAGE.TITLE_ERROR_CCUS,
      onOk: () => {
      },
      iconUrl: IconWarning
    });
  }

  const onChangeProject = (value: string) => {
    setSelectedProject(value);
  };

  const onChangeCompany = (value: string) => {
    setSelectedCompany(value);
  };

  const onChangeApprover = (value: string) => {
    setSelectedApprover(value);
  };

  useEffect(() => {
    initiateFilter();
  }, [state]);
  return {
    state,
    sortType,
    sortBy,
    dataTable,
    form,
    rangeDate,
    listProject,
    listCompany,
    listApprover,
    loadingProject,
    loadingCompany,
    loadingApprover,
    totalRecord,
    timeSheet,
    handleChangeMenuChosen,
    onPageChange,
    onSizeChange,
    getTimesheetSyncData,
    funcCheckSortOrder,
    funcSortTable,
    onFilter,
    onResetFilter,
    onCollapse,
    initiateFilter,
    onFromDateChange,
    onToDateChange,
    getProjectListCCUS,
    getDependedCompanyListCCUS,
    getApproverListCCUS,
    handleGetProject,
    handleGetCompany,
    handleGetApprover,
    handleScrollProject,
    handleScrollCompany,
    handleScrollApprover,
    openModalError,
    onChangeProject,
    onChangeCompany,
    onChangeApprover,
    getCCUSTimesheet
  };
};

export default CCUS002Handler;
