import React, { useEffect } from 'react';
import { HandlerImpl } from '../handler/HandlerImpl';
import { Table, Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import PaginationComponentList from '../../../../../../common/components/pagination/PaginationComponentList';
import IconCircleCheck from '../../../../../../assets/icons/akar-icons_circle-check-fill.svg';
import ModalReject from '../../../../../../common/components/modal-reject/ModalReject';
import ModalRejectView from '../../../../../../common/components/modal-reject/ModalRejectView';
import WarningIcon from '../../../../../../assets/icons/warning-red.svg';
import CM006Component from '../../../../CM006';
import helpers from '../../../../../../common/helpers/common';
import COMMON, {
  STATUS_CM_CHILD_TABLE,
} from '../../../../../../common/constants/COMMON';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import {
  AUTHORIZATION_CODE,
  SYSTEM_ROLES,
} from '../../../../../../common/constants/AUTHORIZATION';
interface props {
  handler: HandlerImpl;
}

type DataType = {
  no: number;
  fileName: string;
  status: {
    id: number;
    name: string;
    code: string;
    type: string;
  };
  filingDate: string;
  submitter: string;
  reason: string;
  canApprove: boolean;
};

const MainUI = ({ handler }: props) => {
  const isVisible = useSelector((state: RootState) => state.cm006.isVisible);

  useEffect(() => {
    handler.getDataView({
      statusId: handler.status ? handler.status : undefined,
      page: handler.page,
      size: handler.size,
      from: !handler.clear ? helpers.getFilterTime(handler.from) : undefined,
      to: !handler.clear ? helpers.getFilterTimeTo(handler.to) : undefined,
      sortType: handler.sortType ? handler.sortType : undefined,
      sortBy: handler.sortType ? 'submittedDate' : undefined,
      keyword: handler.keyword ? handler.keyword : undefined,
    });
  }, [
    handler.status,
    handler.page,
    handler.size,
    handler.from,
    handler.to,
    handler.keyword,
    handler.sortType,
    handler.refresh,
    handler.clear,
  ]);

  const columns: ColumnsType<DataType> = [
    {
      key: 'no',
      dataIndex: 'no',
      title: 'No',
      width: 110,
      align: 'center',
    },
    {
      key: 'fileName',
      dataIndex: 'fileName',
      title: '資料名',
      align: 'center',
      onCell: (record) => {
        return {
          onClick: () => handler.handleViewModalCM006(record),
          style: { cursor: 'pointer' },
        };
      }, width: 343,

    },
    {
      key: 'status',
      dataIndex: 'status',
      title: 'ステータス',
      align: 'center', width: 343,

      render: (record, row) => {
        return (
          <>
            <span
              style={{
                color:
                  record.code === STATUS_CM_CHILD_TABLE.MONEY_REJECT.CODE
                    ? STATUS_CM_CHILD_TABLE.MONEY_REJECT.COLOR
                    : record.code === STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE
                      ? STATUS_CM_CHILD_TABLE.MONEY_APPROVED.COLOR
                      : '#FF9054',
              }}
            >{`${record.name ?? ''}`}</span>
            {record.code === STATUS_CM_CHILD_TABLE.MONEY_REJECT.CODE ? (
              <img
                alt=""
                src={WarningIcon}
                className="cm053-icon"
                onClick={() => handler.handleViewRejectReason(row.reason)}
              />
            ) : null}
          </>
        );
      },
    },
    {
      key: 'submittedDate',
      dataIndex: 'submittedDate',
      title: '提出日',
      align: 'center',
      sorter: true,
      showSorterTooltip: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: !handler.sortType
        ? undefined
        : handler.sortType === 'ASC'
          ? 'ascend'
          : 'descend',
      render: (record) => {
        if (!record) return '';
        return moment(record).format(COMMON.FORMAT_DATE_OM);
      }, width: 343,

    },
    {
      key: 'user',
      dataIndex: 'user',
      title: '提出者',
      align: 'center',
      render: (record) => {
        return `${record.fullName ?? ''}${record?.branchName ? `（${record?.branchName}）` : ''
          }`;
      }, width: 343,

    },
  ];
  return (
    <div className="cm047 cm047-table">
      <Table
        bordered
        columns={columns}
        pagination={false}
        dataSource={handler.dataTable}
        onChange={(_, filter, sorter) => handler.handleSortTable(sorter)}
      />
      {handler.dataTable.length > 0 ? (
        <PaginationComponentList
          disabled={handler.dataTable.length !== 0 ? false : true}
          total={handler.total}
          page={handler.page}
          size={handler.size}
          setPage={handler.onPageChange}
          setSize={handler.onSizeChange}
        />
      ) : null}
      {isVisible ? (
        <CM006Component
          disableSubmitOrReject={
            !(
              handler.detailSubmit?.status ===
              STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE
            )
          }
          onReject={() => handler.handleRejectData(handler.detailSubmit?.id)}
          onSubmit={() => handler.handleSubmitData(handler.detailSubmit?.id)}
        />
      ) : null}
      <ModalReject
        visible={handler.visibleReject}
        form={handler.formReject}
        funcCancel={handler.handleCancelReject}
        funcSubmit={handler.handleSubmitReject}
        funcChangeValue={handler.handleChangeValueReject}
      />
      <ModalRejectView
        visible={handler.visibleRejectView}
        funcClose={handler.handleCloseViewRejectReason}
        rejectReason={handler.rejectReason}
      />
    </div>
  );
};

export default MainUI;
