import React from 'react';
import moment from 'moment';
import COMMON, { TAX_CODE } from '../../../../../common/constants/COMMON';
import helpers from '../../../../../common/helpers/common';
import { Empty } from 'antd';
import { OffsetCost } from '../../entity/Entity';
import EyeViewDetailSvg from '../../../../../assets/icons/eye-view-detail.svg';
import TooltipText from '../../../../../common/components/tooltip-text/TooltipText';
import MESSAGE from '../../../../../common/constants/MESSAGE';

type props = {
    constructionList: any[];
};

const ConstructionTableComponent = ({ constructionList }: props) => {
    return (
        <React.Fragment>
            <tr className="bg-fa">
                <td colSpan={2}></td>
                <td>工事名</td>
                <td>対象期間</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td style={{ width: '40px', textAlign: 'center', marginTop: 13 }}></td>
            </tr>
            {constructionList.length === 0 ? (
                <tr>
                    <td colSpan={10}>
                        <Empty
                            style={{ width: '100%' }}
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={MESSAGE.MESSAGE_DATA_NOT_FOUND}
                        />
                    </td>
                </tr>
            ) : (
                constructionList.map((element, index) => (
                    <tr key={`table${element.id}`} className="">
                        <td style={{ width: 75 }}></td>

                        {/*
                        + STT
                        */}
                        <td style={{ width: 75 }}> {index + 1}</td>
                        <td>
                            <div style={{ width: 400 }}>
                                <TooltipText content={element?.construction?.description ?? ''} />
                            </div>
                        </td>
                        {/*
                        + 対象期間 
                        Lấy giá trị trường 対象期間 có ngày 支払日 với status 承認済 ở màn CM009, lấy những 対象期間 có ngày 支払日 thuộc phạm vi filter tại mục #9.8
                        */}
                        <td>{helpers.getStartEndDate(element.startDate, element.endDate)}</td>

                        {/*
                        + 支払日
                        Lấy ngày 支払日 ở màn CM009 theo 対象期間 bên trên
                        */}
                        <td>
                            {element.paymentDate
                                ? moment(element.paymentDate).format(COMMON.FORMAT_DATE_OM)
                                : ''}
                        </td>
                        <td>{`${helpers.formatNumberMoney(element.actualCost)}円`}</td>
                        <td>{`${helpers.formatNumberMoney(element.consumptionTax)}円`}</td>
                        <td>{`${helpers.formatNumberMoney(
                            element.paymentAmountIncludingTax,
                        )}円`}</td>
                        <td></td>
                    </tr>
                ))
            )}
        </React.Fragment>
    );
};

export default ConstructionTableComponent;
