import HomeSvg from "../../../../../assets/icons/home.svg";

import BreadcrumbComponent from "../../../../../common/components/breadcrumb/BreadcrumbComponent";
import MenuWrapperComponent from "../../../../../common/components/menu-wrapper";

// Icon
import EyeIcon from "../../../../../assets/icons/db001-eye.svg";
import ArrowRight from "../../../../../assets/icons/arrow-right.svg";
import SwapRightBlack from "../../../../../assets/icons/swap-right-black.svg";
import ArrowDown from "../../../../../assets/icons/drop-down-arrow-down.svg";
import CalendarBlack from "../../../../../assets/icons/calendar-black.svg";

// CSS
import { DatePicker, Dropdown, Empty, Form, Layout, Menu, Select } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import "../../../../../assets/styles/DB/db001.css";
import BottomAxisChart from "../../../../../common/components/charts/BottomAxisChart";
import Legend from "../../../../../common/components/charts/Legend";
import MyResponsiveBar from "../../../../../common/components/charts/MyResponsiveBar";
import MyResponsiveBarDup from "../../../../../common/components/charts/MyResponsiveBarDup";
import MyResponsivePie from "../../../../../common/components/charts/MyResponsivePie";
import PaginationComponentDB from "../../../../../common/components/pagination/PaginationComponentDB";
import { RootState } from "../../../../../store";
import DB002Component from "../../../DB002";
import DB003Component from "../../../DB003";
import DB004Component from "../../../DB004";
import DB007Component from "../../../DB007";
import {
  BUDGET_KEY,
  DEFAULT_SIZE,
  PARAMS_FILTER_MODE,
  PARAMS_PROJECT,
  PARAMS_PROJECT_FROM,
  PARAMS_PROJECT_NAME,
  PARAM_COMPANY_ID,
  PARAM_END_DATE,
  PARAM_START_DATE,
} from "../handler/Handler";
import { DB001Handler } from "../handler/HandlerImpl";
import TableComponent from "./Table";
import ComposeChartDB001 from "../../../../../common/components/charts/compose-chart/ComposeChart";
import helpers from "../../../../../common/helpers/common";
import moment from "moment";
import locale from "antd/es/date-picker/locale/ja_JP";
import COMMON from "../../../../../common/constants/COMMON";
import CalendarBlueSVG from "../../../../../assets/icons/calendar-blue.svg";
import DB006Component from "../../../DB006";
import { useUpdateEffect } from "usehooks-ts";
import { PAGE_PARAM } from "../../../../ORD/ORD001/contants";
import TooltipText from "../../../../../common/components/tooltip-text/TooltipText";

const { RangePicker } = DatePicker;
interface props {
  handler: DB001Handler;
}

const MainUI = ({ handler }: props) => {
  const BREADCRUMB_DB001 = [
    {
      title: "ダッシュボード",
      url: "",
      icon: (
        <img
          style={{ paddingBottom: 2, paddingRight: 8 }}
          alt=""
          src={HomeSvg}
        />
      ),
    },
  ];

  const LEGEND001 = [
    {
      color: "linear-gradient(270deg, #FFC53D 0%, #EA6B38 100%)",
      name: "完成出来高",
    },
    {
      color:
        "linear-gradient(90deg, #6648FF 0%, #6A65EB 49.48%, rgba(123, 97, 255, 0.80) 100%)",
      name: "請求残高",
    }
  ];

  const LEGEND002 = [
    {
      color: "linear-gradient(270deg, #2EC5C1 0%, #119CD3 100%)",
      name: "稼働原価",
    },
    {
      color: "linear-gradient(270deg, #FFC53D 0%, #EA6B38 100%)",
      name: "予算残高",
    },
    {
      color: "#0050AE",
      name: "予算消化率(％)",
    },
  ];

  const FILTER_MODE = [
    {
      id: 0,
      name: "デフォルト",
    },
    {
      id: 1,
      name: "フィルター",
    },
  ];

  const visibleDB002 = useSelector(
    (state: RootState) => state.db002.isVisibleDB002
  );
  const visibleDB003 = useSelector(
    (state: RootState) => state.db003.isVisibleDB003
  );
  const visibleDB004 = useSelector(
    (state: RootState) => state.db004.isVisibleDB004
  );
  const visibleDB007 = useSelector(
    (state: RootState) => state.db007.isVisibleDB007
  );
  const visibleDB006 = useSelector(
    (state: RootState) => state.db006.isVisibleDB006
  );
  const [searchParams, setSearchParams] = useSearchParams();

  const filterMode = searchParams.get(PARAMS_FILTER_MODE) ?? "0";
  const projectId = searchParams.get(PARAMS_PROJECT);
  const projectName = searchParams.get(PARAMS_PROJECT_NAME) ?? "";
  const fromD = searchParams.get(PARAMS_PROJECT_FROM) ?? "";
  const companyId = searchParams.get(PARAM_COMPANY_ID) || null;
  const page = Number(searchParams.get(PAGE_PARAM)) || COMMON.DEFAULT_PAGE;

  // Hiển thị data các dự án từ: tháng bắt đầu dự án~tháng hiện tại
  const fromDate =
    searchParams.get(PARAM_START_DATE) ||
    moment().startOf("month").format(COMMON.FORMAT_DATE);
  const toDate =
    searchParams.get(PARAM_END_DATE) ||
    moment().endOf("month").format(COMMON.FORMAT_DATE);

  React.useEffect(() => {
    handler.getDataTable({
      projectId: projectId,
      page: page,
      size: DEFAULT_SIZE,
      from: fromDate,
      to: toDate,
    });
  }, [page, fromDate, toDate]);

  React.useEffect(() => {
    if (!projectId) return;
    const commonParams = {
      projectId: projectId,
      from: helpers.getFilterTime(fromDate),
      to: helpers.getFilterTimeTo(toDate),
    };
    Promise.all([
      handler.getEmployeesAndOperatingCosts({
        ...commonParams,
        initDatePayload: {
          from: fromDate,
          to: toDate,
        },
      }),
      handler.getTurnOver(commonParams),
      handler.getManOfDays(commonParams),
      handler.getPartnerCompany(commonParams),
      handler.getBudgetData(commonParams),
    ]);
  }, [projectId, fromDate, toDate]);

  React.useEffect(() => {
    if (companyId && projectId)
      handler.getEmployeesOperatingAndOperatingCosts({
        projectId: projectId,
        from: helpers.getFilterTime(fromDate),
        to: helpers.getFilterTimeTo(toDate),
        companyId: companyId,
        initDatePayload: {
          from: fromDate,
          to: toDate,
        },
      });
  }, [companyId, projectId, fromDate, toDate]);

  useUpdateEffect(() => {
    searchParams.set(
      PARAM_COMPANY_ID,
      handler.selectedPartnerCompanyRealtime ||
        handler.partnerCompany?.[0]?.id ||
        ""
    );
    setSearchParams(searchParams);
  }, [handler.partnerCompany, handler.selectedPartnerCompanyRealtime]);

  React.useEffect(() => {
    if (companyId) {
      handler.updateSelectedPartnerCompany(Number(companyId));
    }

    handler.initiateFilter(fromDate, toDate);
  }, []);

  return (
    <MenuWrapperComponent chosenKey={1} openKey={1}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
        }}
      >
        <div className="om002-header-container">
          <BreadcrumbComponent data={BREADCRUMB_DB001} />
          <div className="header-row-title" style={{ marginTop: 8 }}>
            ダッシュボード
          </div>
        </div>
        <div
          className="am010__calendar-filter"
          id="testPosition"
          style={{
            position: "relative",
            display: "flex",
            alignContent: "center",
          }}
        >
          <Dropdown
            overlay={
              <Menu
                className="am010__dropdown-menu-2"
                onClick={(e: any) => handler.chooseDropdown(e)}
              >
                {FILTER_MODE.map((element) => (
                  <Menu.Item
                    className="am010__dropdown-menu-item"
                    key={element.id}
                    style={{
                      backgroundColor:
                        filterMode === element.id + "" ? "#E7E6FF" : undefined,
                    }}
                  >
                    {element.name}
                  </Menu.Item>
                ))}
              </Menu>
            }
            placement="bottomRight"
            getPopupContainer={(e: any) =>
              e ? document.getElementById("testPosition") : e.parentNode
            }
          >
            <div className="am010__calendar-dropdown-2 center-item">
              <span>{handler.getFilterModeLabel(filterMode, FILTER_MODE)}</span>
              <img alt="" src={ArrowDown} />
            </div>
          </Dropdown>
          <img
            alt=""
            src={ArrowRight}
            style={{ marginLeft: 8, marginRight: 8 }}
          />
          <div style={{ display: "flex" }}>
            {filterMode === FILTER_MODE[0].id + "" ? (
              <div className="default-filter-container center-item">
                <section>契約工事期間の開始日</section>
                <img
                  src={SwapRightBlack}
                  alt=""
                  style={{ margin: "0px 8px 0px 8px" }}
                />
                <section>現在</section>
                <img src={CalendarBlack} alt="" style={{ marginLeft: 8 }} />
              </div>
            ) : (
              <Form form={handler.form} style={{ margin: 0, padding: 0 }}>
                <Form.Item
                  name="filterDate"
                  style={{ margin: 0, padding: 0, height: "100%" }}
                >
                  <RangePicker
                    onOpenChange={handler.onDateFilterChange}
                    size="large"
                    picker="month"
                    inputReadOnly
                    style={{
                      borderRadius: 6,
                      marginRight: 16,
                      borderColor: "#605BFF",
                      color: "#605BFF",
                      width: 220,
                    }}
                    placeholder={["", ""]}
                    locale={locale}
                    className="cm007-datepicker-font"
                    format={COMMON.FORMAT_DATE_JP}
                    getPopupContainer={(trigger: any) => trigger.parentNode}
                    suffixIcon={<img alt="" src={CalendarBlueSVG} />}
                    allowClear={false}
                  />
                </Form.Item>
              </Form>
            )}
          </div>
        </div>
      </div>
      <div className="chart-wrapper">
        <div className="db001-row">
          <div className="db001-item">
            <span style={{ padding: "16px 24px" }} className="db001-title">
              案件出来高
            </span>
            <div className="db001-table__wrapper">
              <div className="db001-table">
                <TableComponent
                  handler={handler}
                  data={handler.dataTable}
                  projectId={Number(projectId)}
                />
                {handler.totalRecord - DEFAULT_SIZE > 0 ? (
                  <PaginationComponentDB
                    page={Number(page)}
                    size={6}
                    total={handler.totalRecord}
                    setPage={handler.onPageChange}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div className="db001-item">
            <div className="db001-title">グラフ１：案件出来高</div>
            <div className="db001-chart">
              <div className="db001-first__chart">
                <MyResponsiveBar
                  data={handler.dataTable}
                  maxAxis={handler.maxTransactionVolume}
                />
              </div>
              <BottomAxisChart
                items={handler.dataTable}
                handleViewDetail={(value: any) =>
                  handler.openPopupDetail("DB002", {
                    ...value,
                    from: fromDate,
                    to: toDate,
                  })
                }
              />
              <div style={{ marginTop: "20px" }}>
                <Legend items={LEGEND001} isPie={false} />
              </div>
            </div>
          </div>
        </div>
        <div className="db001-row">
          <div className="db001-item__row2">
            <div style={{ marginBottom: "16px" }} className="db001-title">
              グラフ２：
              <span
                style={{
                  display: "block",
                  maxWidth: 220,
                }}
              >
                <TooltipText content={projectName} />
              </span>
              出来高
              <img
                src={EyeIcon}
                style={{ marginLeft: "16px" }}
                alt="icon eye"
                onClick={() =>
                  handler.openPopupDetail("DB003", {
                    projectId: Number(projectId),
                    from: fromDate,
                    to: toDate,
                  })
                }
              />
            </div>
            <div className="db001-chart">
              {handler.turnOver[0]?.value > 0 ||
              handler.turnOver[1]?.value > 0 ? (
                <>
                  <MyResponsivePie data={handler.turnOver} />
                </>
              ) : (
                <Empty
                  style={{ marginTop: 130 }}
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="データなし"
                />
              )}
            </div>
          </div>
          <div className="db001-item__row2">
            <div style={{ marginBottom: "8px" }} className="db001-title">
              グラフ３：
              <span
                style={{
                  display: "block",
                  maxWidth: 220,
                }}
              >
                <TooltipText content={projectName} />
              </span>
              予算消化率
              <img
                src={EyeIcon}
                style={{ marginLeft: "16px" }}
                alt="icon eye"
                onClick={() =>
                  handler.openPopupDetail("DB004", {
                    projectId: Number(projectId),
                    from: fromDate,
                    to: toDate,
                  })
                }
              />
            </div>
            <div className="db001-chart">
              {handler.budgetIsNull ? (
                <Empty
                  style={{ marginTop: 150 }}
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="データなし"
                />
              ) : (
                <div
                  style={{
                    height: "300px",
                  }}
                >
                  <MyResponsiveBarDup
                    keys={handler.keysBudgetChart}
                    data={handler.budget}
                    graph="graph3"
                    keyAxisBottom={BUDGET_KEY}
                    maxAxis={handler.maxBudget}
                  />
                  <Legend items={LEGEND002} isPie={false} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="chart-wrapper">
        <div
          className="db001-item"
          style={{ width: "100%", padding: "24px", height: "502px" }}
        >
          <div className="db001-title">
            グラフ４：
            <span
              style={{
                display: "block",
                maxWidth: 220,
              }}
            >
              <TooltipText content={projectName} />
            </span>
            稼働人工数・稼働原価
            <div className="db001-composechart__eye">
              <img
                style={{ marginLeft: "16px" }}
                src={EyeIcon}
                alt="icon eye"
                onClick={() =>
                  handler.openPopupDetail("DB007", {
                    name: projectName,
                    constructionFromDate: fromD,
                  })
                }
              />
            </div>
          </div>
          <React.Fragment>
            <div style={{ width: "100%", flex: 1 }}>
              {handler.employeesAndOperatingCosts?.length > 0 ? (
                <ComposeChartDB001
                  chartName="chart1"
                  barColor={["#0FA44A", "#21E2B4"]}
                  chartData={handler.employeesAndOperatingCosts || []}
                  legends={{
                    laborCostLegend: "稼働原価　円/月",
                    workingDayLegend: "稼働人工数　人工/月",
                  }}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 382,
                  }}
                >
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="データなし"
                  />
                </div>
              )}
            </div>
          </React.Fragment>
        </div>
        <div
          className="db001-item"
          style={{
            width: "100%",
            padding: "24px",
            height: "502px"
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div className="db001-title">
              グラフ５：
              <span
                style={{
                  display: "block",
                  maxWidth: 220,
                }}
              >
                <TooltipText content={projectName} />
              </span>
              協力会社別 稼働人工数・稼働原価
              <div
                style={{
                  cursor: "pointer",
                }}
                className="db001-composechart__eye"
              >
                <img
                  src={EyeIcon}
                  alt="icon eye"
                  style={{ marginLeft: "16px" }}
                  onClick={() => handler.openPopupDetail("DB006")}
                />
              </div>
            </div>
            <Select
              style={{
                width: 202,
              }}
              className="header-select border-color-select"
              onClick={(e: any) => {
                e.stopPropagation();
              }}
              value={handler.selectedPartnerCompanyRealtime}
              onChange={handler.updateSelectedPartnerCompany}
              getPopupContainer={(trigger: any) => trigger.parentNode}
            >
              {handler.partnerCompany.map((element, index) => (
                <Select.Option value={element.id} key={element.id}>
                  {element.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <React.Fragment>
            <div style={{ width: "100%", flex: 1 }}>
              {handler.employeesOperatingAndOperatingCosts.length > 0 ? (
                <ComposeChartDB001
                  chartName="chart2"
                  barColor={["#119CD3 ", "#2EC5C1"]}
                  chartData={
                    handler.employeesOperatingAndOperatingCosts || []
                  }
                  legends={{
                    laborCostLegend: "稼働原価　円/月",
                    workingDayLegend: "稼働人工数　人工/月",
                  }}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 382,
                  }}
                >
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="データなし"
                  />
                </div>
              )}
            </div>
          </React.Fragment>
        </div>
      </div>
      {visibleDB002 ? <DB002Component /> : <></>}
      {visibleDB003 ? <DB003Component /> : <></>}
      {visibleDB004 ? <DB004Component /> : <></>}
      {visibleDB006 ? <DB006Component /> : <></>}
      {visibleDB007 ? <DB007Component /> : <></>}
    </MenuWrapperComponent>
  );
};

export default MainUI;
