import { useState, useRef, useMemo } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import { AM028UseCase } from "../../usecase/ServiceImpl";
import { useSearchParams } from "react-router-dom";
import { SortOrder, FilterValue } from "antd/lib/table/interface";
import { TablePaginationConfig } from "antd/es/table";
import { Form } from "antd";

import DangerIcon from "../../../../../assets/icons/danger-circle.svg";
import { ParamsRequest } from "../../entity/Entity";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import {
  NOTIFICATION_TITLE,
  LABEL_MESSAGE,
} from "../../../../../common/constants/MESSAGE";
import moment from "moment";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import COMMON from "../../../../../common/constants/COMMON";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import MESSAGE from "../../../../../common/constants/MESSAGE";
import helpers from "../../../../../common/helpers/common";
import { showModalApproveConfirm } from "../../../../../common/components/modal-approve-confirm/presenter/slice/Slice";

const CATEGORY_ALL = {
  name: "すべて",
  categoryId: "all",
};

const STATUS_ALL = {
  name: "すべて",
  id: "all_status",
};

export const MENU_ITEM = [
  {
    name: "打刻申請",
    key: "stampApplication",
  },
  {
    name: "休暇申請",
    key: "leaveApplication",
  },
  {
    name: "遅刻・早退申請",
    key: "applicationLate",
  },
];

export const STATUS_REQUEST = [
  {
    name: "承認済み",
    code: "request_approved",
    color: "#0FA44A",
    icon: "",
  },
  {
    name: "承認待ち",
    code: "request_waiting_for_approval",
    color: "#FF9054",
    icon: "",
  },
  {
    name: "否認",
    code: "request_reject",
    color: "#FF0909",
    icon: DangerIcon,
  },
];

export const PARAMS: any = {
  currentTab: "tab",
  menuChosen: "menu",
};

for (let i = 1; i <= 6; i++) {
  PARAMS[`page${i}`] = `page${i}`;
  PARAMS[`size${i}`] = `size${i}`;
  PARAMS[`keyword${i}`] = `keyword${i}`;
  PARAMS[`sortType${i}`] = `sortType${i}`;
  PARAMS[`sortBy${i}`] = `sortBy${i}`;
  PARAMS[`category${i}`] = `category${i}`;
  PARAMS[`status${i}`] = `status${i}`;
  PARAMS[`from${i}`] = `from${i}`;
  PARAMS[`to${i}`] = `to${i}`;
  PARAMS[`clear${i}`] = `clear${i}`;
}

export const DEFAULT_PARAM = {
  page: 1,
  size: 10,
  keyword: "",
  sortType: null,
  sortBy: "",
  currentTab: "1",
  menuChosen: MENU_ITEM[0].key,
  category: CATEGORY_ALL.categoryId,
  status: STATUS_ALL.id,
  from: moment().startOf("month").format(COMMON.FORMAT_DATE2),
  to: moment().format(COMMON.FORMAT_DATE2),
  clear: "0",
};

const AM028Handler = (am028Service: AM028UseCase) => {
  const dispatch = useDispatch();
  const refTable: any = useRef();
  const [formFilter] = Form.useForm();
  const [formReject] = Form.useForm();
  const [dataTable, setDataTable] = useState([]);
  const [total, setTotal] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [visibleAM037, setVisibleAM037] = useState(false);
  const [visibleAM038, setVisibleAM038] = useState(false);
  const [listStatus, setListStatus] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  const [rejectReason, setRejectReason] = useState("");
  const [isChangeReject, setChangeReject] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isUserApprover, setIsUserApprover] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab =
    searchParams.get(PARAMS.currentTab) || DEFAULT_PARAM.currentTab;
  const menuChosen =
    searchParams.get(PARAMS.menuChosen) || DEFAULT_PARAM.menuChosen;

  const page: any = {};
  const size: any = {};
  const sortBy: any = {};
  const sortType: any = {};
  const keyword: any = {};
  const category: any = {};
  const status: any = {};
  const from: any = {};
  const to: any = {};
  const clear: any = {};

  for (let i = 1; i <= 6; i++) {
    page[i] = +(searchParams.get(PARAMS[`page${i}`]) || DEFAULT_PARAM.page);
    size[i] = +(searchParams.get(PARAMS[`size${i}`]) || DEFAULT_PARAM.size);
    sortBy[i] = searchParams.get(PARAMS[`sortBy${i}`]) || DEFAULT_PARAM.sortBy;
    sortType[i] =
      searchParams.get(PARAMS[`sortType${i}`]) || DEFAULT_PARAM.sortType;
    keyword[i] =
      searchParams.get(PARAMS[`keyword${i}`]) || DEFAULT_PARAM.keyword;
    category[i] =
      searchParams.get(PARAMS[`category${i}`]) || DEFAULT_PARAM.category;
    status[i] = searchParams.get(PARAMS[`status${i}`]) || DEFAULT_PARAM.status;
    from[i] = searchParams.get(PARAMS[`from${i}`]) || DEFAULT_PARAM.from;
    to[i] = searchParams.get(PARAMS[`to${i}`]) || DEFAULT_PARAM.to;
    clear[i] = +(searchParams.get(PARAMS[`clear${i}`]) || DEFAULT_PARAM.clear);
  }

  const funcSetKeyData = (raw: any, page: number, size: number) => {
    if (!raw) return [];
    return raw.map((element: { id: number }, index: number) => {
      return {
        ...element,
        key: element?.id,
        no: index + 1 + size * (page - 1),
      };
    });
  };

  const funcCheckParam = (i: number, isSearch?: boolean) => {
    const params: ParamsRequest = {
      page: page[i],
      size: size[i],
    };

    if (sortBy[i]) params.sortBy = sortBy[i];
    if (sortType[i])
      params.sortType = sortType[i] === "ascend" ? "ASC" : "DESC";
    if (from[i] && !clear[i]) params.from = helpers.getFilterTime(from[i]);
    if (to[i] && !clear[i]) params.to = helpers.getFilterTimeTo(to[i]);
    if (status[i] && status[i] !== DEFAULT_PARAM.status)
      params.statusId = status[i];
    if (category[i] && category[i] !== DEFAULT_PARAM.category)
      params.categoryId = category[i];

    if (keyword[i] && isSearch) params.keyword = keyword[i];

    return params;
  };

  const handleGetData = async () => {
    dispatch(setLoading(true));
    funcScrollTopTable();
    try {
      let response: any = {};
      if (currentTab === "1") {
        switch (menuChosen) {
          case MENU_ITEM[0].key:
            const params1 = funcCheckParam(1);
            response = await am028Service.getListMyAttendance(params1);
            setDataTable(
              funcSetKeyData(
                response?.data?.results,
                params1.page,
                params1.size
              )
            );
            break;
          case MENU_ITEM[1].key:
            const params2 = funcCheckParam(2);
            response = await am028Service.getListMyLeave(params2);
            setDataTable(
              funcSetKeyData(
                response?.data?.results,
                params2.page,
                params2.size
              )
            );
            break;
          case MENU_ITEM[2].key:
            const params3 = funcCheckParam(3);
            response = await am028Service.getListMyLateEarly(params3);
            setDataTable(
              funcSetKeyData(
                response?.data?.results,
                params3.page,
                params3.size
              )
            );
            break;
          default:
            break;
        }
      } else {
        switch (menuChosen) {
          case MENU_ITEM[0].key:
            const params4 = funcCheckParam(4);
            response = await am028Service.getListMyReviewAttendance(
              funcCheckParam(4, true)
            );
            setDataTable(
              funcSetKeyData(
                response?.data?.results,
                params4.page,
                params4.size
              )
            );
            break;
          case MENU_ITEM[1].key:
            const params5 = funcCheckParam(5);
            response = await am028Service.getListMyReviewLeave(
              funcCheckParam(5, true)
            );
            setDataTable(
              funcSetKeyData(
                response?.data?.results,
                params5.page,
                params5.size
              )
            );
            break;
          case MENU_ITEM[2].key:
            const params6 = funcCheckParam(6);
            response = await am028Service.getListReviewLateEarly(
              funcCheckParam(6, true)
            );
            setDataTable(
              funcSetKeyData(
                response?.data?.results,
                params6.page,
                params6.size
              )
            );
            break;
          default:
            break;
        }
      }
      setTotal(response?.data?.pagination?.count);
      funcSetDefaultRowKey(response?.data?.results);
    } catch (error: any) {
      setDataTable([]);
      ErrorNotification(error.message || NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getListStatus = async (params: any) => {
    dispatch(setLoading(true));
    try {
      const response = await am028Service.getListStatus(params);
      setListStatus([STATUS_ALL, ...response.data?.results]);
    } catch (error: any) {
      setListStatus([]);
      ErrorNotification(error.message || NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getCategory = async (params: any) => {
    dispatch(setLoading(true));
    try {
      let response = [];
      switch (menuChosen) {
        case MENU_ITEM[0].key:
          response = await am028Service.getAttendanceCategory(params);
          break;
        case MENU_ITEM[1].key:
          response = await am028Service.getLeaveCategory(params);
          break;
        case MENU_ITEM[2].key:
          response = await am028Service.getLateEarlyCategory(params);
          break;
        default:
          break;
      }
      setCategories([CATEGORY_ALL, ...response.data?.results]);
    } catch (error: any) {
    } finally {
      dispatch(setLoading(false));
    }
  };

  const funcRemoveParamsPage = () => {
    searchParams.delete(PARAMS.page1);
    searchParams.delete(PARAMS.page2);
    searchParams.delete(PARAMS.page3);
    searchParams.delete(PARAMS.page4);
    searchParams.delete(PARAMS.page5);
    searchParams.delete(PARAMS.page6);
    setSearchParams(searchParams);
  };

  const getOrganizationIsUserApprover = async () => {
    try {
      dispatch(setLoading(true));
      const response = await am028Service.getOrganizationIsUserApprover();
      setIsUserApprover(response?.results ? true : false);
    } catch (error: any) {
      ErrorNotification(error.message || NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const postRejectReason = async (reason: any) => {
    dispatch(setLoading(true));
    const dataReject = {
      reason: reason?.rejectReason,
      requestId: funcFilterKeyApproveReject(selectedRowKeys, dataTable),
    };
    try {
      const response = await am028Service.postRejectReason(dataReject);
      SuccessNotification(
        response?.data?.message || NOTIFICATION_TITLE.SUCCESS
      );
      setRefresh(!refresh);
      setVisibleAM037(false);
      setChangeReject(false);
      setSelectedRowKeys([]);
      funcRemoveParamsPage();
    } catch (error: any) {
      setListStatus([]);
      ErrorNotification(error.message || NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const funcApprove = async () => {
    const data = {
      requestId: funcFilterKeyApproveReject(selectedRowKeys, dataTable),
    };
    dispatch(setLoading(true));
    try {
      let response: any = {};
      switch (menuChosen) {
        case MENU_ITEM[0].key:
          response = await am028Service.approveAttendance(data);
          break;
        case MENU_ITEM[1].key:
          response = await am028Service.approveLeave(data);
          break;
        case MENU_ITEM[2].key:
          response = await am028Service.approveLateEarly(data);
          break;
      }
      SuccessNotification(
        response?.data?.message || NOTIFICATION_TITLE.SUCCESS
      );
      setRefresh(!refresh);
      setSelectedRowKeys([]);
      funcRemoveParamsPage();
    } catch (error: any) {
      setListStatus([]);
      ErrorNotification(error.message || NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const funcReject = () => {
    setVisibleAM037(true);
    formReject.resetFields();
  };

  const funcSetDefaultRowKey = (raw: any) => {
    if (!raw) return;
    const listRejectApprove = raw.filter(
      (row: { status: { code: string } }) =>
        row?.status?.code === STATUS_REQUEST[0]?.code ||
        row?.status?.code === STATUS_REQUEST[2]?.code
    );
    const listKey = listRejectApprove?.map((element: { id: number }) => {
      return element.id;
    });
    setSelectedRowKeys(listKey);
  };

  const funcFilterKeyApproveReject = (rawKey: any[], raw: any[]) => {
    if (!raw) return [];
    const listData = raw.filter(
      (row) =>
        row?.status?.code !== STATUS_REQUEST[0]?.code &&
        row?.status?.code !== STATUS_REQUEST[2]?.code &&
        rawKey?.includes(row?.id)
    );

    const listKey = listData?.map((element) => {
      return element?.id;
    });

    return listKey;
  };

  const funcCheckIndex = (tab: string, menu: string) => {
    let i = 1;
    if (tab === "1") {
      switch (menu) {
        case MENU_ITEM[0].key:
          i = 1;
          break;
        case MENU_ITEM[1].key:
          i = 2;
          break;
        case MENU_ITEM[2].key:
          i = 3;
          break;
      }
    } else {
      switch (menu) {
        case MENU_ITEM[0].key:
          i = 4;
          break;
        case MENU_ITEM[1].key:
          i = 5;
          break;
        case MENU_ITEM[2].key:
          i = 6;
          break;
      }
    }
    return i;
  };

  const funcSetValueFormFilter = (tab: string, menu: string) => {
    const i = funcCheckIndex(tab, menu);
    formFilter.setFieldsValue({
      keyword: keyword[i],
      status: status[i],
      category: category[i],
      targetPeriod: !clear[i] ? [moment(from[i]), moment(to[i])] : null,
    });
  };

  const handleChangeCurrentTab = (tab: string) => {
    searchParams.set(PARAMS.currentTab, tab);
    searchParams.delete(PARAMS.menuChosen);
    setSearchParams(searchParams);
    setSelectedRowKeys([]);
    funcSetValueFormFilter(tab, menuChosen);
  };
  const handleChangeMenuChosen = (menuItem: string) => {
    searchParams.set(PARAMS.menuChosen, menuItem);
    setSearchParams(searchParams);
    if (menuChosen !== menuItem) setSelectedRowKeys([]);
    funcSetValueFormFilter(currentTab, menuItem);
  };

  const handleChangePage = (page: number) => {
    searchParams.set(
      PARAMS[`page${funcCheckIndex(currentTab, menuChosen)}`],
      page.toString()
    );
    setSearchParams(searchParams);
    setSelectedRowKeys([]);
    funcSetValueFormFilter(currentTab, menuChosen);
  };

  const handleChangeSize = (size: number) => {
    searchParams.set(
      PARAMS[`size${funcCheckIndex(currentTab, menuChosen)}`],
      size.toString()
    );
    searchParams.delete(
      PARAMS[`page${funcCheckIndex(currentTab, menuChosen)}`]
    );
    setSearchParams(searchParams);
    setSelectedRowKeys([]);
    funcSetValueFormFilter(currentTab, menuChosen);
  };

  const handleSetDefaultSorter = (keyColumn: string): SortOrder => {
    const i = funcCheckIndex(currentTab, menuChosen);
    if (!sortBy[i] || sortBy[i] !== keyColumn) return null;
    if (sortBy[i] === keyColumn)
      return sortType[i] === "descend"
        ? "descend"
        : sortType[i] === "ascend"
          ? "ascend"
          : null;

    return null;
  };

  const handleChangeTable = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: any
  ) => {
    if (sorter) {
      const i = funcCheckIndex(currentTab, menuChosen);
      searchParams.set(PARAMS[`sortBy${i}`], sorter.field);
      searchParams.set(PARAMS[`sortType${i}`], sorter.order);
      searchParams.delete(PARAMS[`page${i}`]);
      setSearchParams(searchParams);
    }
  };
  const handleChangeSelectedRowKeys = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const funcViewModalAM038 = (rejectReason: string) => {
    setVisibleAM038(true);
    setRejectReason(rejectReason);
  };

  const handleCancelAM037 = () => {
    if (isChangeReject) {
      ConfirmModal({
        onOk: () => {
          setVisibleAM037(false);
          setChangeReject(false);
        },
        className: "confirm__modal",
        title: MESSAGE.MESSAGE_020,
        description: MESSAGE.MESSAGE_021,
        canceText: LABEL_MESSAGE.CANCEL_MODAL,
        okText: LABEL_MESSAGE.OK_MODAL,
        isCenterWithoutMenu: true,
      });
    } else {
      setVisibleAM037(false);
    }
  };
  const handleOkAM038 = () => {
    setVisibleAM038(false);
  };

  const funcSetDefaultFilter = () => {
    funcSetValueFormFilter(currentTab, menuChosen);
  };

  let searching: any;
  const handleSearch = () => {
    if (searching) clearTimeout(searching);
    searching = setTimeout(() => {
      const i = funcCheckIndex(currentTab, menuChosen);
      const searchText = formFilter.getFieldValue("keyword");
      searchParams.delete(PARAMS[`keyword${i}`]);
      searchParams.delete(PARAMS[`page${i}`]);
      formFilter.setFieldValue("status", status[i]);
      formFilter.setFieldValue("category", category[i]);
      formFilter.setFieldValue(
        "targetPeriod",
        !clear[i] ? [moment(from[i]), moment(to[i])] : null
      );
      if (searchText) searchParams.set(PARAMS[`keyword${i}`], searchText);
      setSearchParams(searchParams);
    }, 1000);
  };

  const handleFinishFormFilter = (filter: {
    category: string;
    status: string;
    targetPeriod: any[];
  }) => {
    const i = funcCheckIndex(currentTab, menuChosen);
    searchParams.delete(PARAMS[`page${i}`]);
    searchParams.delete(PARAMS[`size${i}`]);
    searchParams.delete(PARAMS[`status${i}`]);
    searchParams.delete(PARAMS[`category${i}`]);
    searchParams.delete(PARAMS[`from${i}`]);
    searchParams.delete(PARAMS[`to${i}`]);

    if (filter.category && filter.category !== CATEGORY_ALL.categoryId)
      searchParams.set(PARAMS[`category${i}`], filter.category);
    if (filter.status && filter.status !== STATUS_ALL.id)
      searchParams.set(PARAMS[`status${i}`], filter.status);
    if (filter.targetPeriod && filter.targetPeriod?.length > 0) {
      searchParams.set(
        PARAMS[`from${i}`],
        moment(filter.targetPeriod[0]?._d).format(COMMON.FORMAT_DATE2)
      );
      searchParams.set(
        PARAMS[`to${i}`],
        moment(filter.targetPeriod[1]?._d).format(COMMON.FORMAT_DATE2)
      );
    }
    if (!filter.targetPeriod) searchParams.set(PARAMS[`clear${i}`], "1");
    else searchParams.delete(PARAMS[`clear${i}`]);
    setSearchParams(searchParams);
  };

  const handleChangeFormFilter = (value: any) => { };

  const handleClearFilter = () => {
    const i = funcCheckIndex(currentTab, menuChosen);
    searchParams.delete(PARAMS[`page${i}`]);
    searchParams.delete(PARAMS[`category${i}`]);
    searchParams.delete(PARAMS[`status${i}`]);
    searchParams.delete(PARAMS[`from${i}`]);
    searchParams.delete(PARAMS[`to${i}`]);
    searchParams.delete(PARAMS[`clear${i}`]);

    formFilter.setFieldsValue({
      keyword: keyword[i],
      status: DEFAULT_PARAM.status,
      category: DEFAULT_PARAM.category,
      targetPeriod: [moment(DEFAULT_PARAM.from), moment(DEFAULT_PARAM.to)],
    });

    setSearchParams(searchParams);
  };
  const funcScrollTopTable = () => {
    if (refTable.current) {
      refTable.current?.children[0]?.children[0]?.children[0]?.children[0]?.children[1]?.scrollTo(
        0,
        0
      );
    }
  };

  const showConfirmApproveModal = () => {
    dispatch(showModalApproveConfirm());
  };

  return {
    formFilter,
    formReject,
    currentTab,
    menuChosen,
    page,
    size,
    total,
    keyword,
    clear,
    dataTable,
    selectedRowKeys,
    visibleAM037,
    visibleAM038,
    sortBy,
    sortType,
    status,
    category,
    from,
    to,
    listStatus,
    categories,
    rejectReason,
    refresh,
    refTable,
    isUserApprover,
    handleChangeCurrentTab,
    handleChangeMenuChosen,
    handleChangePage,
    handleChangeSize,
    handleSetDefaultSorter,
    handleChangeTable,
    handleChangeSelectedRowKeys,
    handleCancelAM037,
    handleOkAM038,
    handleFinishFormFilter,
    handleChangeFormFilter,
    handleGetData,
    getListStatus,
    getCategory,
    handleClearFilter,
    funcSetDefaultFilter,
    funcViewModalAM038,
    funcApprove,
    funcReject,
    postRejectReason,
    setChangeReject,
    funcFilterKeyApproveReject,
    handleSearch,
    funcCheckIndex,
    getOrganizationIsUserApprover,
    showConfirmApproveModal
  };
};

export default AM028Handler;
