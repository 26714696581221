import { CCUS061Handler } from "../handler/HandlerImpl";
import { ColumnsType } from "antd/es/table";
import {
  TableIssueAuthenType,
  TableTechnicianInfoType,
} from "../../entity/Entity";
import { Form, Input } from "antd";
import { useMemo } from "react";

import SortTable from "../../../../../common/components/sort-table/SortTable";
import { AUTHENTICATION_KEY } from "../../constants";
import IconInfoRed from "../../../../../assets/icons/icon-info-red.svg";
import REGEX from "../../../../../common/constants/REGEX";
import { getExpirationDateCcus061 } from "../../../../OM/OM002/presenter/ui/MemberTable";

export const COLUMN = (handler: CCUS061Handler) => {
  const sortType = useMemo(() => {
    return handler.sortType;
  }, [handler.sortType]);
  const sortBy = useMemo(() => {
    return handler.sortBy;
  }, [handler.sortBy]);

  const columnsTab1: ColumnsType<TableTechnicianInfoType> = [
    {
      title: "No",
      dataIndex: "No",
      width: 230,
      render: (text) => <div className="text-table-ccus">{text}</div>,
    },
    {
      title: (
        <SortTable
          columnTitle="技能者名"
          sortOrder={handler.funcCheckSortOrder("fullName", sortType, sortBy)}
          onChange={(sortOrder) => handler.funcSortTable("fullName", sortOrder)}
          justifyContent="start"
        />
      ),
      dataIndex: "fullName",
      render: (_, record) => (
        <div
          className="text-table-ccus"
          style={{ color: "#605BFF", cursor: "pointer" }}
          onClick={() => handler.openPopupDetail(record.key)}
        >
          {record.fullName}
        </div>
      ),
      width: 238,
    },
    {
      title: (
        <SortTable
          columnTitle="部門"
          sortOrder={handler.funcCheckSortOrder("group", sortType, sortBy)}
          onChange={(sortOrder) => handler.funcSortTable("group", sortOrder)}
          justifyContent="start"
        />
      ),
      dataIndex: "group",
      render: (text) => <div className="text-table-ccus">{text}</div>,
      width: 238,
    },
    {
      title: "技能者ID",
      dataIndex: "ccusId",
      render: (text) => <div className="text-table-ccus">{text}</div>,
      width: 238,
    },
    {
      title: (
        <SortTable
          columnTitle="有効期限"
          sortOrder={handler.funcCheckSortOrder(
            "ccusExpiredDate",
            sortType,
            sortBy
          )}
          onChange={(sortOrder) =>
            handler.funcSortTable("ccusExpiredDate", sortOrder)
          }
          justifyContent="start"
        />
      ),
      dataIndex: "expiredDate",
      render: (text) => (
        <div className="text-table-ccus">
          {text ? getExpirationDateCcus061(text) : "-"}
        </div>
      ),
      width: 238,
    },
  ];

  const columnsTab2: ColumnsType<TableIssueAuthenType> = [
    {
      title: (
        <SortTable
          columnTitle="技能者ID"
          sortOrder={handler.funcCheckSortOrder("ccusId", sortType, sortBy)}
          onChange={(sortOrder) => handler.funcSortTable("ccusId", sortOrder)}
          justifyContent="start"
        />
      ),
      render: (_, record) => {
        return (
          <div>
            <span
              className={
                record.state === AUTHENTICATION_KEY[1].key
                  ? "text-table-ccus-disable"
                  : "text-table-ccus"
              }
            >
              {record.ccusId}
            </span>
            <Form.Item name={[`record${record.key}`, `ccusId`]} hidden>
              <Input
                disabled={record.state === AUTHENTICATION_KEY[1].key}
                size="large"
                className="input-ccus062"
              />
            </Form.Item>
          </div>
        );
      },
      width: 30,
    },
    {
      title: "技能者姓①",
      render: (_, record) => (
        <Form.Item
          name={[`record${record.key}`, `lastName`]}
          className="input-ccus062-container lastName"
        >
          <Input
            disabled={record.state === AUTHENTICATION_KEY[1].key}
            size="large"
            className="input-ccus062"
            style={{ width: 125 }}
            onInput={(e: any) => {
              let { value } = e.target;
              
              //Replace Number
              value = value.replace(/\d/, "");

              //Replace Katakana
              value = value.replace(REGEX.REGEX_KATAKANA, "");

              ////Uppercase alphabet
              value = value.replace(/[a-z]/g, function (match: any) {
                return match.toUpperCase();
              });
              
              e.target.value = value

              handler.setNotSubmit(true)
            }}
          />
        </Form.Item>
      ),
      width: 170,
    },
    {
      title: "技能者名①",
      render: (_, record) => (
        <Form.Item
          name={[`record${record.key}`, `firstName`]}
          className="input-ccus062-container"
        >
          <Input
            disabled={record.state === AUTHENTICATION_KEY[1].key}
            size="large"
            className="input-ccus062"
            style={{ width: 125 }}
            onInput={(e: any) => {
              let { value } = e.target;
              
              //Replace Number
              value = value.replace(/\d/, "");

              //Replace Katakana
              value = value.replace(REGEX.REGEX_KATAKANA, "");

              ////Uppercase alphabet
              value = value.replace(/[a-z]/g, function (match: any) {
                return match.toUpperCase();
              });
              
              e.target.value = value

              handler.setNotSubmit(true)
            }}
          />
        </Form.Item>
      ),
      width: 170,
    },
    {
      title: "技能者姓(カタカナ)②",
      render: (_, record) => (
        <Form.Item
          name={[`record${record.key}`, `lastNameKatakana`]}
          className="input-ccus062-container"
        >
          <Input
            disabled={record.state === AUTHENTICATION_KEY[1].key}
            size="large"
            className="input-ccus062"
            style={{ width: 125 }}
            onInput={(e: any) => {
              const { value } = e.target;
              if (REGEX.REGEX_LATIN_DIGITS_KATAKANA_ONLY.test(value)) {
                e.target.value = value;
              } else {
                e.target.value = value.replace(/[^A-Za-z0-9\u30A0-\u30FF]/g, "");
              }
             
              handler.setNotSubmit(true)
            }}
          />
        </Form.Item>
      ),
      width: 170,
    },
    {
      title: "技能者名(カタカナ)②",
      render: (_, record) => (
        <div>
          <Form.Item
            name={[`record${record.key}`, `firstNameKatakana`]}
            className="input-ccus062-container"
          >
            <Input
              disabled={record.state === AUTHENTICATION_KEY[1].key}
              size="large"
              className="input-ccus062"
              style={{ width: 125 }}
              onInput={(e: any) => {
                const { value } = e.target;
                if (REGEX.REGEX_LATIN_DIGITS_KATAKANA_ONLY.test(value)) {
                  e.target.value = value;
                } else {
                  e.target.value = value.replace(/[^A-Za-z0-9\u30A0-\u30FF]/g, "");
                }
                handler.setNotSubmit(true)
              }}
            />
          </Form.Item>
        </div>
      ),
      width: 170,
    },
    {
      title: "本人確認番号③",
      render: (_, record) => (
        <Form.Item
          name={[`record${record.key}`, `confirmCode`]}
          className="input-ccus062-container"
        >
          <Input
            disabled={record.state === AUTHENTICATION_KEY[1].key}
            size="large"
            className="input-ccus062"
            style={{ width: "100px" }}
            minLength={4}
            maxLength={4}
            onInput={(e: any) => {
              const { value } = e.target;
              if (/^\d*$/.test(value)) {
              } else {
                e.target.value = value.replace(/\D/g, "");
              }
              handler.setNotSubmit(true)
            }}
          />
        </Form.Item>
      ),
    },
    {
      title: (
        <div>
          生年月日<span className="sterisk">*</span>
        </div>
      ),
      render: (_, record) => (
        <Form.Item
          name={[`record${record.key}`, `dateOfBirth`]}
          className="input-ccus062-container"
        >
          <Input
            disabled={record.state === AUTHENTICATION_KEY[1].key}
            size="large"
            className="input-ccus062"
            style={{ width: "100px" }}
            maxLength={8}
            onInput={(e: any) => {
              const { value } = e.target;
              if (/^\d*$/.test(value)) {
              } else {
                e.target.value = value.replace(/\D/g, "");
              }
              handler.setNotSubmit(true)
            }}
          />
        </Form.Item>
      ),
    },
    {
      title: "認証結果",
      render: (_, record) => {
        return (
          <>
            <Form.Item name={[`record${record.key}`, `state`]} hidden>
              <Input
                disabled={record.state === AUTHENTICATION_KEY[1].key}
                size="large"
                className="input-ccus062"
              />
            </Form.Item>
            <div
              className={`${
                record.state === AUTHENTICATION_KEY[1].key
                  ? "text-state-success"
                  : record.state === AUTHENTICATION_KEY[2].key
                  ? "text-state-error"
                  : ""
              }`}
            >
              {record.state === AUTHENTICATION_KEY[1].key ? (
                AUTHENTICATION_KEY[1].value
              ) : record.state === AUTHENTICATION_KEY[2].key ? (
                <div className="record-state-table">
                  <span>{AUTHENTICATION_KEY[2].value}</span>{" "}
                  <img
                    src={IconInfoRed}
                    style={{ cursor: "pointer" }}
                    onClick={() => handler.openModalError(record)}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </>
        );
      },
      width: 120,
    },
  ];
  const rowSelection = {
    selectedRowKeys: handler.selectedRowsKey,
    onChange: (selectedRowKeys: React.Key[]) => {
      handler.getSelectedKey(selectedRowKeys);
    },
    getCheckboxProps: (record: any) => {
      return {
        disabled: record.state === AUTHENTICATION_KEY[1].key,
      };
    },
  };
  return {
    columnsTab1,
    columnsTab2,
    rowSelection,
  };
};
