import React from 'react';
import { HandlerImpl } from '../handler/HandlerImpl';
import { MENU_ITEM } from '../handler/Handler';
import { Form, Select, Button, DatePicker, Input } from 'antd';

import CalendarSvg from '../../../../../../assets/icons/calendar.svg';
import moment from 'moment';
import COMMON from '../../../../../../common/constants/COMMON';
import locale from 'antd/es/date-picker/locale/ja_JP';
import 'moment/locale/ja';
import helpers from '../../../../../../common/helpers/common';
import { useMemo } from 'react';
import EmptyIcon from '../../../../../../assets/icons/empty-data.svg';
import CM035Component from '../../../CM035/index';
import CM042Component from '../../../CM042/index';
import CM047Component from '../../../CM047/index';
import CM048Component from '../../../CM048/index';
import CM052Component from '../../../CM052/index';
import CM053Component from '../../../CM053/index';
import CM0034Component from '../../../../CM0034';
import CM0039Component from '../../../../CM0039';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';

const { Item } = Form;
const { Option } = Select;
const { RangePicker } = DatePicker;

interface props {
    handler: HandlerImpl;
}

const Body = ({ handler }: props) => {
    const content = useMemo(() => {
        switch (`${handler.menu}${handler.tab}`) {
            case `${MENU_ITEM[0].key}1`:
                return <CM0034Component />;
            case `${MENU_ITEM[1].key}1`:
                return <CM035Component />;
            case `${MENU_ITEM[2].key}1`:
                return <CM0039Component />;
            case `${MENU_ITEM[3].key}1`:
                return <CM042Component />;
            case `${MENU_ITEM[0].key}2`:
                return <CM047Component />;
            case `${MENU_ITEM[1].key}2`:
                return <CM048Component />;
            case `${MENU_ITEM[2].key}2`:
                return <CM052Component />;
            case `${MENU_ITEM[3].key}2`:
                return <CM053Component />;
        }
        return (
            <div className="cm-no-data">
                <img alt="" src={EmptyIcon} />
            </div>
        );
    }, [handler.tab, handler.menu]);

    return (
        <div className="am028-body">
            <div className="am028-nav">
                <ul className="am028-menu">
                    {handler.tabItems.map((menu) => {
                        return (
                            <li
                                key={menu.key}
                                className={`am028-menu-item ${menu.key === handler.menu ? 'am028-menu-item-active' : ''
                                    }`}
                                onClick={() => handler.handleChangeMenuChosen(menu.key)}
                            >
                                {menu.name}
                            </li>
                        );
                    })}
                </ul>
            </div>
            <Form
                name="cm034-cm53-filter"
                className="am028-filter"
                form={handler.formFilter}
                onFinish={handler.handleFinishFormFilter}
            >
                <div className="am028-filter-chosen">
                    <div className="am028-filter-item">
                        <span className="am028-filter-item-label">ステータス</span>
                        <Item name="status">
                            <Select
                                size="large"
                                className="am028-select am028-select-status cm034-cm053-select"
                                getPopupContainer={(trigger: any) => trigger.parentNode}
                            >
                                {handler.statusList.map((select) => {
                                    return (
                                        <Option key={select?.id ?? ''} value={select?.id ?? ''}>
                                            {select.name ?? ''}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Item>
                    </div>
                    <div className="am028-filter-item">
                        <span className="am028-filter-item-label">提出日</span>
                        <Item name="targetPeriod">
                            <RangePicker
                                allowClear={false}
                                inputReadOnly
                                size="large"
                                locale={locale}
                                className="am028-range"
                                format={COMMON.FORMAT_DATE_OM}
                                getPopupContainer={(trigger: any) => trigger.parentNode}
                                suffixIcon={
                                    <img alt="" src={CalendarSvg} className="icon-calender" />
                                }
                            />
                        </Item>
                    </div>
                </div>
                <div className="am028-filter-btn">
                    <Item>
                        <Button htmlType="submit" size="large" className="btn-withdraw am028-btn">
                            絞込み
                        </Button>
                    </Item>
                    <Button
                        htmlType="button"
                        size="large"
                        className="am028-btn btn-text-gray"
                        onClick={handler.handleClearFilter}
                    >
                        クリア
                    </Button>
                </div>
            </Form>
            <div className="cm-body">{content}</div>
        </div>
    );
};

export default Body;
