import moment from 'moment';
import React from 'react';
import COMMON from '../../../../../common/constants/COMMON';
import { Badge, Popover } from 'antd';
import { SM01HandlerImpl } from '../handler/HandlerImpl';
import helpers from '../../../../../common/helpers/common';
import CommentSVG from '../../../../../assets/icons/comment.svg';
import TooltipText from '../../../../../common/components/tooltip-text/TooltipText';
import XIcon from '../../../../../assets/icons/SM01-X.svg';
import { DAY_IN_WEEK } from '../handler/Handler';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { orderEventList } from '../../helper';


const WeekEventPopOverRender = (props: {
    handler: SM01HandlerImpl;
    items: any[];
    date: any;
}) => {
    const { handler, items, date } = props
    const data = items
    return (
        <div className="popup-wrapper">
            <div className="popup-header">
                <div></div>
                <div className="popup-header__text">{`${moment(date).format(COMMON.FORMAT_DATE6)}(${DAY_IN_WEEK[moment(date).days()]
                    })`}</div>
                <div className="popup-header__icon" onClick={() => handler.handleOpenPopover()}>
                    <img src={XIcon} alt="icon-close" />
                </div>
            </div>
            <div className="popup-group">
                {data.map((element: any, idx: number) => {
                    return (
                        <div
                            className="popup-item"
                            key={element.id}
                        >
                            <EventRowWeek event={element} widthInfo={174} widthText={150} openModalDetailSM04={handler.openModalDetailSM04} />
                        </div>
                    )
                }
                )}
            </div>
        </div>
    );
};

export const EventRowWeek = (props: {
    event: any;
    widthInfo?: number,
    widthText?: number,
    openModalDetailSM04: (scheduleId: number) => void
}) => {
    const { openModalDetailSM04, event, widthText = 90, widthInfo = 106 } = props
    const name = event.isFullDay ? `終日 ${event.name}` : `${moment(event.startTime).format(COMMON.FORMAT_TIME3)} ${event.name}`
    const mainColor = event?.category?.colorRgb ?? ""

    const fadedMainColor = helpers.hexToRGBA(mainColor, 0.2)
    const backgroundColor = event.isFullDay ? mainColor : fadedMainColor
    const titleColor = event.isFullDay ? '#fff' : mainColor

    return <div className='event-row-week button-style' style={{ padding: 16, }}
        onClick={(e: any) => {
            e.stopPropagation();
            openModalDetailSM04(event.id)
        }}>
        <section className='info center-item' style={{ background: backgroundColor, color: titleColor, width: widthInfo, textAlign: 'left' }}>
            <div style={{ width: widthText }}>
                <TooltipText content={name} showTooltip={event.name} />
            </div>
        </section>
        <Badge count={event.commentCount} className="avatar-badge-button" color={'#0050AE'} >
            <div
                style={{ border: "none", marginLeft: 8, }}
            >
                <img alt="" src={CommentSVG} style={{ width: 18, height: 18 }} />
            </div>
        </Badge>
    </div >
}

export const UnitWeeklySchedule = (props: {
    handler: SM01HandlerImpl;
    active?: boolean;
    items: any[];
    date: string;
}) => {
    const { handler, active, items, date } = props
    const visibleSm003 = useSelector((state: RootState) => state.sm03.isVisible);
    const visibleSM04 = useSelector((state: RootState) => state.sm04.isVisible);
    const visibleSM010 = useSelector((state: RootState) => state.sm010.isVisible);

    const orderedEvents = orderEventList(items)

    return (
        <div >
            {orderedEvents.map((event: any, idx: number) => {
                if (orderedEvents.length <= 5) {
                    return <div key={event.id} style={{ marginBottom: idx === orderedEvents.length - 1 ? 0 : 6 }}>
                        <EventRowWeek event={event} openModalDetailSM04={handler.openModalDetailSM04} />
                    </div>
                } else {
                    if (idx <= 3) {
                        return <div key={event.id} style={{ marginBottom: 6 }}>
                            <EventRowWeek event={event} openModalDetailSM04={handler.openModalDetailSM04} />
                        </div>
                    } else if (idx === 4) {
                        return visibleSm003 || visibleSM04 || visibleSM010 ?
                            <div className="unit" key={idx} id="testPosition">
                                <div className="unit__desc">+{orderedEvents.length - 2}全て表示</div>
                            </div>
                            : (
                                <div className="unit" key={idx} id="testPosition">
                                    <Popover
                                        key={`${handler.openPopover}`}
                                        getPopupContainer={(e: any) =>
                                            e ? document.getElementById('testPosition') : e.parentNode
                                        }
                                        placement="right"
                                        trigger={'click'}
                                        title=""
                                        content={
                                            <WeekEventPopOverRender
                                                handler={handler}
                                                items={orderedEvents}
                                                date={date}
                                            />
                                        }
                                    >
                                        <div className="unit__desc">+{orderedEvents.length - 4}全て表示</div>
                                    </Popover>
                                </div>
                            );
                    } else return null;
                }
            })}
        </div>
    );
};



export const WeekSchedule = (props: {
    handler: SM01HandlerImpl;
    currentDate: string;
}) => {
    const { handler, currentDate } = props
    const Header = (props: {
        header: any
    }) => {
        const { header } = props
        return (
            <thead>
                <tr>
                    <th style={{ width: 110 }}></th>

                    <th
                        style={{ width: 160 }}
                        className={header['mon']?.isCurrent ? 'th--active' : ''}
                    >
                        {header['mon']?.date} <br /> <span style={{ fontWeight: 400 }}>月</span>
                    </th>
                    <th
                        style={{ width: 160 }}
                        className={header['tue']?.isCurrent ? 'th--active' : ''}
                    >
                        {header['tue']?.date} <br /> <span style={{ fontWeight: 400 }}>火</span>
                    </th>
                    <th
                        style={{ width: 160 }}
                        className={header['web']?.isCurrent ? 'th--active' : ''}
                    >
                        {header['web']?.date} <br /> <span style={{ fontWeight: 400 }}>水</span>
                    </th>
                    <th
                        style={{ width: 160 }}
                        className={header['thu']?.isCurrent ? 'th--active' : ''}
                    >
                        {header['thu']?.date} <br /> <span style={{ fontWeight: 400 }}>木</span>
                    </th>
                    <th
                        style={{ width: 160 }}
                        className={header['fri']?.isCurrent ? 'th--active' : ''}
                    >
                        {header['fri']?.date} <br /> <span style={{ fontWeight: 400 }}>金</span>
                    </th>
                    <th
                        style={{ width: 160 }}
                        className={header['sat']?.isCurrent ? 'th--active' : ''}
                    >
                        {header['sat']?.date} <br /> <span style={{ fontWeight: 400 }}>土</span>
                    </th>
                    <th
                        style={{ width: 160 }}
                        className={header['sun']?.isCurrent ? 'th--active' : ''}
                    >
                        {header['sun']?.date} <br /> <span style={{ fontWeight: 400 }}>日</span>
                    </th>
                </tr>
            </thead>
        );
    };

    const data = handler.events['origin'] ?? [];
    const user = helpers.getObjectFromLocalStorage('user');

    return (
        <div className='popover-no-padding' style={{ overflow: 'auto', maxHeight: '73vh' }}>
            <div style={{ maxHeight: 62, }}>
                <table border={1} className="calendar-week__table" style={{ tableLayout: 'fixed' }}>
                    <Header header={handler.headerWeek} />
                </table>
            </div>
            <div
                style={{ maxHeight: 768, }}
                onScroll={() => handler.handleOpenPopover()}
            >
                <table border={1} className="calendar-week__table" style={{ tableLayout: 'fixed' }}>
                    <tbody>
                        {data.map((item: any, idx: number) => (
                            <tr key={idx + Math.random()}>
                                <td style={{ width: 110 }}>
                                    {helpers.getShortTextWithToolTip(
                                        item.member === user.fullName
                                            ? item.member
                                            : item.fullName ?? '',
                                        10,
                                    )}
                                </td>
                                {item?.data.map((ite: any, ind: number) => (
                                    <td
                                        id="testPosition"
                                        style={{ width: 160 }}
                                        key={ind + Math.random()}
                                        className={ite.date === currentDate ? 'td--active' : ''}
                                    >
                                        <UnitWeeklySchedule
                                            handler={handler}
                                            active={ite.date === currentDate ? true : false}
                                            items={ite.data}
                                            date={ite.date}
                                        />
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

