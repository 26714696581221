import React from 'react';
import { Badge, Calendar, Popover } from 'antd';
import { SM01HandlerImpl } from '../handler/HandlerImpl';
import moment from 'moment';
import COMMON from '../../../../../common/constants/COMMON';
import helpers from '../../../../../common/helpers/common';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import locale from "antd/es/date-picker/locale/ja_JP";
import { DefaultAvatarComponent } from '../../../../../common/components/default-avatar';
import { SYSTEM_ROLES } from '../../../../../common/constants/AUTHORIZATION';
import CommentSVG from '../../../../../assets/icons/comment.svg';
import TooltipText from '../../../../../common/components/tooltip-text/TooltipText';
import XIcon from '../../../../../assets/icons/SM01-X.svg';
import { DAY_IN_WEEK } from '../handler/Handler';
import { orderEventList } from '../../helper';


const MonthlyEventPopOverRender = (props: {
    handler: SM01HandlerImpl;
    items: any[];
    date: any;
}) => {
    const { handler, items, date } = props
    const data = items
    return (
        <div className="popup-wrapper">
            <div className="popup-header">
                <div></div>
                <div className="popup-header__text">{`${moment(date).format(COMMON.FORMAT_DATE6)}(${DAY_IN_WEEK[moment(date).days()]
                    })`}</div>
                <div className="popup-header__icon" onClick={() => handler.handleOpenPopover()}>
                    <img src={XIcon} alt="icon-close" />
                </div>
            </div>
            <div className="popup-group" >
                {data.map((element: any, idx: number) => {
                    return (
                        <div
                            className="popup-item"
                            style={{ width: 232, paddingLeft: 8, paddingRight: 8 }}
                            key={element.id}
                        >
                            <MonthlyEventRow event={element} widthInfo={142} widthText={100} openModalDetailSM04={handler.openModalDetailSM04} />
                        </div>
                    )
                }
                )}
            </div>
        </div>
    );
};

export const MonthlyEventRow = (props: {
    event: any;
    widthInfo?: number,
    widthText?: number,
    openModalDetailSM04: (scheduleId: number) => void
}) => {
    const { openModalDetailSM04, event, widthText = 90, widthInfo = 97.71 } = props
    const name = event.isFullDay ? `終日 ${event.name}` : `${moment(event.startTime).format(COMMON.FORMAT_TIME3)} ${event.name}`
    const mainColor = event?.category?.colorRgb ?? ""

    const fadedMainColor = helpers.hexToRGBA(mainColor, 0.2)
    const backgroundColor = event.isFullDay ? mainColor : fadedMainColor
    const titleColor = event.isFullDay ? '#fff' : mainColor

    const isRoleAdmin = event?.role === SYSTEM_ROLES.ADMIN;
    const isRoleAdminSystem = event?.role === SYSTEM_ROLES.ADMIN_SYSTEM;

    return <div className='event-row-month button-style'
        onClick={(e: any) => {
            e.stopPropagation();
            openModalDetailSM04(event.id)
        }}>
        <section className='info center-item'
            style={{ background: backgroundColor, color: titleColor, width: widthInfo, textAlign: 'left', marginLeft: 8 }}>
            <div style={{ width: widthText, }}>
                <TooltipText content={name} showTooltip={event.name} />
            </div>
        </section>
        <div className='no-click' style={{ marginLeft: 8 }}>
            <DefaultAvatarComponent
                size={24}
                isAdmin={(isRoleAdminSystem || isRoleAdmin)}
                avatar={event?.avatar ?? undefined}
                value={(event?.fullName ?? "")}
            />
        </div>
        <Badge
            count={event.commentCount}
            className="comment-badge-button"
            color={'#0050AE'}
            size='small'
        >
            <div
                style={{ border: "none", }}
            >
                <img alt="" src={CommentSVG} style={{ width: 18, height: 18, marginLeft: 8 }} />
            </div>
        </Badge>
    </div >
}

const MonthSchedule = (props: {
    handler: SM01HandlerImpl;
    filterDate: string;
}) => {
    const { handler, filterDate } = props
    const scroll = useSelector((state: RootState) => state.common.scroll);
    const visibleSm003 = useSelector((state: RootState) => state.sm03.isVisible);
    const visibleSM04 = useSelector((state: RootState) => state.sm04.isVisible);
    const visibleSM010 = useSelector((state: RootState) => state.sm010.isVisible);

    const dateCellRender = (date: moment.Moment) => {
        const isFirstDay = date.format("DD") === "01";
        const isSameDay = date.isSame(moment(filterDate), "day");
        const dateValue = moment(date).format(COMMON.FORMAT_DATE2);

        const monthlyData = handler?.events[dateValue] ? handler?.events[dateValue] : [];
        const orderedEvents = orderEventList(monthlyData)

        return (
            <div
                className="date-cell-render-2"
                style={{
                    backgroundColor: isSameDay ? "#f3f0ff" : "#fff",
                }}
            >
                <section className="month-cell-render-container">
                    <div className={`pt-pb-8 date ${isSameDay ? "is-current" : ""} center-item`}>
                        {moment(date.toLocaleString()).format(
                            isFirstDay ? "MM/DD" : "DD"
                        )}
                    </div>
                    <div className="content">
                        <div className="unit-wrapper-month">
                            {orderedEvents.map((event: any, idx: number) => {
                                if (orderedEvents.length <= 3) {
                                    return <>
                                        <MonthlyEventRow key={event.id} event={event} openModalDetailSM04={handler.openModalDetailSM04} />
                                    </>
                                } else {
                                    if (idx <= 1) {
                                        return <MonthlyEventRow key={event.id} event={event} openModalDetailSM04={handler.openModalDetailSM04} />
                                    } else if (idx === 2) {
                                        return visibleSm003 || visibleSM04 || visibleSM010 ?
                                            <div className="unit center-item" key={event.id} style={{ width: "100%", height: 70 }}>
                                                <div className="unit__desc" style={{ width: 150, height: 300 }}>
                                                    +{orderedEvents.length - 2}全て表示
                                                </div>
                                            </div>
                                            :
                                            <div className="unit center-item" key={event.id} style={{ width: "100%", height: 70 }}>
                                                <Popover
                                                    key={`${scroll}${handler.openPopover}`}
                                                    getPopupContainer={(e: any) =>
                                                        e
                                                            ? document.getElementById('testPosition')
                                                            : e.parentNode
                                                    }
                                                    placement="right"
                                                    trigger={'click'}
                                                    title=""
                                                    content={
                                                        <MonthlyEventPopOverRender
                                                            handler={handler}
                                                            items={orderedEvents}
                                                            date={date}
                                                        />
                                                    }
                                                >
                                                    <div className="unit__desc" >
                                                        +{orderedEvents.length - 2}全て表示
                                                    </div>
                                                </Popover>
                                            </div>
                                    } else return null;
                                }
                            })}
                        </div>
                    </div>
                </section>
            </div>
        );
    };

    return (
        <div id="testPosition" className='popover-no-padding' >
            <Calendar
                mode="month"
                dateFullCellRender={dateCellRender}
                value={moment(filterDate)} locale={locale}
                disabledDate={(current) => {

                    return !(current.month() === moment(filterDate).month())

                }}
            />
        </div>
    );
};

export default MonthSchedule;
