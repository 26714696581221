// Built-in
import React, { useState } from "react";

// 3rd party lib
import moment from "moment";
import { Form } from "antd";
import { useDispatch } from "react-redux";

// Source files
import { setLoading } from "../../../../../common/slice/CommonSlice";
import { CollapsedTableServiceImpl } from "./../../usecase/ServiceImpl";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import ConfirmModalAsync from "../../../../../common/components/modal/ConfirmModalAsync";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import {
  MoneyDirectCostMaterialCreatePayload,
  MoneyDirectCostMaterialDeletePayload,
  MoneyDirectCostMaterialListResult,
  MoneyDirectCostMaterialUpdatePayload,
} from "../../entity/Entity";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import COMMON, {
  STATUS_CM_CHILD_TABLE,
  STATUS_CM_PARENT_TABLE,
} from "../../../../../common/constants/COMMON";
import {
  editParentStatus,
  setCheckedCollapse,
  setEvidenceLink,
} from "../slice/Slice";
import { showImageViewerModal } from "../../../../../common/components/image-viewer-modal/ImageViewSlice";
import helpers from "../../../../../common/helpers/common";
import { showPdfViewerModal } from "../../../../../common/components/pdf-viewer-modal/pdfViewerModalSlice";
import { setDocumentView } from "../../../../../common/components/modal-approve-view/presenter/slice/Slice";
import { setDocumentEdit } from "../../../../../common/components/modal-approve-edit/presenter/slice/Slice";
import { setEvidenceName } from "../../../CM005/presenter/slice/Slice";

const SCREEN_NAME = "CM007";

const CollapsedTableHandler = (service: CollapsedTableServiceImpl) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [parentId, setParentId] = useState<any>();
  const [parentData, setParentData] = useState<any>();
  const [data, setData] = useState<MoneyDirectCostMaterialListResult[]>([]);
  const [originalData, setOriginalData] = useState<
    MoneyDirectCostMaterialListResult[]
  >([]);
  const [editingKeys, setEditingKeys] = useState<any[]>([]);
  const [saveKeys, setSaveKeys] = useState<any[]>([]);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedList, setCheckedList] = useState<any[]>([]);
  const [newLines, setNewLines] = useState<any[]>([]);
  const [newCount, setNewCount] = useState<number>(0);

  const isEnableSaveButton = (record: any): boolean =>
    saveKeys.some((element) => element === record.key + "");

  const checkEnableButtons = (changedValues: any, values: any) => {
    const recordChangedValue = changedValues[Object.keys(changedValues)[0]];
    const changedValue = recordChangedValue[Object.keys(recordChangedValue)[0]];
    const recordKeyName = Object.keys(changedValues)[0]?.replaceAll(
      "record",
      ""
    );
    if (changedValue !== undefined && changedValue !== null) {
      // find in saveKeys
      const foundKey = saveKeys.find((key: any) => {
        return recordKeyName === key;
      });
      if (foundKey) return;
      setSaveKeys([...saveKeys, recordKeyName]);
    } else {
      const changedValueObj = values[Object.keys(changedValues)[0]];
      const isAllUndefined = Object.values(changedValueObj).every(
        (x) => x === undefined || x === null
      );
      if (isAllUndefined) {
        const newSaveKeys = saveKeys.filter(
          (key: any) => key !== recordKeyName
        );
        setSaveKeys(newSaveKeys);
      } else {
        const foundKey = saveKeys.find((key: any) => {
          return recordKeyName === key;
        });
        if (foundKey) return;
        setSaveKeys([...saveKeys, recordKeyName]);
      }
    }
  };

  const afterClearEvidence = (formName: any) => {
    const evidenceChangeValue = form.getFieldValue(formName[0]);
    const recordKeyName = formName[0]?.replaceAll("record", "");

    const isAllUndefined = Object.values(evidenceChangeValue).every(
      (x) => x === undefined || x === null
    );
    if (isAllUndefined) {
      const newSaveKeys = saveKeys.filter((key: any) => key !== recordKeyName);
      setSaveKeys(newSaveKeys);
    } else {
      const foundKey = saveKeys.find((key: any) => {
        return recordKeyName === key;
      });
      if (foundKey) return;
      setSaveKeys([...saveKeys, recordKeyName]);
    }
  };

  const getMoneyDirectCostMaterialListLocal = async (
    childData: MoneyDirectCostMaterialListResult
  ): Promise<void> => {
    try {
      dispatch(setLoading(true));
      // using deep copy to avoid 2 state aim the same memory address
      setData(JSON.parse(JSON.stringify(childData)));
      setOriginalData(JSON.parse(JSON.stringify(childData)));
    } catch (error: any) {
      ErrorNotification(NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getMoneyDirectCostMaterialListApi = async (params: {
    documentDirectMaterialCostId: number;
    page?: number;
    size?: number;
    sortBy?: string;
    sortType?: string;
  }): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const responseData = await service.getMoneyDirectCostMaterialList({
        ...params,
      });
      // using deep copy to avoid 2 state aim the same memory address
      setData(JSON.parse(JSON.stringify(responseData)));
      setOriginalData(JSON.parse(JSON.stringify(responseData)));

      let status = STATUS_CM_PARENT_TABLE.REJECTED.LABEL;
      let actualCost: number = 0;
      let isAllApproved: boolean = true;
      let isAllWaitingForApproval: boolean = false;
      if (responseData) {
        const totalRecord = [...responseData, ...newLines];
        for (let i = 0; i < totalRecord.length; i++) {
          if (
            totalRecord[i]?.status?.code &&
            totalRecord[i].status.code !==
            STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE
          )
            isAllApproved = false;
          else if (
            totalRecord[i]?.status?.code &&
            totalRecord[i].status.code ===
            STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE
          )
            actualCost += totalRecord[i].actualCost || 0;
          if (
            totalRecord[i]?.status?.code &&
            totalRecord[i].status.code ===
            STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE
          )
            isAllWaitingForApproval = true;
        }
        if (responseData.length > 0) {
          if (isAllApproved) status = STATUS_CM_PARENT_TABLE.APPROVED.LABEL;
          else if (isAllWaitingForApproval)
            status = STATUS_CM_PARENT_TABLE.PENDING.LABEL;
        }
        dispatch(
          editParentStatus({
            id: parentId,
            actualCost,
            status,
            childrenNumber: responseData.length,
          })
        );
      }
    } catch (error: any) {
      ErrorNotification(NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onValueNumberChange = (record: any, property: string, e: any) => {
    const value = e;
    const toNumber = Number(value);

    if (String(record.id).includes("new")) {
      // new data
      const newNewLines = newLines.map((element) => {
        if (element.id === record.id)
          element[`${property}`] = isNaN(toNumber) ? 0 : toNumber;
        return element;
      });
      // calculate total redux
      setNewLines(newNewLines);
    } else {
      // old data
      const newData = data.map((element) => {
        if (element.id === record.id)
          (element as any)[`${property}`] = isNaN(toNumber) ? 0 : toNumber;
        return element;
      });
      setData(newData);
    }
  };

  const addNewLine = (id: number | string) => {
    const newLineKey = `new${newCount}${id}`;
    const newLinesData: any = {
      key: newLineKey,
      id: newLineKey,
    };
    setNewLines([...newLines, newLinesData]);
    setEditingKeys([...editingKeys, newLineKey]);
    setNewCount(newCount + 1);
  };

  const isEditing = (record: any): boolean =>
    editingKeys.some((element) => element === record.key);

  const edit = (record: any) => {
    setSaveKeys([...saveKeys, record.key]);
    setEditingKeys([...editingKeys, record.key]);
    form.setFields([
      {
        name: [`record${record.id}`, `targetPeriod`],
        value: [
          record.startDate ? moment(record.startDate) : undefined,
          record.endDate ? moment(record.endDate) : undefined,
        ],
      },
      {
        name: [`record${record.id}`, `paymentDate`],
        value: record.paymentDate ? moment(record.paymentDate) : undefined,
      },
      {
        name: [`record${record.id}`, `paidCost`],
        value: record.paidCost || 0,
      },
      {
        name: [`record${record.id}`, `planMaterialCost`],
        value: record.planMaterialCost || 0,
      },

      {
        name: [`record${record.id}`, `actualCost`],
        value: record.actualCost,
      },
      {
        name: [`record${record.id}`, `evidence`],
        value: [
          {
            name: record.evidence,
            uid: Math.random(),
            key: Math.random(),
          },
        ],
      },
    ]);
  };

  const handleViewEvidence = async (record: any) => {
    try {
      dispatch(setLoading(true));

      const companyId = helpers.getCompanyIdFromLocalStorage();
      if (!companyId) return ErrorNotification(NOTIFICATION_TITLE.ERROR);
      const response = await service.getPresignLinkS3({
        keyName: `re/${companyId}/${SCREEN_NAME}/${record.id}/${record.evidence}`,
      });

      dispatch(setEvidenceLink(response.results));
      const checkFile = record.evidence?.split(".").pop();
      const evidenceName = record.evidence?.split(".").slice(0, -1).join(".");
      dispatch(setEvidenceName(evidenceName));
      if (checkFile === "pdf") {
        dispatch(showPdfViewerModal());
      } else dispatch(showImageViewerModal());
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getLinkPresignUpload = async (params: any) => {
    try {
      const response = await service.getLinkPresignUpload(params);
      if (response) return response.results;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const uploadTos3 = async (url: any, data: any) => {
    try {
      const response = await service.uploadToS3(url, data);
      return response.results;
    } catch (error) {
      throw error;
    }
  };

  const afterPostPutSuccess = async (key: any) => {
    const filteredKeys = editingKeys.filter((element: any) => element !== key);
    setEditingKeys(filteredKeys);

    await getMoneyDirectCostMaterialListApi({
      documentDirectMaterialCostId: parentId,
    });
  };

  const saveRecord = async (record: any, id: number) => {
    try {
      dispatch(setLoading(true));
      const recordFormValue = form.getFieldValue(`record${record.id}`);
      const {
        targetPeriod,
        paymentDate,
        paidCost,
        planMaterialCost,
        actualCost,
        evidence,
      } = recordFormValue;
      const startDate =
        targetPeriod && targetPeriod[0]
          ? helpers.getFilterTime(targetPeriod[0])
          : null;
      const endDate =
        targetPeriod && targetPeriod[1]
          ? helpers.getFilterTimeTo(targetPeriod[1])
          : null; // blank case

      if (String(record.id).includes("new")) {
        // create
        const payload: MoneyDirectCostMaterialCreatePayload = {
          data: [
            {
              paidCost: paidCost || 0,
              planMaterialCost: planMaterialCost || 0,
              actualCost: actualCost || 0,
              startDate: startDate, // blank case
              endDate: endDate, // blank case
              evidence: evidence?.file?.name ? evidence.file.name : "",
              paymentDate: paymentDate
                ? helpers.getFilterTime(paymentDate)
                : null,
            },
          ],
          documentDirectMaterialCostId: id,
        };
        const response = await service.postMoneyDirectCostMaterialCreate(
          payload
        );

        if (response && response.status === `${COMMON.HTTP_STATUS_OK}`) {
          if (evidence?.file?.originFileObj) {
            const companyId = JSON.parse(localStorage.getItem("user") ?? `{}`)
              ?.company?.id;
            const linkPreSignUpload = await getLinkPresignUpload({
              keyName: `re/${companyId}/${SCREEN_NAME}/${response.results}/${evidence?.file?.name}`,
            });
            await uploadTos3(linkPreSignUpload, evidence.file?.originFileObj);
          }
          // remove from new lines
          const filterNewLines = newLines.filter(
            (element: any) => element.key !== record.key
          );
          setNewLines(filterNewLines);
          SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
          return await afterPostPutSuccess(record.key);
        } else ErrorNotification(NOTIFICATION_TITLE.ERROR);
      } else {
        // update
        const payload: MoneyDirectCostMaterialUpdatePayload = {
          id: record.id,
          endDate: endDate, // blank case
          startDate: startDate, // blank case
          evidence: evidence?.file?.name ? evidence.file.name : record.evidence,
          actualCost: actualCost || 0,
          paidCost: paidCost || 0,
          paymentDate: paymentDate ? helpers.getFilterTime(paymentDate) : null,
          planMaterialCost: planMaterialCost || 0,
          note: record?.note ?? "",
        };

        if (
          record?.status?.code === STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE
        ) {
          dispatch(setDocumentEdit(payload));
          return;
        }

        const response = await service.putMoneyDirectCostMaterialUpdate(
          payload
        );
        if (response && response.status === `${COMMON.HTTP_STATUS_OK}`) {
          if (evidence?.file?.originFileObj) {
            const companyId = JSON.parse(localStorage.getItem("user") ?? `{}`)
              ?.company?.id;
            const linkPreSignUpload = await getLinkPresignUpload({
              keyName: `re/${companyId}/${SCREEN_NAME}/${record.id}/${evidence?.file?.name}`,
            });
            await uploadTos3(linkPreSignUpload, evidence.file?.originFileObj);
          }
          SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
          return await afterPostPutSuccess(record.key);
        } else throw new Error(NOTIFICATION_TITLE.ERROR);
      }
    } catch (error) {
      ErrorNotification(NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const updateOnNoteModal = async (payload: any) => {
    const response = await service.putMoneyDirectCostMaterialUpdate(payload);
    if (response && response.status === `${COMMON.HTTP_STATUS_OK}`) {
      if (payload.evidence?.file?.originFileObj) {
        const companyId = JSON.parse(localStorage.getItem("user") ?? `{}`)
          ?.company?.id;
        const linkPreSignUpload = await getLinkPresignUpload({
          keyName: `re/${companyId}/${SCREEN_NAME}/${payload.id}/${payload.evidence?.file?.name}`,
        });
        await uploadTos3(
          linkPreSignUpload,
          payload.evidence.file?.originFileObj
        );
      }
      await afterPostPutSuccess(payload.id);
      SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
    } else throw new Error(NOTIFICATION_TITLE.ERROR);
  };

  const viewApproveModal = (record: any) => {
    // update
    const payload: MoneyDirectCostMaterialUpdatePayload = {
      id: record.id,
      endDate: record.endDate, // blank case
      startDate: record.startDate, // blank case
      evidence: record.evidence,
      actualCost: record.actualCost,
      paidCost: record.paidCost,
      paymentDate: record.paymentDate,
      planMaterialCost: record.planMaterialCost,
      note: record.note,
    };

    dispatch(
      setDocumentView({
        ...payload,
        status: record.status,
      })
    );
  };

  const deleteRecord = async (value: any) => {
    ConfirmModalAsync({
      onOk: async () => {
        try {
          dispatch(setLoading(true));
          // call api delete
          const payload: MoneyDirectCostMaterialDeletePayload = {
            id: value.id,
          };
          const response = await service.deleteMoneyDirectCostMaterialDelete(
            payload
          );
          SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);

          await getMoneyDirectCostMaterialListApi({
            documentDirectMaterialCostId: parentId,
          });
        } catch (error: any) {
          ErrorNotification(NOTIFICATION_TITLE.ERROR);
        } finally {
          dispatch(setLoading(false));
        }
      },
      className: "confirm__modal",
      onCancel: () => { },
      title: MESSAGE.MESSAGE_MODAL_DELETE_TITLE,
      description: MESSAGE.MESSAGE_DESCRIPTION_CR_7,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_DELETE,
    });
  };

  const onCancelRecord = (record: any) => {
    if (String(record.id).includes("new")) {
      const calcelNewLines = newLines.filter(
        (element) => element.id !== record.id
      );
      setNewLines(calcelNewLines);
    } else {
      // case update
      const originalRecord = originalData.find(
        (element) => element.id === record.id
      );
      if (originalRecord) {
        const cancelEditData: MoneyDirectCostMaterialListResult[] = data.map(
          (element) => {
            if (originalRecord.id === element.id) return originalRecord;
            return element;
          }
        );
        setData(JSON.parse(JSON.stringify(cancelEditData)));
      }
    }
    const filteredKeys = editingKeys.filter(
      (element: any) => element !== record.key
    );
    setEditingKeys(filteredKeys);
  };

  const cancelOperation = (record: any) => {
    // check if user has change input
    const isTouchedTargetPeriod = form.isFieldsTouched([
      `record${record.id}`,
      `targetPeriod`,
    ]);
    const isTouchedReceiveAt = form.isFieldsTouched([
      `record${record.id}`,
      `receiveAt`,
    ]);
    const isTouchedPlanReceiveMoney = form.isFieldsTouched([
      `record${record.id}`,
      `planReceiveMoney`,
    ]);
    const isTouchedReceiveMoney = form.isFieldsTouched([
      `record${record.id}`,
      `receiveMoney`,
    ]);
    const isTouchedContractorReceiveMoney = form.isFieldsTouched([
      `record${record.id}`,
      `contractorReceiveMoney`,
    ]);

    const isRecordTouched = [
      isTouchedTargetPeriod,
      isTouchedReceiveAt,
      isTouchedPlanReceiveMoney,
      isTouchedReceiveMoney,
      isTouchedContractorReceiveMoney,
    ].some((element) => element === true);

    if (isRecordTouched) {
      ConfirmModal({
        onOk: () => onCancelRecord(record),
        onCancel: () => { },
        title: MESSAGE.MESSAGE_020,
        description: MESSAGE.MESSAGE_022_1,
        extraDescription: MESSAGE.MESSAGE_022_2,
        canceText: LABEL_MESSAGE.CANCEL_MODAL,
        okText: LABEL_MESSAGE.OK_MODAL,
      });
    } else onCancelRecord(record);
    // case new
  };

  const resetEditingLines = () => {
    setNewLines([]);
    setEditingKeys([]);
  };

  const onCheckAllChange = (checked: boolean) => {
    setCheckAll(checked);
    if (checked) {
      const checkedItems: any[] = [];
      for (let element of data) {
        if (
          element?.status?.code ===
          STATUS_CM_CHILD_TABLE.MONEY_NOT_APPROVED.CODE
        )
          checkedItems.push(element.id);
      }
      setCheckedList(checkedItems);
      dispatch(
        setCheckedCollapse({
          id: parentData.id,
          checked: checkedItems,
        })
      );
    } else {
      setCheckedList([]);
      dispatch(
        setCheckedCollapse({
          id: parentData.id,
          checked: [],
        })
      );
    }
  };

  const isCheckbox = (record: any): boolean =>
    checkedList.some((element) => element === record.key);

  const onCheckboxChange = (record: any, checked: boolean) => {
    let newCheckList: any[] = [];

    if (checked) {
      newCheckList = [...checkedList, record.key];
    } else {
      const checkedData = checkedList.filter(
        (element: any) => element !== record.key
      );
      newCheckList = checkedData;
    }
    const possibleItems: any[] = [];

    for (let element of data) {
      if (
        element?.status?.code === STATUS_CM_CHILD_TABLE.MONEY_NOT_APPROVED.CODE
      )
        possibleItems.push(element.id);
    }
    setCheckedList(newCheckList);
    setCheckAll(newCheckList.length === possibleItems.length);
    dispatch(
      setCheckedCollapse({
        id: parentData.id,
        checked: newCheckList,
      })
    );
  };

  const checkParentCBChange = (
    parentData: any,
    checkedCollapse: { id: number; checked: number[] }[]
  ) => {
    const isChecked = checkedCollapse.some(
      (element) => element.id === parentData.id
    );
    if (!isChecked) {
      setCheckAll(false);
      setCheckedList([]);
    }
  };

  return {
    form,
    editingKeys,
    data,
    newLines,
    saveKeys,
    checkAll,
    checkedList,

    isEditing,
    edit,
    saveRecord,
    addNewLine,
    onValueNumberChange,
    deleteRecord,
    getMoneyDirectCostMaterialListLocal,
    cancelOperation,
    setParentId,
    handleViewEvidence,
    checkEnableButtons,
    isEnableSaveButton,
    afterClearEvidence,
    resetEditingLines,
    onCheckAllChange,
    isCheckbox,
    onCheckboxChange,
    setParentData,
    checkParentCBChange,
    viewApproveModal,
    updateOnNoteModal,
  };
};

export default CollapsedTableHandler;
