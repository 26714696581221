import { uuidv4 } from "@firebase/util";
import { useState } from "react";
// Redux
import { useDispatch, useSelector } from "react-redux";

// Services

import { setVisibleDB002 } from "../slice/Slice";
import { DB002UseCase } from "../../usecase/ServiceImpl";
import { convertDataTableExpenditure } from "../../helper";
import { RootState } from "../../../../../store";
import COMMON from "../../../../../common/constants/COMMON";
import moment from "moment";
import { createRangeArrayNumber } from "../../../DB001/presenter/handler/Handler";

const DB002Handler = (service: DB002UseCase) => {
  const dispatch = useDispatch();
  const dataProjectDB001 = useSelector(
    (state: RootState) => state.db002.dataProjectDB002
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [inforProject, setInforProject] = useState({});
  const [dataTableExpenditure, setDataTableExpenditure] = useState<any>([]);
  const [dataTableOverview, setDataTableOverview] = useState<any>([]);
  const [totalTableExpenditure, setTotalTableExpenditure] = useState({
    totalBudgetExpenditure: 0,
    totalOperatingCostExpenditure: 0,
    totalManPowerExpenditure: 0,
    totalBillingAmountExpenditure: 0,
    totalGrossProfitExpenditure: 0,
    totalGrossProfitMarginExpenditure: 0,
  });

  const cancelModalDB002 = () => {
    dispatch(setVisibleDB002(false));
  };

  const getReMoneyMonthly = async (params: {
    projectId: number;
    from: string;
    to: string;
  }) => {
    try {
      setLoading(true);
      const response = await service.getReMoneyMonthly(params);
      setInforProject({
        projectName: response?.results?.project?.projectName,
        constructionFromDate: response?.results?.project?.constructionFromDate,
        constructionToDate: response?.results?.project?.constructionToDate,
      });
      const dataSummaryProjectCost = await getProjectSummaryCost(params);
      const dataReceivedMoney = await getProjectSummaryReceivedMoney(params);

      const resultDB002 = convertDataTableExpenditure(
        response?.results?.data,
        dataProjectDB001,
        dataSummaryProjectCost,
        dataReceivedMoney
      );
      const fromDate = moment(params.from).format(COMMON.FORMAT_DATE4)
      const toDate = moment(params.to).format(COMMON.FORMAT_DATE4)

      const startYear = +fromDate.split("-")[0];
      const startMonth = +fromDate.split("-")[1];
  
      const endYear = +toDate.split("-")[0];
      const endMonth = +toDate.split("-")[1];

      const allYearArr = createRangeArrayNumber(startYear, endYear, 1)

      const dateOfDataAvailable = resultDB002.resultsTableExpenditure?.reduce((dateOfDataAvailable: any, item: any) => {
        const currentYear = item.time?.split("-")[0]
        const currentMonth = +item.time?.split("-")[1];
        const dateOfDataAvailableTmp = {...dateOfDataAvailable}
        if(dateOfDataAvailableTmp[currentYear]) {
          dateOfDataAvailableTmp[currentYear].push(currentMonth)
        } else {
          dateOfDataAvailableTmp[currentYear] = [currentMonth]
        }
        return dateOfDataAvailableTmp
      }, {})
      const resultsTableExpenditure = [...resultDB002.resultsTableExpenditure]
      allYearArr.forEach((year) => {
        let months = createRangeArrayNumber(1, 12, 1);
        if(startYear === endYear) months = createRangeArrayNumber(startMonth, endMonth, 1)
        else if(year === startYear) months =  createRangeArrayNumber(startMonth, 12, 1);
        else if(year === endYear) months =  createRangeArrayNumber(1, endMonth, 1);
        if(resultsTableExpenditure?.length >= 0) {
          months.forEach((month) => {
            if(!dateOfDataAvailable[year]?.includes(month)) {
              const initRowOfTableExpenditure = {
                amountGAP: 0,
                billingAmount: 0,
                budget: 0,
                children: [
                  {
                      "key": uuidv4(),
                      "item": "材料費",
                      "budget": 0,
                      "operatingCost": 0,
                      "amountGAP": 0,
                      "rateGAP": null
                  },
                  {
                      "key": uuidv4(),
                      "item": "外注費",
                      "budget": 0,
                      "operatingCost": 0,
                      "amountGAP": 0,
                      "rateGAP": null
                  },
                  {
                      "key": uuidv4(),
                      "item": "労務費",
                      "budget": 0,
                      "operatingCost": 0,
                      "amountGAP": 0,
                      "rateGAP": null
                  },
                  {
                      "key": uuidv4(),
                      "item": "間接工事費",
                      "budget": 0,
                      "operatingCost": 0,
                      "amountGAP": 0,
                      "rateGAP": null
                  }
                ],
                grossProfit: 0,
                grossProfitMargin: 0,
                item: "全体",
                key: uuidv4(),
                manPower: 0,
                operatingCost: 0,
                rateGAP: 0,
              }
              const time = moment(`${year}/${month}`).format(COMMON.FORMAT_DATE_4);
              resultsTableExpenditure.push({...initRowOfTableExpenditure, time})
            }
          })
        }
      })
      const resultsTableExpenditureSorted = resultsTableExpenditure.sort((a: any, b: any) => moment(a.time).isBefore(moment(b.time))? 1: -1 )
      setDataTableExpenditure(resultsTableExpenditureSorted);
      setDataTableOverview(resultDB002.resultTableOverview);
      setTotalTableExpenditure(resultDB002.totalTableExpenditure);
    } finally {
      setLoading(false);
    }
  };
  const getProjectSummaryCost = async (params: {
    projectId: number;
    from: string;
    to: string;
  }) => {
    try {
      setLoading(true);
      const response = await service.getProjectSummaryCost(params);

      return response.results;
    } finally {
      setLoading(false);
    }
  };

  const getProjectSummaryReceivedMoney = async (params: {
    projectId: number;
    from: string;
    to: string;
  }) => {
    try {
      setLoading(true);
      const response = await service.getProjectSummaryReceivedMoney(params);

      return response.results;
    } finally {
      setLoading(false);
    }
  };
  return {
    dataTableOverview,
    dataTableExpenditure,
    inforProject,
    totalTableExpenditure,
    loading,
    cancelModalDB002,
    getReMoneyMonthly,
  };
};
export default DB002Handler;
