import { memo, useEffect } from "react";
import { Button, Modal, Spin, Table } from "antd";
import { CR001MailContractorsHandlerUI } from "../handler/HandlerImpl";
import LoadingComponent from "../../../../../common/components/icons/LoadingComponent";
import PaginationComponentList from "../../../../../common/components/pagination/PaginationComponentList";

import "../../../../../assets/styles/CR/CR001.css";
import IconCloseModal from "../../../../../assets/icons/icon-close-modal.svg";
import ArrowLeft from "../../../../../assets/icons/arrow-left-2.svg";
import EmptyData from "../../../../../assets/icons/empty-data.svg";

interface props {
  handler: CR001MailContractorsHandlerUI;
}

const columns: any = [
  {
    title: "契約工事名",
    dataIndex: "constructionName",
    key: "constructionName",
    width: "35%",
    render: (
      text: string,
      row: { constructionName: string; constructionNameChild: string }
    ) => `${row.constructionName || ""} ${row.constructionNameChild}`,
  },
  {
    title: "会社名",
    dataIndex: "name",
    key: "name",
    width: "30%",
  },
  {
    title: "メールアドレス",
    dataIndex: "email",
    key: "email",
  },
];

function CR001MailContractor({ handler }: props) {
  useEffect(() => {
    if (!handler.isVisible) handler.resetState();
  }, [handler.isVisible]);

  return (
    <Modal
      className="cr001-mail-contractor"
      closeIcon={<img alt="" src={IconCloseModal} />}
      centered
      onCancel={() => {
        if (!handler.loading) {
          handler.handleCloseModal();
        }
      }}
      title={
        <div className="cr001-modal-title">
          {handler.isViewDataAfter ? (
            <>
              <img
                onClick={() => {
                  if (!handler.loading) {
                    handler.handleCancel();
                  }
                }}
                className={`cr001-modal-title-icon ${
                  handler.loading ? "icon-disable" : ""
                }`}
                alt=""
                src={ArrowLeft}
              />
              <span>招待メール送信 確認</span>
            </>
          ) : (
            "協力会社 未招待リスト"
          )}
        </div>
      }
      visible={handler.isVisible}
      width="80%"
      footer={
        handler.isViewDataAfter ? (
          <>
            <Button
              size="large"
              className="button-purple"
              onClick={handler.handlerConfirmData}
              disabled={handler.loading}
              style={{
                width: 118,
              }}
            >
              メール送信
            </Button>
            <Button
              style={{
                width: 118,
              }}
              size="large"
              disabled={handler.loading}
              onClick={handler.handleCancel}
              className="btn-text-color"
            >
              キャンセル
            </Button>
          </>
        ) : (
          <>
            <Button
              disabled={
                handler.selectedRowKeys?.length === 0 || handler.loading
              }
              size="large"
              className="button-purple"
              onClick={handler.handleConfirmChoose}
              style={{ letterSpacing: -1, width: 118 }}
            >
              確認
            </Button>
            <Button
              size="large"
              disabled={handler.loading}
              onClick={handler.handleCancel}
              className="btn-text-color"
              style={{
                width: 118,
              }}
            >
              キャンセル
            </Button>
          </>
        )
      }
    >
      <Spin indicator={LoadingComponent} spinning={handler.loading}>
        <div
          style={{
            minHeight: "60vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Table
            columns={columns}
            dataSource={handler.dataSource}
            className="cr001-table"
            pagination={false}
            locale={{ emptyText: <img src={EmptyData} alt="" /> }}
            scroll={{ y: "61vh" }}
            rowSelection={
              handler.isViewDataAfter
                ? undefined
                : {
                    selectedRowKeys: handler.selectedRowKeys,
                    onChange: handler.handlerChangeRow,
                    columnWidth: 65,
                    selections: false,
                    getCheckboxProps: (record: any) => {
                      return {
                        disabled:
                          record?.startTime == null && record?.endTime == null,
                      };
                    },
                  }
            }
          />
          {handler.isViewDataAfter || handler.dataSource?.length > 0 || (
            <PaginationComponentList
              disabled={handler.dataSource.length !== 0 ? false : true}
              total={handler.total}
              page={handler.page}
              size={handler.size}
              setPage={handler.handleChangePage}
              setSize={handler.handleChangeSize}
            />
          )}
        </div>
      </Spin>
    </Modal>
  );
}

export default memo(CR001MailContractor);
