import { Button, Divider, Modal, Spin } from 'antd';
import { useSelector } from 'react-redux';
import '../../../../../assets/styles/CM/CM0062.css';
import LoadingComponent from '../../../../../common/components/icons/LoadingComponent';
import 'moment/locale/ja';
import { RootState } from '../../../../../store';
import { CM0062Hanlder } from '../handler/HandlerImpl';
import CloseIconModal from '../../../../../assets/icons/close.png';
import { default as XIconModal } from '../../../../../assets/icons/x-icon-modal.svg';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useEffect } from 'react';
import React from 'react';
import helpers from '../../../../../common/helpers/common';

type Props = {
    handler: CM0062Hanlder;
};

export interface DataType {
    key?: string;
    order?: string;
    item?: string;
    taxCategoryComsumption?: string;
    offsetAmount?: number;
}

const columns: ColumnsType<DataType> = [
    {
        title: 'No',
        dataIndex: 'order',
        align: 'center',
    },
    {
        title: '項目',
        dataIndex: 'item',
        align: 'center',
        render: (record: any) => {
            return helpers.getShortTextWithToolTip(record, 15);
        },
    },
    {
        title: '消費税区分',
        dataIndex: 'taxCategoryComsumption',
        align: 'center',
        colSpan: 1,
    },
    {
        title: '相殺金額',
        dataIndex: 'offsetAmount',
        align: 'center',
        render: (record: any) => {
            return helpers.formatCurrency(record) + '円';
        },
    },
];

const CM0062UI = ({ handler }: Props) => {
    const isVisible = useSelector((state: RootState) => state.cm0062.isVisible);
    const input = useSelector((state: RootState) => state.cm0062.input);
    const loading = useSelector((state: RootState) => state.cm0062.loading);

    React.useEffect(() => {
        if (input.documentId !== 0)
            handler.getOffsetData(
                input.documentId,
                input.categoryId,
                input.projectName,
                input.option,
            );
    }, []);

    return (
        <Modal
            className="group-detail-modal"
            style={{ marginLeft: 300, marginTop: 40, marginBottom: 40 }}
            visible={isVisible}
            closable={false}
            maskClosable={false}
            width={'1027px'}
            centered={true}
            closeIcon={<img src={CloseIconModal} alt="close icon" />}
            footer={
                <>
                    <Button
                        disabled={loading}
                        size="large"
                        type="primary"
                        style={{ marginLeft: 16, width: 120, letterSpacing: -1.5 }}
                        form="formCM0062"
                        onClick={() => handler.onCancel()}
                    >
                        閉じる
                    </Button>
                </>
            }
        >
            <Spin indicator={LoadingComponent} spinning={loading}>
                <div className="w-100-percent header-modal-containter" style={{ height: 50 }}>
                    <div className="header-modal-title-cm0062">相殺金額</div>
                    <div style={{ cursor: 'pointer' }} onClick={handler.onCancel}>
                        <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
                    </div>
                </div>
                <div style={{ minHeight: 224, marginTop: 32 }} className="cm0062-content">
                    <div className="content-description">
                        <div className="content-description-text">
                            <div className="description-col">
                                <label> 発注元会社名</label>
                                <p>{helpers.getShortTextWithToolTip(input.companyName, 15)}</p>
                            </div>
                            <div className="description-col">
                                <label> 対象期間</label>
                                <p>{input.time}</p>
                            </div>
                        </div>
                    </div>
                    <div className="content-table">
                        <div className="table-bot">
                            <Table
                                scroll={{ y: 224 }}
                                columns={columns}
                                dataSource={handler.data}
                                bordered
                                pagination={false}
                                summary={(data) => {
                                    let total = 0;
                                    data.forEach((element: any) => {
                                        total += element.offsetAmount;
                                    });
                                    return (
                                        <Table.Summary fixed>
                                            <Table.Summary.Row
                                                className="children-table-summary"
                                                style={{
                                                    background: '#605BFF',
                                                    color: '#fff',
                                                }}
                                            >
                                                <Table.Summary.Cell
                                                    index={1}
                                                    align="center"
                                                    className="border-right-none"
                                                    colSpan={1}
                                                >
                                                    <span>合計金額</span>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell
                                                    index={2}
                                                    className="border-right-none"
                                                ></Table.Summary.Cell>
                                                <Table.Summary.Cell
                                                    index={2}
                                                    className="border-right-none"
                                                ></Table.Summary.Cell>
                                                <Table.Summary.Cell
                                                    index={4}
                                                    className="text-center"
                                                >
                                                    <span className="center-item">{`${helpers.formatCurrency(
                                                        total,
                                                    )}円`}</span>
                                                </Table.Summary.Cell>
                                            </Table.Summary.Row>
                                        </Table.Summary>
                                    );
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Spin>
        </Modal>
    );
};

export default CM0062UI;
