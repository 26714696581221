import { memo } from 'react';
import { Button, Collapse, Input, Tooltip, Form } from 'antd';
import PM011ItemMember from './PM014ItemMember';
import { PM014TableHandler } from '../handler/HandlerImpl';
import React from 'react';

import IconDown from '../../../../../assets/icons/arrow.svg';
import { onBlurTrimFormItem } from '../../../../../common/helpers/typeInput';

const { Panel } = Collapse;

interface props {
    handler: PM014TableHandler;
}
const PM014ItemHeader = ({ handler }: props) => {
    const [members, setMembers] = React.useState<any[]>([]);
    const [memberApprove, setMemberAppove] = React.useState<any[]>([]);
    const [openCollapse, setOpenCollapse] = React.useState<boolean>(false);
    const [titleLevel, setTitleLevel] = React.useState<string>(handler.dataParentPM014?.titleLevel);
    const [render, setRender] = React.useState<boolean>(false);
    const [formTitleLevel] = Form.useForm();

    React.useEffect(() => {
        handler.funcChangeEdit(false);
    }, []);

    React.useEffect(() => {
        if (handler.keySearch && handler.keySearch !== '') {
            handler.funcGetSubcontractorMemberParent(
                handler.chosenNavItem.constructionId,
                Number(handler.chosenNavItem.subcontractorId),
                setMembers,
                setMemberAppove,
                handler.keySearch,
                handler.currentTab !== "1"
            );
        } else {
            handler.funcGetSubcontractorMemberParent(
                handler.chosenNavItem.constructionId,
                Number(handler.chosenNavItem.subcontractorId),
                setMembers,
                setMemberAppove,
                undefined,
                handler.currentTab !== "1"
            );
        }
        setRender(!render);
    }, [handler.expandAllCollapse, openCollapse, handler.currentTab, handler.keySearch, handler.isRefreshPM014]);

    const funcUpdateTitleLevel = () => {
        handler.funcUpdateContractorLevel(
            Number(handler.chosenNavItem.constructionId),
            Number(handler.chosenNavItem.subcontractorId),
            titleLevel,
        );
        handler.funcChangeEnableEdit();
        setRender(!render);
    };

    React.useEffect(() => {
        handler.activeCheckboxAll(true);
    }, []);

    return (
        <Collapse
            // key={`${render}`}
            className="folder-collapse pm011-collapse-header"
            onChange={(e: any) => {
                handler.funcExpandCollapseAll();
                handler.activeCheckboxAll(false);
                if (e.length !== 0) {
                    setOpenCollapse(true);
                } else {
                    setOpenCollapse(false);
                }
            }}
            expandIcon={({ isActive }) => {
                return (
                    <div className={`${isActive ? 'collapse-icon-active' : ''} collapse-icon`}>
                        <img src={IconDown} alt="" />
                    </div>
                );
            }}
        >
            <Panel
                className="pm011-panel"
                key="aaaaa"
                header={
                    <Tooltip
                        title="クリックして詳細を表示します。"
                        placement="top"
                        overlayInnerStyle={{
                            fontSize: 12,
                            borderRadius: 3,
                            padding: 10,
                        }}
                        color={'#222222'}
                    >
                        <div
                            className="pm011-panel-header-title"
                            // onClick={(e) => e.stopPropagation()}
                            style={{ cursor: 'pointer' }}
                        >
                            <div className="pm011-panel-header-title-left">
                                <div style={{ marginRight: 12, marginBottom: -2 }}>
                                    <svg
                                        width="2"
                                        height="20"
                                        viewBox="0 0 2 20"
                                        fill="#418FEB"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <line
                                            x1="1"
                                            y1="1"
                                            x2="0.999999"
                                            y2="19"
                                            stroke="#418FEB"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                        />
                                    </svg>
                                </div>
                                <span style={{ lineHeight: 2 }}>
                                    {handler.dataParentPM014?.company?.name ?? ''} (
                                    {handler.dataParentPM014?.shortDescription ?? ''})
                                </span>
                                {handler.enableEdit ? (
                                    <>
                                        <div
                                            style={{
                                                marginLeft: 10,
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'flex-start',
                                            }}
                                        >
                                            <Form form={formTitleLevel}>
                                                <Form.Item
                                                    name="titleLevel"
                                                    style={{ marginBottom: 0 }}
                                                >
                                                    <Input
                                                        maxLength={20}
                                                        defaultValue={
                                                            titleLevel
                                                                ? titleLevel
                                                                : handler.dataParentPM014
                                                                    ?.titleLevel
                                                        }
                                                        onChange={(e: any) =>
                                                            setTitleLevel(e.target.value.trim())
                                                        }
                                                        onBlur={() =>
                                                            onBlurTrimFormItem(
                                                                formTitleLevel,
                                                                'titleLevel',
                                                            )
                                                        }
                                                    />
                                                </Form.Item>
                                            </Form>

                                            <Button
                                                className="button-purple"
                                                style={{
                                                    marginLeft: 10,
                                                    letterSpacing: '-1px',
                                                }}
                                                onClick={funcUpdateTitleLevel}
                                            >
                                                保存
                                            </Button>
                                            <Button
                                                style={{
                                                    marginLeft: 5,
                                                    borderRadius: 3,
                                                }}
                                                onClick={() => {
                                                    setTitleLevel(
                                                        handler.dataParentPM014?.titleLevel,
                                                    );
                                                    handler.funcChangeEnableEdit();
                                                }}
                                            >
                                                キャンセル
                                            </Button>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div
                                            className="pm011-panel-button"
                                            style={{
                                                backgroundColor: 'rgba(65, 143, 235, 0.12)',
                                            }}
                                        >
                                            <span style={{ color: '#418FEB' }}>
                                                （
                                                {titleLevel
                                                    ? titleLevel
                                                    : handler.dataParentPM014?.titleLevel}
                                                ）
                                            </span>
                                            <div
                                                className="pm011-panel-button-icon"
                                                onClick={() => handler.funcChangeEnableEdit()}
                                            >
                                                <svg
                                                    width="16"
                                                    height="16"
                                                    viewBox="0 0 16 16"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    <path
                                                        d="M9.16504 13.6287H14.0001"
                                                        stroke={'#418FEB'}
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M8.52001 2.52986C9.0371 1.91186 9.96666 1.82124 10.5975 2.32782C10.6324 2.35531 11.753 3.22586 11.753 3.22586C12.446 3.64479 12.6613 4.5354 12.2329 5.21506C12.2102 5.25146 5.87463 13.1763 5.87463 13.1763C5.66385 13.4393 5.34389 13.5945 5.00194 13.5982L2.57569 13.6287L2.02902 11.3149C1.95244 10.9895 2.02902 10.6478 2.2398 10.3849L8.52001 2.52986Z"
                                                        stroke={'#418FEB'}
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M7.34717 4.00049L10.982 6.7919"
                                                        stroke={'#418FEB'}
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="pm011-panel-header-title-right">
                                人数：
                                {members.length || 0}
                                人
                            </div>
                        </div>
                    </Tooltip>
                }
            >
                <PM011ItemMember
                    data={handler.currentTab === '1' ? members : memberApprove}
                    handler={handler}
                    constructionId={handler.chosenNavItem.constructionId}
                    contractorConstructionId={Number(handler.chosenNavItem.subcontractorId)}
                />
            </Panel>
        </Collapse>
    );
};

export default PM014ItemHeader
