import React from 'react';
import moment from 'moment';
import COMMON, { TAX_CODE } from '../../../../../common/constants/COMMON';
import helpers from '../../../../../common/helpers/common';
import { Empty } from 'antd';
import MESSAGE from '../../../../../common/constants/MESSAGE';

type props = {
    constructionCompanyList: any[];
};

const CostTableComponent = ({ constructionCompanyList }: props) => {
    return (
        <React.Fragment>
            <tr className="bg-fa">
                <td></td>
                <td></td>
                <td>業者名</td>
                <td>対象期間</td>

                <td>支払日</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            {constructionCompanyList.length === 0 ? (
                <tr>
                    <td colSpan={10}>
                        <Empty
                            style={{ width: '100%' }}
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={MESSAGE.MESSAGE_DATA_NOT_FOUND}
                        />
                    </td>
                </tr>
            ) : (
                constructionCompanyList.map((element, index) => (
                    <tr key={`table${element.id}`} className="">
                        <td style={{ width: 75 }}></td>
                        <td style={{ width: 75 }}>{index + 1}</td>
                        <td>{element?.materialCostCompanyName ?? ''}</td>
                        <td>{helpers.getStartEndDate(element.startDate, element.endDate)}</td>
                        <td>
                            {element.paymentDate
                                ? moment(element.paymentDate).format(COMMON.FORMAT_DATE_OM)
                                : ''}
                        </td>
                        <td>{`${helpers.formatNumberMoney(element.paymentAmountExcludeTax)}円`}</td>
                        <td>{`${helpers.formatNumberMoney(element.taxAmount)}円`}</td>
                        <td>{`${helpers.formatNumberMoney(
                            element.paymentAmountExcludeTax + element.taxAmount,
                        )}円`}</td>
                        <td></td>
                    </tr>
                ))
            )}
        </React.Fragment>
    );
};

export default CostTableComponent;
