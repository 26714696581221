import React from 'react';
import moment from 'moment';
import COMMON, { TAX_CODE } from '../../../../../common/constants/COMMON';
import helpers from '../../../../../common/helpers/common';
import { Empty } from 'antd';
import MESSAGE from '../../../../../common/constants/MESSAGE';
import { OffsetCost } from '../../entity/Entity';
import CostTableComponent from './CostTable';
import IconDown from '../../../../../assets/icons/icon-down-small.svg';
import TooltipText from '../../../../../common/components/tooltip-text/TooltipText';

type props = {
    projectList: any[];
    setProjectExpandKeys: (key: any) => void;
    checkProjectKeyExpand: (key: any) => boolean;
    openCM0025_1: (value: any) => void;
};

const ProjectRowComponent = ({
    projectList,
    setProjectExpandKeys,
    checkProjectKeyExpand,
    openCM0025_1,
}: props) => {
    const getOffsetValue = (record: any, taxFree?: boolean) => {
        const offsetCost = record.offsetCost?.find(
            (element: OffsetCost) =>
                element.category.code === (taxFree ? TAX_CODE.TAX_FREE : TAX_CODE.TAX),
        );

        return (
            <main className="center-item">
                <div className="collapsed-table-row-style">
                    {`${helpers.formatNumberMoney(offsetCost?.value ?? 0)}円`}
                </div>
            </main>
        );
    };
    return (
        <React.Fragment>
            {projectList.length === 0 ? (
                <tr>
                    <td colSpan={16}>
                        <Empty
                            style={{ width: '100%' }}
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={MESSAGE.MESSAGE_DATA_NOT_FOUND}
                        />
                    </td>
                </tr>
            ) : (
                projectList.map((element, index) => (
                    <React.Fragment key={`table${element.id}`}>
                        <tr
                            key={`table${element.id}`}
                            className="bg-fa button-style"
                            onClick={() => setProjectExpandKeys(element.id)}
                        >
                            <td colSpan={2}>{index + 1}</td>
                            <td colSpan={6}>
                                <div style={{ width: 160 + 600 + 160 }}>
                                    <TooltipText content={element?.project?.projectName ?? ''} />
                                </div>
                            </td>
                            <td>{`${helpers.formatNumberMoney(element.actualCost)}円`}</td>
                            <td>{getOffsetValue(element)}</td>
                            <td>{getOffsetValue(element, true)}</td>
                            <td>{`${helpers.formatNumberMoney(
                                element.paymentAmountExcludingTax,
                            )}円`}</td>
                            <td>{`${helpers.formatNumberMoney(element.consumptionTax)}円`}</td>
                            <td>{`${helpers.formatNumberMoney(
                                element.paymentAmountIncludingTax,
                            )}円`}</td>
                            <td>
                                <img
                                    alt=""
                                    src={IconDown}
                                    className={`cm024-icon ${
                                        !checkProjectKeyExpand(element.id)
                                            ? ''
                                            : 'cm024-icon-active'
                                    }`}
                                />
                            </td>
                        </tr>
                        {checkProjectKeyExpand(element.id) ? (
                            <CostTableComponent
                                outsourceCost={element.outsourceCost}
                                openCM0025_1={openCM0025_1}
                            />
                        ) : (
                            <></>
                        )}
                    </React.Fragment>
                ))
            )}
        </React.Fragment>
    );
};

export default ProjectRowComponent;
